import React, { useEffect, useState } from 'react'
import ArrayChartInfo from './ArrayChartInfo'

const ArrayDataMonth = () => {
    // const [dataMonth, setDataMonth] = useState({
    //     name:'',
    //     borrow:0,
    //     Deposit:0,
    // })
    const getDataMonth = async () => {
        try {

        } catch (error) {
            //TODO getDataMonth
        }
    }
    useEffect(() => {
        getDataMonth()
    }, [])
    const dataMonth = [
        { "name": "9", "Borrow": 4000, "Deposit": 1400, },
        { "name": "10", "Borrow": 2300, "Deposit": 9000, },
        { "name": "11", "Borrow": 3200, "Deposit": 2400, },
        { "name": "12", "Borrow": 2600, "Deposit": 2400, },

    ]

    return (
        <ArrayChartInfo data={dataMonth} />

    )
}

export default ArrayDataMonth
