import { formatCoin } from 'helps/formatCoin'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { TronContext } from './tronWeb'
export const UsersContext = createContext({
    users: {
        status: 0,
        packAmount: 0,
        //tree node
        totalTree: 0,
        treeParent: "parent",
        left: 'left',
        right: "right",
        star: 0
    }
})
interface UsersContextWrapProps {
    children: any
}
const UsersContextWrap = ({ children }: UsersContextWrapProps) => {
    const { data, action, tronWeb } = useContext(TronContext)
    const [users, setUsers] = useState({
        status: 0,
        packAmount: 0,
        //tree node
        totalTree: 0,
        treeParent: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        left: 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb',
        right: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
        star: 0
    })
    useEffect(() => {
        try {


            const getUsers = async () => {
                const result = await data.stake?.users(action.address).call() || ''
                if (result !== '') {
                    const { totalTree, treeParent, left, right, star } = result
                    setUsers({
                        ...users,
                        totalTree: formatCoin(Number(totalTree)),
                        treeParent: tronWeb.address.fromHex(treeParent),
                        left: tronWeb.address.fromHex(left),
                        right: tronWeb.address.fromHex(right),
                        star: Number(star)
                    })
                }
            }
            getUsers()
        } catch (error) {
            console.log('error user context')
        }
    }, [action, data])
    return (
        <UsersContext.Provider value={{
            users
        }}>
            {children}
        </UsersContext.Provider>
    )
}

export default UsersContextWrap
