import React from 'react'
import styled from 'styled-components'
import { BsThreeDots, BsCheck } from 'react-icons/bs'
import { breakpointsMedia } from 'assets/containts/breakpoints'
interface StepNodeProps {
    title: string,
    subTitle: string,
    status: "disabled" | "loading" | "done"
}
const StepNode = ({ title, subTitle, status = "disabled" }: StepNodeProps) => {
    return (
        <Wrap className={status}>
            <div className="sn-status">
                <div className="status">
                    {
                        status === "loading" && <BsThreeDots fontSize={24} />
                    }
                    {
                        status === "done" && <BsCheck fontSize={24} />
                    }
                </div>
            </div>
            <div className="sn-step-info">
                <h3>{title}</h3>
                <p>{subTitle}</p>
            </div>
        </Wrap>
    )
}

export default StepNode
const Wrap = styled.div`
    .sn-status{
        display:flex;
        justify-content:center;
        text-align:center;
        margin-bottom:20px;
        position:relative;
        :before{
            position:absolute;
            content:'';
            left:50%;
            top:50%;
            transform:translate(-50%,-50%);
            width:33px;
            height:33px;
            z-index:0;
            background-color:#798FA6;
            border-radius:999px;
        }
        .status{
            background-color:#798FA6;
            width:30px;
            height:30px;
            border-radius:999px;
            border:2px solid #262E40;
            position:relative;
            z-index:3;
            display:flex;
            align-items:center;
            justify-content:center;
            color:#fff;
        }
    }
    .sn-step-info{
        text-align:center;
        *{
            color:#798FA6 !important;

        }
    }
    &.disabled{
        
    }
    &.loading,&.done{
        .sn-step-info{
            *{
                color:#FFA143 !important;
            }
        }
        .sn-status{
            :before{
                background-color:#FFA143;
            }
            .status{
                background-color:#FFA143;
            }
        }
    }
    ${breakpointsMedia.landscape}{
        .sn-status{
            margin-bottom:20px;
            :before{
                width:40px;
                height:40px;
            }
            .status{
                width:36px;
                height:36px;
            }
        }
    }
    ${breakpointsMedia.desktop}{

    }
    ${breakpointsMedia.largeDesktop}{
        .sn-status{
            margin-bottom:20px;
            :before{
                width:45px;
                height:45px;
            }
            .status{
                width:40px;
                height:40px;
            }
        }
    }
`