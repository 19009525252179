import Text from 'core/Text'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AiOutlineCheck } from 'react-icons/ai'
import { RiFileCopyLine } from 'react-icons/ri'
import { CopyToClipboard } from 'react-copy-to-clipboard';
interface CopyAddressProps {
    address: string,
}
const Address = ({ address }: CopyAddressProps) => {
    const addressRef = address.substr(0, 5) + "..." + address.substr(-5, 5)
    const [copied, setCopied] = useState(false)
    const onCopyAddress = () => {
        setCopied(true)
    }
    useEffect(() => {
        const time = setTimeout(() => { setCopied(false) }, 3000)
        return () => {
            clearTimeout(time)
        }
    }, [copied])
    return (
        <Wrap>
            <div className="src-copy">
                <div className='src-copy__input'>
                    <div className="adress">
                        <Text text={addressRef} />
                    </div>
                    <div className="icon">
                        {
                            copied ?

                                <AiOutlineCheck size={20} color="#fff" />
                                :
                                <CopyToClipboard
                                    text={address}
                                    onCopy={onCopyAddress}
                                >
                                    <RiFileCopyLine size={20} color="#fff" />

                                </CopyToClipboard>

                        }
                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default Address
const Wrap = styled.div`
    .src-copy{
        width:100%;
        &__input{
            display:flex;
            align-items:center;
            /* justify-content:space-between; */
            /* justify-content:center; */
            .icon{
                cursor: pointer;
                margin-left:5px;
            }
        }
    }
`