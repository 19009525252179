import Text from 'core/Text'
import React, { useState } from 'react'
import styled from 'styled-components'
interface FormValueProps {
    value: number,
    onChange: any,
    onSetMaxValue: () => void
}
const FormValue = ({ value, onChange, onSetMaxValue }: FormValueProps) => {

    return (
        <Wrap>
            <input type="number" value={value} onChange={onChange} />
            <div onClick={onSetMaxValue} className="btn-max"><Text text="Max" /></div>
        </Wrap>
    )
}

export default FormValue
const Wrap = styled.div`
    width:100%;
    position:relative;
    input{
        width:100%;
        background-color:transparent;
        border:0;
        border-bottom: 1px solid #798FA6;
        color:#fff;
        padding:8px 0;
        font-size:1rem;
        :focus{
            outline:unset;
        }
    }
    .btn-max{
        background: #4D7FB2 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 3px;
        position:absolute;
        right:0;
        bottom:7px;
        padding:2px 10px;
        cursor: pointer;
        :hover{
            background-color:#FFA143;
        }
    }
`