import { breakpointsMedia } from 'assets/containts/breakpoints'
import StakingLayout from 'components/StakingNew/StakingLayout'
import Text from 'core/Text'
import React, { memo } from 'react'
import styled from 'styled-components'
import HomeBalanceStaking from './HomeBalanceStaking'
import HomeBalanceTop from './HomeBalanceTop'
import HomeBalanceTronHeld from './HomeBalanceTronHeld'
import HomePriceChart from './HomePriceChart'

interface HomeBalanceProps {
    dataCoinUSDT: {
        usdtBalanceUSDT: number
        trxBalanceUSDT: number
        defBalanceUSDT: number
    }
    dataCoin: {
        usdtBalance: number
        trxBalance: number
        defBalance: number
    }
}
const HomeBalance = ({ dataCoinUSDT, dataCoin }: HomeBalanceProps) => {
    return (
        <HomeBalanceWrap>
            <div className="hbw_i">
                <HomeBalanceTop dataCoinUSDT={dataCoinUSDT} dataCoin={dataCoin} />

            </div>
            <div className="hbw_i hbw_i--chart">
                {/* <HomeBalanceStaking /> */}
                <div className="stl-title">
                    <Text text="Price Chart (DEF/TRX)" size="xNormal" fontWeight='bold' />
                </div>
                <div className="hpc">
                    <HomePriceChart />
                </div>
            </div>
            {/* <div className="hbw_i">
                <HomeBalanceStaking />


            </div>
            <div className="hbw_i">
                <HomeBalanceTronHeld />


            </div> */}
        </HomeBalanceWrap>
    )
}

export default HomeBalance
const HomeBalanceWrap = memo(styled.div`
    display:flex;
    /* justify-content:space-between; */
    .hbw_i{
        background-color:#262E3F;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        padding:2rem;
        /* flex:0.32; */
        width:100%;
        margin-right:30px;
        &--chart{
            flex:1;
            margin-right:0;
            .stl-title{
                margin-bottom:30px;
            }
        }
    }
    div:last-child{
        margin-right:0;
    }
    ${breakpointsMedia.largeDesktop}{
        .hbw_i{
            background-color:#262E3F;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            padding:2rem;
            /* flex:0.32; */
            width:30%;
            margin-right:30px;
            &--chart{
                flex:1;
                margin-right:0;
                .stl-title{
                    margin-bottom:30px;
                }
            }
        }
    }
    @media screen and (max-width: 1279px){
        flex-direction:column;
        .hbw_i{
            margin-bottom:1rem;
            padding:2rem ;
        }
    }
    @media screen and (max-width: 1024px){
        flex-direction:column;
        .hbw_i{
            margin-bottom:1rem;
            border-radius: 5px;
        }
    }
    @media screen and (max-width: 767px){
        flex-direction:column;
       >div:last-child{
            margin-right:1rem;
        }
        .hbw_i{
            margin: 0 1rem 1rem;
            padding:1rem;
        }
       
    }
`)