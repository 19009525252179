import React, { memo } from 'react'
import styled from 'styled-components'
import ErrorInternet from '../../assets/images/error-internet.svg'
import Error404 from '../../assets/images/404-error.svg'

import Image from '../../core/Image';
import Text from '../../core/Text'
const ConnectErrorInternet = () => {
    return (
        <ConnectErrorInternetWarp className="container">
            <div className="ceiw_main">
                <div className="ceiwm_404">
                    <div className="ceiwn4_header">
                        <Image src={Error404} />
                    </div>
                    <div className="ceiwn4_sorry">
                        <Text text="Sorry, page not found" size="sale" fontWeight="bold" />
                    </div>
                    <div className="ceiwn4_access">
                        <Text text="The page you are trying to access does not exist or has been moved." />
                        <Text text="Try going back to our homepage." />
                    </div>
                    <div className="ceiwn4_button">
                        <button><Text text="Go to homepage" /></button>
                    </div>
                </div>
                <div className="ceiwm_img">
                    <Image src={ErrorInternet} />
                </div>
            </div>
        </ConnectErrorInternetWarp>
    )
}

export default ConnectErrorInternet
const ConnectErrorInternetWarp = memo(styled.div`
    .ceiw_main{
        width:100%;
        padding:9rem 9.25rem 9.625rem 9rem;
        background-color:#262E3F;
        display:flex;
        justify-content:space-between;
        height:calc(100vh - 140px);
        .ceiwm_404{
            flex:0.45;
            .ceiwn4_header{
                color:#FFA143;
                margin-top:1.875rem;
                margin-bottom:5rem;
                padding:0 6.75rem;
                img{
                    width:100%;
                    height:100%;
                }
            }
            .ceiwn4_sorry{
                text-align:center;
            }
            .ceiwn4_access{
                text-align: center;
                margin-top:2.8125rem;
                margin-bottom:3.125rem;
            }
            .ceiwn4_button{
                width:57%;
                margin:0 auto;
                button{
                    background: transparent linear-gradient(270deg, #FFA143 0%, #FF7555 100%) 0% 0% no-repeat padding-box;
                    border-radius: 32px;
                    width:100%;
                    padding:0.75rem;
                    outline:none;
                    border:none;
                    cursor: pointer;
                }
            }
        }
        .ceiwm_img{
            flex:0.52;
            img{
                width:100%;
            }
        }
    }
    
    @media screen and (min-width: 1300px) and (max-width:1599px){
        .ceiw_main{
            padding: 9rem 4rem;
            .ceiwm_404{
                flex:0.52;
                .ceiwn4_header{
                    margin-top:2rem;
                    margin-bottom:3.5rem;
                    padding:0 4rem;
                }
                .ceiwn4_access {
                    margin-top:2.125rem;
                    margin-bottom:2.5rem;
                }
            }
            .ceiwm_img{
                flex:0.45;
            }
        }
    }
    @media screen and (min-width: 1025px) and (max-width:1299px){
        .ceiw_main{
            padding: 7rem 3rem;
            .ceiwm_404{
                flex:0.52;
                .ceiwn4_header{
                    margin-top:2rem;
                    margin-bottom:3.5rem;
                    padding:0 4rem;
                }
                .ceiwn4_access {
                    margin-top:2.125rem;
                    margin-bottom:2.5rem;
                }
            }
            .ceiwm_img{
                flex:0.45;
            }
        }

    }
    @media screen and (min-width: 768px) and (max-width:1024px){
        .ceiw_main{
            flex-direction: column;
            padding: 10rem 4rem;
             height:100%;

            .ceiwm_404{
                .ceiwn4_header{
                    margin-top:0;
                    margin-bottom:3.5rem;
                    padding:0 8rem;
                }
                .ceiwn4_access {
                    margin-top:2.125rem;
                    margin-bottom:2.5rem;
                }
            }
            .ceiwm_img {
                margin-top:6.375rem;
            }
        }
    }

    @media screen and (min-width:576px) and (max-width:767px){
        .ceiw_main{
            flex-direction: column;
            padding: 5rem 1.5rem;
            height:100%;
            .ceiwm_404{
                .ceiwn4_header{
                    margin-top:0;
                    margin-bottom:4rem;
                    padding:0 6.5rem;
                    text-align:center;
                    img{
                        max-width:260px;
                    }
                }
                .ceiwn4_access {
                    margin-top:2.5rem;
                    margin-bottom:2.875rem;
                }
            }
            .ceiwm_img {
                margin-top:4.5rem;
                text-align:center;
                img{
                    max-width:395px;
                }
            }
        }
    }
    @media screen and (min-width: 320px) and (max-width:575px){
        .ceiw_main{
            flex-direction: column;
            padding: 3rem 1.5rem;
            height:100%;
            .ceiwm_404{
                .ceiwn4_header{
                    margin-top:0;
                    margin-bottom:2rem;
                    padding:0 3.5rem;
                }
                .ceiwn4_access {
                    margin-top:2.5rem;
                    margin-bottom:2.875rem;
                }
            }
            .ceiwm_img {
                margin-top:4.5rem;
            }
        }
    }
`)