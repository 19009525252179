import StakingNode from 'container/StakingNew/stakingNode/StakingNode'
import Image from 'core/Image'
import Modal from 'core/ModalPop'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import StakingNew from './StakingNew'
import imgErrorConnectNode from 'assets/images/error-connect-node.svg'
import Text from 'core/Text'
import Button from 'core/Button'
import { TronContext } from 'contexts/tronWeb'

const StakingNewConnectNode = () => {
    const { data, tronWeb, action } = useContext(TronContext)
    //TODO check user join node
    const [openModalJoin, setOpenModalJoin] = useState<number>(3)//true:not join node, false:joined node//0 //1true:2:false//3:await
    const [successNode, setSuccessNode] = useState(false)
    const onChageSuccess = useCallback((e: boolean) => {
        setSuccessNode(e)
    }, [setSuccessNode])
    const onChangeModal = useCallback((e: number) => {
        setOpenModalJoin(e)
    }, [setOpenModalJoin])
    const onCloseModal = useCallback(() => {
        if (successNode === true) {
            setOpenModalJoin(2)
        } else {
            setOpenModalJoin(0)
        }
    }, [setOpenModalJoin, successNode])

    useEffect(() => {
        try {
            const checkUserJoin = async () => {
                const { status } = await data.stake?.users(action.address).call() || ''
                if (typeof status === "object") {

                    let statusModal = 0 as number
                    switch (Number(status)) {
                        case 0: {
                            statusModal = 1
                            break;
                        }
                        case 1: {
                            statusModal = 3
                            break;
                        }
                        case 2: {
                            statusModal = 2
                            break;
                        }
                        default:
                            statusModal = 0
                            break;
                    }
                    setOpenModalJoin(statusModal)
                } else {
                    //when missing status
                    setOpenModalJoin(0)
                }
            }

            checkUserJoin()
        } catch (error) {
            console.log('error user joined', error)
        }

    }, [tronWeb, data, action])
    // console.log('status modal', openModalJoin)
    return (
        <>
            {openModalJoin === 0 &&
                <PageNode className="container">
                    <Image src={imgErrorConnectNode} />
                    <div className="pn-title">
                        <Text text="Oh, sorry!" color="gray" fontWeight="bold" size="subHeading" />
                    </div>
                    <div className="pn-subtitle">
                        <Text text="Your success request was interrupted. Please match the user to empty node to continue visiting our staking website and have the best experience in our system!" />
                    </div>
                    <div className="pn-subtitle">
                        <Button
                            aria-activedescendant="approve"
                            text="Merge node"
                            styleBtn="btn-spin"
                            onClick={() => onChangeModal(1)}
                        />
                    </div>
                </PageNode>
            }

            {openModalJoin === 1 &&
                <Modal title="" onClose={onCloseModal} >
                    <StakingNode onClose={() => onChangeModal(2)} onChageSuccess={onChageSuccess} />
                </Modal>
            }
            {openModalJoin === 2 &&
                <StakingNew />
            }
            {openModalJoin === 3 &&
                <Modal title="" onClose={onCloseModal} >
                    <Text text="please wait connect node!" />
                </Modal>
            }
        </>
    )
}

export default StakingNewConnectNode
const PageNode = styled.div`
    text-align:center;
    .pn-title{
        margin-bottom:25px;
        margin-top:60px;
    }
    .pn-subtitle{
        max-width:500px;
        margin-left:auto;
        margin-right:auto;
        margin-bottom:45px;
    }
`