import React, { memo, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Text from '../../../core/Text'
import AssetInfo from './InfoDetails/AssetInfo'
import InfoDetails from './InfoDetails/InfoDetails'
import ModalDetails from './ModalDetails/ModalDetails'
import TableDetails from './TableDetails/TableDetails'
const DeffectBoloDetails = () => {
    const [openModal, setOnpenModal] = useState({
        open: false,
        status: 1,

    }
    );
    const handleClickOpenModal = (type: number, active: boolean) => {
        setOnpenModal({
            open: active,
            status: type
        })
    }
    let { id } = useParams() as any;
    const [dataBorrow, setDataBorrow] = useState({
        loanBalance: 0,
        newLoanBalance: 0,
        borrowingPower: 0,
        newBorrowingPower: 0,
        value: 0
    })
    const getDataBorrow = async () => {
        try {
            // setDataBorrow({
            //     loanBalance: 0,
            //     newLoanBalance: 0,
            //     borrowingPower: 0,
            //     newBorrowingPower: 0,
            //     value: 0
            // })
        } catch (error) {
            //TODO getDataBorrow
        }
    }
    const [dataDesposit, setDataDesposit] = useState({
        availableBalance: 0,
        newVailableBalance: 0,
        additional: 0,
        borrowing: 0,
        newBorrowing: 0,
        value: 0
    })
    const getDataDesposit = async () => {
        try {
            // setDataDesposit({
            //     availableBalance: 0,
            //     newVailableBalance: 0,
            //     additional: 0,
            //     borrowing: 0,
            //     newBorrowing: 0,
            //     value: 0
            // })
        } catch (error) {
            //TODO getDataBorrow
        }
    }
    useEffect(() => {
        getDataBorrow()
        getDataDesposit()
    }, [])
    const [timeLine, setTimeLine] = useState({
        active: 0
    })
    const handelClickTimeLine = (time: number) => {
        setTimeLine({
            active: time
        })
    }
    const [dataAssetInfo, setDataAssetInfo] = useState({
        price: 0,
        loan: 0,
        rate: 0,
        loansOut: 0,
        total: 0
    })
    const getDataAssetInfo = async () => {
        try {

        } catch (error) {
            //TODO getDataAssetInfo
        }
    }
    useEffect(() => {
        getDataAssetInfo()
    }, [])
    return (
        <DeffectBoloDetailsWrap className='container'>
            {openModal.open ?
                <ModalDetails
                    handleClickOpenModal={handleClickOpenModal}
                    openModal={openModal}
                    dataDesposit={dataDesposit}
                    dataBorrow={dataBorrow}
                /> : null}

            <div className="dbdw_link">
                <Link to='/deffectbolo'>
                    <Text text='DeffectBolo' size="small" color="dark" />
                </Link>
                <Text text='/ USDT coin' size="small" />
            </div>
            <div className="dbdw_cont">
                <div className="dbdwc_info">
                    <InfoDetails handleClickOpenModal={handleClickOpenModal} handelClickTimeLine={handelClickTimeLine} timeLine={timeLine} />
                    <AssetInfo dataAssetInfo={dataAssetInfo} />
                </div>
                <div className="dbdw_table">
                    <TableDetails />
                </div>
            </div>
        </DeffectBoloDetailsWrap>
    )
}

export default DeffectBoloDetails
const DeffectBoloDetailsWrap = memo(styled.div`
    position: relative;
    .dbdw_link{
        display:flex;
        justify-content:flex-start;
        margin-bottom:1.875rem;
        a{
            text-decoration:none;
            margin-right:0.5rem;
        }
    }
    .dbdw_cont{
        display:flex;
        justify-content:space-between;
        .dbdwc_info{
            flex:0.35;
        }
        .dbdw_table{
            flex:0.62;
            background: #262E3F 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
        }
    }
    @media screen and (max-width:1279px){
        .dbdw_cont{
            flex-direction:column;
            .dbdwc_info{
            margin-bottom:2rem;
            }

        }
    }
    @media screen and (max-width:1024px){
        .dbdw_cont{
            .dbdwc_info{
                margin-bottom:1.5rem;
            }
            .dbdw_table{
            border-radius: 5px;
        }

        }
    }
    @media screen and (max-width:767px){
        .dbdw_link{
            margin: 2rem 0 0 3rem;
        }
        .dbdw_cont{
            margin:2rem 3rem;
        }
    }
    @media screen and (max-width:576px){
        .dbdw_link{
            margin: 1rem 0 0 1rem;
        }
        .dbdw_cont{
            margin: 1rem;
        }
    }

`)