import { TronContext } from "contexts/tronWeb"
import { useContext, useEffect, useState } from "react"
import { formatCoin } from "./formatCoin"

export const useTreeChild = (address: string) => {
    const { data } = useContext(TronContext)
    const [value, setValue] = useState(0)
    useEffect(() => {
        try {
            const getValueNode = async () => {
                const result = await data.stake?.users(address).call() || ''
                if (result !== '') {
                    const { totalTree } = result
                    setValue(formatCoin(Number(totalTree)))
                }
            }
            getValueNode()
        } catch (error) {
            console.log('error get tree child', error)
        }
    }, [data, address])
    return value
}