import React, { useEffect, useState } from 'react'
const axios = require('axios');

export const useTotalWallet = () => {
    const [totalWallet, setTotalWallet] = useState(0)
    useEffect(() => {
        axios.get("https://apilist.tronscan.org/api/token_trc20?contract=TY2uWJK5kprU6d5R7YXxWcXQVJtyYdMEC1&showAll=1")
            .then(({ data }) => {
                // console.log('total wallet', data)
                if (!!data.trc20_tokens[0].holders_count) {
                    setTotalWallet(Number(data.trc20_tokens[0].holders_count || 0))

                }
            })
    }, [])
    return totalWallet
}
