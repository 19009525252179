import { gql, useApolloClient } from '@apollo/client'
import Axios from 'axios'
import { TronContext } from 'contexts/tronWeb'
import dayjs from 'dayjs'
import { formatCoin } from 'helps/formatCoin'
import React, { useContext, useEffect, useState } from 'react'
import { Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import styled from 'styled-components'
const CustomTooltip = (props) => {
    const { payload, label, active } = props
    if (active) {
        return (
            <TooltipContainer>
                <div className="ttc-label">Time: {dayjs(Number(label) || '').format("HH:mm - DD/MM/YYYY")}</div>
                <div className="ttc-value">Price: {payload?.[0]?.value || 0}</div>
            </TooltipContainer>
        )
    }
    else {
        return null
    }
}
const CustomLabel = (props) => {
    const { x, y, payload } = props
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="translate(17,0)">{dayjs(Number(payload?.value) || '').format("HH:mm")}</text>
        </g>
    )
}
const CustomizedDot = (props) => {
    const { cx, cy } = props;
    return (
        <circle cx={cx} cy={cy} r={3} stroke="#242938" strokeWidth={1} fill="#82ca9d" />
    );
}

const GET_PRICE_CHART = gql`
query getPriceChart($timeFrom:Float!,$timeTo:Float!){
    price_chart_get(fromTimestamp:$timeFrom,toTimestamp:$timeTo){
      value
      date
    }
  }
`
const HomePriceChart = () => {
    //TODO Price Chart get data
    interface PriceChartProps {
        time: string,
        price: number
    }
    //new api
    const { action } = useContext(TronContext)
    const [dataChart, setDataChart] = useState([])
    const client = useApolloClient();
    const getDataChart = (timeFrom: number, timeTo: number) => {
        const query = client.watchQuery({
            query: GET_PRICE_CHART,
            variables: { timeFrom, timeTo },
            fetchPolicy: "no-cache"
        })
        const result = query.subscribe(({ data: { price_chart_get } }) => {
            let arr = [] as Array<any>
            if (price_chart_get.length > 0) {
                for (let i = price_chart_get.length - 30; i < price_chart_get.length; i++) {
                    arr.push(price_chart_get[i])
                }
            }
            if (arr.length > 0) {
                const dataMapping = arr.map((item) => {
                    const { value: price, date } = item
                    const time = dayjs(date).unix() * 1000
                    return { time, price: formatCoin(Number(price || 0)) }
                }) as any
                setDataChart(dataMapping)
            }

        })
        return result
    }
    const timeTo = dayjs().unix()
    let reset = true
    const getPriceChartJustSwap = async () => {
        try {
            reset = true
            await Axios.get(`https://apilist.tronscan.io/api/justswap/kline?token_address=TY2uWJK5kprU6d5R7YXxWcXQVJtyYdMEC1&granularity=1h&time_start=${timeTo - (24 * 60 * 60)}&time_end=${timeTo}`)
                .then(({ data }) => {
                    let dataMapping = []
                    const { data: dataList } = data
                    dataMapping = dataList.length > 0 && dataList.map((item, index) => {
                        const { t: time, c: price } = item
                        return { time: Number(time) * 1000, price: Number(price) }
                    })
                    setDataChart(dataMapping)
                })
        } catch (error) {
            console.log('error get price chart', error)
        }
    }
    useEffect(() => {
        // const query = getDataChart(0, timeTo)
        getPriceChartJustSwap()
        return () => {
            reset = false
            // query && query.unsubscribe()
        }
    }, [action.address])
    //end new api
    return (
        <Wrap>
            <ResponsiveContainer>
                <LineChart
                    width={500}
                    height={400}
                    data={dataChart}
                    syncId="anyId"
                    margin={{
                        top: 10, right: 30, left: 30, bottom: 0,
                    }}
                >
                    {/* <CartesianGrid strokeDasharray="0 3" /> */}
                    <XAxis dataKey="time" tick={<CustomLabel />} />
                    <YAxis domain={['dataMin / 10**6', "dataMax / 10**6"]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line type="monotone" dataKey="price" stroke="#82ca9d" fill="#82ca9d" dot={<CustomizedDot />} />
                </LineChart>
            </ResponsiveContainer>
        </Wrap>
    )
}

export default HomePriceChart
const Wrap = styled.div`
    width:100%;
    height:300px;
`
const TooltipContainer = styled.div`
    background-color:#fff;
    padding:10px 20px;
    .ttc-value{
        margin-top:5px;
        color:#82ca9d;
    }
`