import { UsersContext } from 'contexts/UsersContext'
import Text from 'core/Text'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import numeral from 'numeral'

const StakingCounterTree = () => {
    //TODO get number total binary tree
    const { users } = useContext(UsersContext)
    return (
        <Wrap>
            <Text className="mr-1" text="Your Total System Node:" />
            <Text fontWeight="bold" text={`${numeral(users.totalTree).format("0,0")}`} />
        </Wrap>
    )
}

export default StakingCounterTree
const Wrap = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`