import StepNode from "components/StakingNew/stakingNode/StepNode";
import StakingSussess from "components/StakingNew/StakingSussess";
import StakingTreeNode from "components/StakingNew/StakingTreeNode";
import Button from "core/Button";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import imgJoined from "assets/images/joined.svg";
import Image from "core/Image";
import Text from "core/Text";
import { breakpointsMedia } from "assets/containts/breakpoints";
import { TronContext } from "contexts/tronWeb";
import { toast, ToastContainer } from "react-toastify";
interface StakingNodeProps {
  onClose: () => void;
  onChageSuccess: (e: boolean) => void;
}
const StakingNode = ({ onClose, onChageSuccess }: StakingNodeProps) => {
  const { ref, data, action, tronWeb } = useContext(TronContext);
  const [progressStep, setProgressStep] = useState(1);

  const [sponsor, setSponsor] = useState(ref);
  const [awaitSponsor, setAwaitSponsor] = useState(true);
  useEffect(() => {
    const getParentMember = async () => {
      const result =
        (await data.PrivateMember?.getParent(action.address).call()) || "";
      if (result !== "") {
        const parentMember = tronWeb.address.fromHex(result._parent);
        if (parentMember === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb") {
        } else {
          const newSponsor =
            (await data.stake?.users(parentMember).call()) || "";
          if (newSponsor !== "") {
            const addressParent = tronWeb.address.fromHex(
              newSponsor.treeParent
            );
            if (
              addressParent === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" &&
              parentMember !== "TUAaHSB5fzD4zf9yXZ5jGi25evpmDWe1mv"
            ) {
              toast.error(
                "Sponsor is not join staking first! please contact sponsor!",
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                }
              );
              setAwaitSponsor(false);
            } else {
              setSponsor(parentMember);
              setAwaitSponsor(true);
            }
          }
        }
      }
    };
    getParentMember();
  }, [action.address, ref, data]);
  const [activeNode, setActiveNode] = useState(undefined); //0:left,1:right
  const onChangeActive = useCallback(
    (e: any) => {
      setActiveNode(e);
    },
    [activeNode, setActiveNode]
  );

  const onNextStep = () => {
    if (activeNode === undefined) {
      return false;
    }
    if (!awaitSponsor) {
      toast.error("Sponsor wrong! please contact sponsor!", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return false;
    }
    setProgressStep(2);
  };
  const onConfirm = () => {
    try {

      const registerNode = async () => {
        const result = await action.stake
          .registerNode(sponsor, activeNode)
          .send({
            callValue: 0,
            feeLimit: 2e8,
          });
        if (result) {
          setProgressStep(3);
        }
      };
      registerNode().then(
        () => { },
        (error) => {
          console.log("error confirm data", error);
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    } catch (error) {
      console.log("error confirm node joined", error);
    }
  };
  useEffect(() => {
    if (progressStep === 3) {
      onChageSuccess(true);
    }
  }, [progressStep]);
  return (
    <>
      <ToastContainer />
      {progressStep === 3 ? (
        <SuccessWrap>
          <Image src={imgJoined} />
          <div className="sw-title">
            <Text text="Congrats! You have successfully matched the user to the empty node. Refer more firends to fill more empty nodes!" />
          </div>
          <div className="sw-btn">
            <Button
              onClick={onClose}
              aria-activedescendant="approve"
              text="Close"
              styleBtn="btn-spin"
            />
          </div>
        </SuccessWrap>
      ) : (
        <Container>
          <div className="snm-title">
            <Text
              size="subHeading"
              fontWeight="bold"
              text="Match user to the empty node"
            />
          </div>
          {/* <div className="snm-referral">
                        <CopyRef address="hello" title="Referral link" />
                    </div> */}
          <div className="snm-node">
            <div className="snm-node__title">
              <Text text="Step by step to match user to the empty node" />
            </div>
            <div className="snm-node__progress">
              <Wrap>
                <div className="sn-step">
                  <div className="sn-step__child">
                    <StepNode
                      title="STEP 1"
                      status={progressStep === 2 ? "done" : "loading"}
                      subTitle="Select the node position (left or right)"
                    />
                  </div>
                  <div className="devider"></div>
                  <div className="sn-step__child">
                    <StepNode
                      title="STEP 2"
                      status={progressStep === 2 ? "loading" : "disabled"}
                      subTitle="Successfully match the user to empty node"
                    />
                  </div>
                </div>
                <div className="sn-tree">
                  {progressStep === 1 && (
                    <>
                      <StakingTreeNode
                        username="Referrer"
                        userRef={sponsor}
                        nodeLeft="Left Node"
                        nodeRight="Right Node"
                        activeNode={activeNode}
                        onChangeActive={onChangeActive}
                      />
                      <div className="sn-tree__action">
                        <Button
                          onClick={onNextStep}
                          aria-activedescendant="approve"
                          text="Next"
                          styleBtn="btn-spin"
                          disabled={activeNode === undefined}
                        />
                      </div>
                    </>
                  )}
                  {progressStep === 2 && (
                    <>
                      <StakingSussess node={activeNode || 0} sponsor={ref} />
                      <div className="sn-tree__action">
                        <Button
                          onClick={onConfirm}
                          aria-activedescendant="approve"
                          text="Confirm"
                          styleBtn="btn-spin"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Wrap>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default StakingNode;
const SuccessWrap = styled.div`
  .sw-title {
    margin-top: 30px;
    margin-bottom: 50px;
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
  }
`;
const Container = styled.div`
  .snm-title {
    margin-bottom: 0;
  }
  .snm-referral {
    margin-bottom: 10px;
  }
  .snm-node {
    &__title {
      margin-bottom: 40px;
    }
  }
  ${breakpointsMedia.landscape} {
  }
  ${breakpointsMedia.desktop} {
  }
  ${breakpointsMedia.largeDesktop} {
    .snm-title {
      margin-bottom: 0;
    }
    .snm-referral {
      margin-bottom: 30px;
    }
    .snm-node {
      &__title {
        margin-bottom: 50px;
      }
    }
  }
`;
const Wrap = styled.div`
  background: #293246 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #171c2a1a;
  border-radius: 15px;
  position: relative;
  padding-bottom: 30px;
  .sn-step {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-20px);
    margin-bottom: 10px;
    &__child {
      margin: 0 30px;
    }
    .devider {
      width: 43px;
      height: 3px;
      background-color: #798fa6;
    }
  }
  .sn-tree {
    &__action {
      margin-top: 20px;
    }
  }
  ${breakpointsMedia.landscape} {
    padding-bottom: 30px;
    .sn-step {
      transform: translateY(-20px);
      margin-bottom: 30px;
      &__child {
        margin: 0 10px;
      }
      .devider {
        width: 43px;
        height: 3px;
      }
    }
    .sn-tree {
      &__action {
        margin-top: 20px;
      }
    }
  }
  ${breakpointsMedia.desktop} {
    padding-bottom: 10px;
    .sn-step {
      transform: translateY(-20px);
      margin-bottom: 00px;
      &__child {
        margin: 0 10px;
      }
      .devider {
        width: 43px;
        height: 3px;
      }
    }
    .sn-tree {
      &__action {
        margin-top: 10px;
      }
    }
  }
  ${breakpointsMedia.largeDesktop} {
    padding-bottom: 30px;
    .sn-step {
      transform: translateY(-20px);
      margin-bottom: 50px;
      &__child {
        margin: 0 30px;
      }
      .devider {
        width: 43px;
        height: 3px;
      }
    }
    .sn-tree {
      &__action {
        margin-top: 20px;
      }
    }
  }
`;
