import React from 'react'
import styled from 'styled-components'
import Image from './Image'
import Text from './Text'
import imgCoin from 'assets/images/coin.svg'
import configColor from 'assets/containts/configColor'
import numeral from 'numeral'
interface CoinProps {
    value: number,
    size?: "normal" | "tiny",
    color?: "normal" | "primary" | "red",
    format?: "0,0.00" | "0,0" | "0,0.000000",
    bold?: "bold" | "normal"
}
const Coin = ({ value, size = "normal", color, format = "0,0", bold = "normal" }: CoinProps) => {
    return (
        <Wrap className={size} aria-controls="coin">
            <p style={{ fontWeight: bold }} className={color}>{numeral(value).format(format)}</p>
            <Image src={imgCoin} />
        </Wrap>
    )
}

export default Coin
const Wrap = styled.div`
    font-size:16px;
    color:#fff;
    display:flex;
    align-items:center;
    line-height:1.5;
    img{
        width:16px;
        height:16px;
        margin-left:8px;
    }
    p{
        &.primary{
            color:#FFA143;
            font-weight:bold;
        }
        &.red{
            color:#FF7555;
            font-weight:bold;
        }
    }
    &.tiny{
        font-size:14px;
        
        img{
            width:16px;
            height:16px;
        }
        
    }
`