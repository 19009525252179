import React, { memo } from 'react'
import styled from 'styled-components'
import ActionUser from './ActionUser'
import Logo from './Logo'
import Search from './Search'
const HeaderComponent = () => {
    return (
        <Warp className='header-warp'>
            <Logo />
            <Search />
            <ActionUser />
        </Warp>
    )
}

export default memo(HeaderComponent)
const Warp = styled.div`
    display:flex;
    background-color:#262E3F;
    box-sizing:border-box;
    position:fixed;
    z-index:10;
    /* z-index:10; */

    width:100%;
    @media screen and (min-width:768px){
        height:80px;
    }
    @media screen and (min-width:320px) and (max-width:767px){
        height:54px;
   }
`