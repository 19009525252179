import React from 'react'
import Text from '../../../core/Text'
import I18n from 'i18n-js'

interface InformationItemProps {
    poloniex?: string
    text: string
    value?: string
    vote?: number
    num?: number
}
const InformationItem = ({ text, value, num, poloniex, vote }: InformationItemProps) => {

    return (
        <div className="iw_item">
            <div className="iwi_left">
                <Text text={text} />

            </div>
            <div className="iwi_right">
                {
                    value ? <Text text={value} /> : null
                }
                {
                    poloniex ? <Text text={text} fontWeight="bold" /> : null
                }
                {
                    vote ? <Text text={I18n.toNumber(vote, { precision: 0 })} fontWeight="bold" /> : null
                }
                {
                    num && num > 0 ? <Text text={`+${I18n.toNumber(num, { precision: 0 })}`} color="increase" /> :
                        num && num < 0 ? <Text text={I18n.toNumber(num, { precision: 0 })} color='reduction' /> : null
                }
            </div>
        </div>
    )
}

export default InformationItem
