import React, { useEffect, useState } from 'react'
import Text from '../../../core/Text'
import I18n from 'i18n-js'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
interface TableProps {
    proheader: string
    token: string
    Lasting: number
    Current: number
    totalCurent?: number
    Percentage: number
    rank: number
    rankTotal?: number
    apr: number
    id: number
}


const Table = ({ id, proheader, token, Lasting, Current, totalCurent, Percentage, rank, rankTotal, apr }: TableProps) => {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const getWidth = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', getWidth)
        return () => window.removeEventListener('resize', getWidth)
    }, [])
    let history = useHistory()
    const handelClickLink = (id: number) => {
        history.push(`/humanilies/id=${id}`)
    }
    return (

        <div className="tr" onClick={() => handelClickLink(id)} >
            <div className="td-id">
                <Text text={proheader} fontWeight="bold" />
                {
                    width > 1024 ? <Text text={`${token.slice(0, 10)}...${token.slice(-10)}`} size="small" color="coin" /> :
                        <Text text={`${token.slice(0, 5)}...${token.slice(-5)}`} size="small" color="coin" />
                }
            </div>
            <div className="td-time">
                <Text text={`${I18n.toNumber(Lasting)} `} fontWeight="bold" />
            </div>
            <div className="td">
                <Text text={`${I18n.toNumber(Current)} `} fontWeight="bold" />
                {

                    totalCurent && totalCurent > 0 ? <Text text={`+ ${I18n.toNumber(totalCurent)} `} size="small" color="increase" />
                        :
                        totalCurent && totalCurent < 0 ? <Text text={`- ${I18n.toNumber(totalCurent)} `} size="small" color="reduction" />
                            : null
                }
            </div>
            <div className="td">
                <Text text={` ${I18n.toNumber(Percentage, { precision: 2 })} %`} fontWeight="bold" />
            </div>
            <div className="td">
                <Text text={`${rank}`} color="coin" />
                {
                    rankTotal === undefined ? null :
                        rankTotal !== undefined && rankTotal > 0 ?
                            <div className="rank_total_up">
                                <Text text={`+${rankTotal} `} size="small" color="increase" /><BsArrowUp />

                            </div>
                            :
                            <div className="rank_total_down">
                                <Text text={`${rankTotal} `} size="small" color="reduction" /><BsArrowDown />
                            </div>

                }
            </div>
            <div className="td-rate">
                <Text text={` ${I18n.toNumber(apr, { precision: 2 })} %`} color="coin" />

            </div>
        </div>
    )
}

export default Table