import React, { useEffect, useState } from 'react'
const axios = require('axios');

export const useTotalTransfer = () => {
    const [totalTransfer, setTotalTransfer] = useState(0)
    useEffect(() => {
        axios.get("https://apilist.tronscan.org/api/token_trc20/transfers?contract_address=TY2uWJK5kprU6d5R7YXxWcXQVJtyYdMEC1&limit=0")
            .then(({ data }) => {
                setTotalTransfer(Number(data?.rangeTotal || 0))
            })
    }, [])
    return totalTransfer
}
