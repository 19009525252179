import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import { TronContext } from '../../../../contexts/tronWeb';
import Text from '../../../../core/Text';
import ModalDetailsFormInputItem from './ModalDetailsFormInputItem'
interface ModalDetailsFormInputProps {
    // banlanceCoin: {
    //     coinTRX: number
    //     coinUSDT: number
    // }
    // usdtAmount: string
    // setUsdtAmount: any
    // errorInput: string
    // setErrorInput: any
    // BuyConfirm: (usdtAmount: number) => void
    // setopenModal: (e: boolean) => void
    openModal: {
        open: boolean,
        status: number,
    }
}

const ModalDetailsFormInput = (
    // { banlanceCoin, usdtAmount, setUsdtAmount, errorInput, setErrorInput, BuyConfirm, setopenModal }:
    { openModal }: ModalDetailsFormInputProps
) => {
    const { action } = useContext(TronContext);
    const handelClickBuy = (usdtAmount: number) => {
        // if (action.address !== undefined && action.address !== 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
        //     BuyConfirm(usdtAmount)
        // } else {
        //     setopenModal(true)
        //     //TODO ConfirmStaking
        // }

    }
    return (
        <ModalDetailsFormInputWrap>
            <div className="sfiw_header">
                <Text text="Input number" size="small" />
            </div>
            <ModalDetailsFormInputItem
            // banlanceCoin={banlanceCoin}
            // usdtAmount={usdtAmount}
            // setUsdtAmount={setUsdtAmount}
            // errorInput={errorInput}
            // setErrorInput={setErrorInput}
            />
            <div className="sfiw_balance">
                <Text text={`Balance USDT: 0
                `} />
            </div>
            <div className="sfiw_button">
                <button
                // onClick={() => handelClickBuy(Number(usdtAmount))}
                // disabled={
                //     (action.address === undefined &&
                //         action.address === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" &&
                //         errorInput !== "") ||
                //     Number(usdtAmount) < 100
                // }
                ><Text text="Cancel" /> </button>
                {
                    openModal.status === 1 ?
                        <button
                        // onClick={() => handelClickBuy(Number(usdtAmount))}
                        // disabled={
                        //     (action.address === undefined &&
                        //         action.address === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" &&
                        //         errorInput !== "") ||
                        //     Number(usdtAmount) < 100
                        // }
                        ><Text text="Borrow" /> </button>
                        :
                        <button
                        // onClick={() => handelClickBuy(Number(usdtAmount))}
                        // disabled={
                        //     (action.address === undefined &&
                        //         action.address === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" &&
                        //         errorInput !== "") ||
                        //     Number(usdtAmount) < 100
                        // }
                        ><Text text="Deposit" /> </button>

                }

            </div>

        </ModalDetailsFormInputWrap>
    )
}

export default ModalDetailsFormInput
const ModalDetailsFormInputWrap = memo(styled.div`
    .sfiw_header{
        margin-bottom:1rem;
    }
    .sfiw_balance{
        text-align:end;
        font-style:italic;
        margin-top:0.5rem;
        margin-bottom:3.75rem;
    }
    .sfiw_button{
        display:flex;
        justify-content:space-around;
        flex-direction:row;
        button:first-child{
            background: #45617E 0% 0% no-repeat padding-box;
            :hover {
                background: #4B75A2 0% 0% no-repeat padding-box;
            }
        }
        button{
                width:40%;
            background: transparent linear-gradient(270deg,  #FFAE5D 0%, #FF8467 100%) 0% 0% no-repeat padding-box;
            border-radius: 20px;
            padding:0.75rem 2.875rem;
            border:none;
            outline:none;
            display:flex;
            justify-content:center;
            cursor: pointer;
            :hover {
                background: transparent
                    linear-gradient(270deg, #ffa143 0%, #ff7555 100%) 0% 0% no-repeat
                    padding-box;
                }
            :disabled {
                background: transparent
                    linear-gradient(270deg, #ab733a 0%, #ab5a45 100%) 0% 0% no-repeat
                    padding-box;
                cursor: not-allowed;
                p{
                    color: #ababab !important;
                }
                :hover {
                    background: transparent
                    linear-gradient(270deg, #ab733a 0%, #ab5a45 100%) 0% 0% no-repeat
                    padding-box;
                }
            }
        
        }
    }
   
    @media screen and (max-width:1599px){
        .sfiw_header{
            margin-bottom:1rem;
        }
   
        .sfiw_balance{
            margin-bottom:3.75rem;
        }
    }
    @media screen and (max-width:1279px){
        .sfiw_header{
            margin-bottom:0.5rem;
        }
   
        .sfiw_balance{
            margin-bottom:2.75rem;
        }
    }
    @media screen and (max-width:1024px){
        .sfiw_header{
            margin-bottom:1rem;
        }
   
        .sfiw_balance{
            margin-bottom:2.75rem;
        }
    }
    @media screen and ( max-width:992px ){
        .sfiw_header{
            margin-bottom:1rem;
        }
   
        .sfiw_balance{
            margin-bottom:2.75rem;
        }
    }
    @media screen and (max-width:767px){
        .sfiw_button{
            button{
                width:45%;      
            }
        }
    }
   
`)