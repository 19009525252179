import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
import Table from './Table'
import Pagination from './Pagination'
import { useLocation } from 'react-router-dom'

const HumaniliesTable = () => {
    const data = [
        { page: 1, id: 1, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: - 1, apr: 5.49 },
        { page: 1, id: 2, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, apr: 5.49 },
        { page: 1, id: 3, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 1, id: 4, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 1, id: 5, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 1, id: 6, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 1, id: 7, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 1, id: 8, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 1, id: 9, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 1, id: 10, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
        { page: 2, id: 11, proheader: 'BlockchainOrg', token: 'TLyqzVGLV1srkB7dToTAEqgDSfPtXRJZYH', Lasting: 364498223, Current: 364498223, totalCurrent: 55140, Percentage: 40.84, rank: 23, rankTotal: 1, apr: 5.49 },
    ]
    const [dataHumanTable, setDataHumanTable] = useState() as any;
    useEffect(() => {
        const getDataHumanTable = async () => {
            try {

            } catch (error) {
                console.log(error);
                // TODO getDataHumanTable
            }
        }
        getDataHumanTable()
    }, [])



    const [dataTable, setDataTable] = useState([]) as any
    const [pagination, setPagination] = useState({
        _page: 1,
        _limit: 10,
        _totalRows: 1,

    })
    const [fitterPage, setFitterPage] = useState({
        _page: 1,
        _limit: 10
    })
    const handelChangePage = (newPage: number) => {
        setFitterPage({
            ...fitterPage,
            _page: newPage
        })
    }
    useEffect(() => {
        if (fitterPage._page === 1) {
            let a = data.filter(x => x.page === 1);
            setDataTable(a);
        }
        if (fitterPage._page === 2) {
            let a = data.filter(x => x.page === 2);
            setDataTable(a);
        }

        setPagination({
            ...pagination,
            _totalRows: data.length,
            _page: fitterPage._page,
        })
    }, [fitterPage])
    return (
        <HumaniliesTableWrap>
            <div className="htw_header">
                <Text text="List of projects to vote" fontWeight="bold" size="xNormal" />
            </div>
            <div className="htw_content">
                <div className="table">
                    <div className="tr">
                        <div className="td-id"><Text text='Proposer' fontWeight="bold" /></div>
                        <div className="td-time"><Text text='Lasting votes' fontWeight="bold" /></div>
                        <div className="td-time"><Text text='Current votes' fontWeight="bold" /></div>
                        <div className="td"><Text text='Percentage' fontWeight="bold" /></div>
                        <div className="td"><Text text='Current rank' fontWeight="bold" /></div>
                        <div className="td-rate"><Text text='APR' fontWeight="bold" /></div>
                    </div>
                    {dataTable.map((t, index) => {
                        return <Table
                            key={index}
                            proheader={t.proheader}
                            token={t.token}
                            Lasting={t.Lasting}
                            Current={t.Current}
                            totalCurent={t.totalCurrent}
                            Percentage={t.Percentage}
                            rank={t.rank}
                            rankTotal={t.rankTotal}
                            apr={t.apr}
                            id={t.id}
                        />
                    })}
                </div>

            </div>
            <Pagination
                onPageChange={handelChangePage}
                pagination={pagination}
            />
        </HumaniliesTableWrap>
    )
}

export default HumaniliesTable
const HumaniliesTableWrap = memo(styled.div`
    background: #262E3F 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:2rem;
    .htw_header{
        margin-bottom:2rem;
    }
    .htw_content{
        overflow-x:auto;
        .table {
            
            display: table;
            border-collapse: collapse;
            width:100%;
            >div:last-child{
                border-bottom:0;
            }
            >div:first-child{
                cursor: default;
                :hover{
                    background: transparent;
                }

            }
            .tr {    
                :hover{
                    background: rgba(56, 68, 93, 0.3);
                }
                display: table-row;
                border-bottom: 1px solid rgba(58, 78, 110,0.5);
                cursor: pointer;
                .td-id{
                    padding: 1rem 1rem 1rem 0  ;
                    min-width:100px;
                    display: table-cell;
                    text-align:start;
                    vertical-align:middle;
                }
                .td-time{
                    padding:  1rem  ;
                    min-width:150px;
                    text-align:center;
                    vertical-align:middle;
                    display: table-cell;
                }
                .td {
                    padding:  1rem  ;
                    min-width:100px;
                    display: table-cell;
                    text-align:center;
                    vertical-align:middle;
                   
                    .rank_total_up{
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        svg{
                            fill:#28DE44;
                        }
                    }
                    .rank_total_down{
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        svg{
                            fill:#FF4355;
                        }
                    }
                }
                .td-rate{
                    min-width:100px;
                    display: table-cell;
                    vertical-align:middle;
                    padding:  1rem  0 1rem 1rem;
                    text-align:end;            
                }
            }
        }
    }
    .htw_content::-webkit-scrollbar {
        display: none;
    }

    .htw_content {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    }
    @media screen and (max-width:1024px){
        padding:1.875rem;
        .htw_header{
        margin-bottom:1rem;
    }
       
    }
    @media screen and  (max-width:767px){
        padding:1.875rem 1.5rem;
        border-radius:5px;
        margin:0 1.5rem;
        .htw_header{
            margin-bottom:0.5rem;
        }
    }
    @media screen and  (max-width:576px){
        margin:0 1rem; 
    }
`)