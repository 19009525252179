import Image from 'core/Image'
import Text from 'core/Text'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import imgCopy from 'assets/images/copy.svg'
import { AiOutlineCheck } from 'react-icons/ai'
import { RiFileCopyLine } from 'react-icons/ri'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TronContext } from 'contexts/tronWeb'
import { breakpointsMedia } from 'assets/containts/breakpoints'
const StakingRefCopy = () => {
    const { action } = useContext(TronContext)
    let addressRef = window.location.origin + "?r=" + action.address
    useEffect(() => {
        addressRef = window.location.origin + "?r=" + action.address
    }, [action.address])
    const [copied, setCopied] = useState(false)
    const onCopyAddress = () => {
        setCopied(true)
    }
    useEffect(() => {
        const time = setTimeout(() => { setCopied(false) }, 3000)
        return () => {
            clearTimeout(time)
        }
    }, [copied])

    return (
        <Wrap>
            <div className="src-title">
                <Text text="Copy referral link below and share it with your friends anywhere" />

            </div>
            <div className="src-copy">
                <div className='src-copy__input'>
                    <div className="adress">
                        <Text color="coin" text={addressRef} />
                    </div>
                    {/* <Image src={imgCopy} /> */}
                    <div className="icon">
                        {
                            copied ?

                                <AiOutlineCheck size={20} color="#fff" />
                                :
                                <CopyToClipboard
                                    text={addressRef}
                                    onCopy={onCopyAddress}
                                >
                                    <RiFileCopyLine size={20} color="#fff" />

                                </CopyToClipboard>

                        }
                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default StakingRefCopy
const Wrap = styled.div`
    .src-title{
        text-align:center;
        margin-bottom:20px;
    }
    .src-copy{
        background: #293246 0% 0% no-repeat padding-box;
        border-radius: 5px;
        width:100%;

        &__input{
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:12px 20px;
            p{
                word-break:break-all;
            }
            .icon{
                margin-left:15px;
                cursor: pointer;
            }
        }
    }
    ${breakpointsMedia.largeDesktop}{
        .src-copy{
            width:80%;
            &__input{
                .icon{
                    margin-left:0;
                }
            }
        }
    }
`