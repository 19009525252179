import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import DeffectBoloContent from './DeffectBoloContent/DeffectBoloContent'
import DeffectBoloHeader from './DeffectBoloHeader/DeffectBoloHeader'

const DeffectBolo = () => {
    const [dataCoinBolo, setDataCoinBolo] = useState({
        totalPersonal: 0,
        dailyInterest: 0,
        totalIncome: 0,
        interestRate: 0
    })
    const getDataCoinBolo = async () => {
        try {
            // setDataCoinBolo({
            //     totalPersonal:0,
            //     dailyInterest:0,
            //     totalIncome:0,
            //     interestRate:0
            // })
        } catch (error) {
            //TODO getDataCoinBolo
        }
    }
    useEffect(() => {
        getDataCoinBolo()
    }, [])
    const [dataCoinBoloCont, setDataCoinBoloCont] = useState({
        deposit: 0,
        depositMining: 0,
        depositMarket: 200,
        borrow: 200,
        apr: 4.81,
        size: 392
    })
    const getDataCoinBoloCont = async () => {
        try {

        } catch (error) {
            //TODO getDataCoinBoloCont
        }
    }
    useEffect(() => {

        getDataCoinBoloCont()
    }, [])
    return (
        <DeffectBoloWarp className='container'>
            <div className='dbw_header'>
                <DeffectBoloHeader dataCoinBolo={dataCoinBolo} />
            </div>
            <div className='dbw_cont'>
                <DeffectBoloContent dataCoinBoloCont={dataCoinBoloCont} />
            </div>
        </DeffectBoloWarp>
    )
}

export default DeffectBolo
const DeffectBoloWarp = memo(styled.div`
    .dbw_header{
        display:flex;
        justify-content:center;
        margin-bottom:2rem;
    }
    @media screen and (max-width:1024px){
        margin-bottom:1.5rem;
    }
`)