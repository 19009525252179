import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import Text from "../../../core/Text";

interface InfomationCopyProps {
    onCopy: {
        value: string,
        copied: boolean
    }
    setOnCopy: any
}
const InfomationCopy = ({ onCopy, setOnCopy }: InfomationCopyProps) => {
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        const getWidth = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', getWidth)
        return () => window.removeEventListener('resize', getWidth)
    }, [])
    return (
        <InfomationCopyWarp>
            {onCopy.copied ? (
                <div className="copy">
                    <Text text="Copy Link" />
                </div>
            ) : null}
            <div className="adress">
                <Text text="Address:" />
            </div>
            <CopyToClipboard
                text={`${onCopy.value}`}
                onCopy={() => setOnCopy({ ...onCopy, copied: true })}
            >
                <div className="text-copy">
                    {
                        width > 1280 ?
                            <Text className="copylink" text={`${onCopy.value.slice(0, 10)}...${onCopy.value.slice(-10)}`} />
                            :
                            <Text className="copylink" text={`${onCopy.value.slice(0, 5)}...${onCopy.value.slice(-5)}`} />
                    }
                </div>
            </CopyToClipboard>
            <CopyToClipboard
                text={`${onCopy.value}`}
                onCopy={() => setOnCopy({ ...onCopy, copied: true })}
            >
                <div className="usmlc_action">
                    <div className="usmlca_left" />
                    <div className="usmlca_right" />
                </div>
            </CopyToClipboard>
        </InfomationCopyWarp>
    );
};

export default InfomationCopy;
const InfomationCopyWarp = memo(styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #293246;
  border-radius: 5px;
  width: 100%;
  padding:1rem 2rem;
  position: relative;
  .copy {
    position: absolute;
    right: 0;
    bottom: -2.5rem;
    width: 5rem;
    z-index: 20;
    background-color: #28DE44;
    border-radius: 5px;
    padding: 0.5rem 0;
    text-align: center;
    }
  
  .adress{
        width:50%;
  }
  .text-copy {
    width:50%;
   
    .copylink {
      cursor: pointer;
    }
  }
  .usmlc_action {
    position: relative;
    .usmlca_left {
      position: absolute;
      top: 0px;
      right: 10px;
      width: 0.8125rem;
      height: 1.0625rem;
      border: 1px solid#fff;
      border-radius: 3px;
      cursor: pointer;
    }
    .usmlca_right {
      position: absolute;
      cursor: pointer;
      top: 3px;
      right: 13px;
      width: 0.8125rem;
      height: 1.0625rem;
      border: 1px solid#fff;
      z-index: 1;
      background: #293246;
      border-radius: 3px;
    }
  }
 
    @media screen and (max-width:767px){
        padding:0.875rem 1rem ;   
    }
    @media screen and (max-width:576px){
        padding:0.75rem  1rem;
    }
`);
