import React, { memo, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Image from "../../core/Image";
import Text from "../../core/Text";
import { useLocation } from "react-router-dom";
import { MenuOpenContext } from "../../contexts/MenuOpenContext";

// import SeedRound from '../../assets/images/seedround.svg'
// import SeedRoundActive from '../../assets/images/seedround-active.svg'
import Swap from "../../assets/images/swap.svg";
import SwapActive from "../../assets/images/swap-active.svg";
import Staking from "../../assets/images/staking.svg";
import StakingActive from "../../assets/images/staking-active.svg";
import Home from "../../assets/images/home.svg";
import HomeActive from "../../assets/images/home-active.svg";
import deffectbolo from "../../assets/images/deffectbolo.svg";
import deffectboloActive from "../../assets/images/deffectbolo-active.svg";
import deffectgame from "../../assets/images/deffectgame.svg";
import deffectgameActive from "../../assets/images/deffectgame-active.svg";
import human from "../../assets/images/human.svg";
import humanActive from "../../assets/images/human-active.svg";
import imgBinary from "assets/images/binary-tree.svg";
import imgBinaryActive from "assets/images/binary-tree-hover.svg";
// import Presale from '../../assets/images/price.svg'
// import PresaleActive from '../../assets/images/price-active.svg'

const LinkAction = () => {
  let location = useLocation();
  const { isOpenMenu, handleOpenMenu } = useContext(MenuOpenContext);
  const dataLink = [
    // { id: 1, path: '/', img: Presale, imgactive: PresaleActive, text: 'Presale' },
    // { id: 2, path: '/seed', img: SeedRound, imgactive: SeedRoundActive, text: 'SeedRound' },
    { id: 1, path: "/", img: Home, imgactive: HomeActive, text: "Home" },
    {
      id: 2,
      path: "/staking",
      img: Staking,
      imgactive: StakingActive,
      text: "Staking",
    },
    // { id: 3, path: '/swap', img: Swap, imgactive: SwapActive, text: 'Swap' },
    {
      id: 4,
      path: "/deffectbolo",
      img: deffectbolo,
      imgactive: deffectboloActive,
      text: "DeffectBolo",
    },
    // {
    //   id: 5,
    //   path: "/deffectgame",
    //   img: deffectgame,
    //   imgactive: deffectgameActive,
    //   text: "DeffectGame",
    // },
    {
      id: 6,
      path: "/humanilies",
      img: human,
      imgactive: humanActive,
      text: "Humanilies",
    },
    // {
    //   id: 7,
    //   path: "/direct-ref",
    //   img: Staking,
    //   imgactive: StakingActive,
    //   text: "Direct Referral",
    // },
    // {
    //   id: 8,
    //   path: "/binary-tree",
    //   img: imgBinary,
    //   imgactive: imgBinaryActive,
    //   text: "System Node",
    // },
  ];
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const addHeight = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", addHeight);
    return () => {
      window.removeEventListener("resize", addHeight);
    };
  }, []);
  return (
    <LinkActionWarp isOpenMenu={isOpenMenu} height={height}>
      {dataLink.map((item, index) => {
        return (
          <Link
            key={index}
            to={item.path}
            className={`${location.pathname === `${item.path}` && "active"}`}
            onClick={() => handleOpenMenu(!isOpenMenu)}
          >
            {location.pathname === `${item.path}` ? (
              <Image className="law_image" src={item.imgactive} />
            ) : (
              <Image className="law_image" src={item.img} />
            )}
            <div className="law_text">
              <Text text={item.text} />
            </div>
          </Link>
        );
      })}
    </LinkActionWarp>
  );
};

export default memo(LinkAction);
const LinkActionWarp = memo(styled.div`
  position: fixed;
  z-index: 1;
  height: ${(props: any) => Number(props.height) - 80}px;
  width: 200px;
  padding: 10px 0 0 10px;
  background-color: #262e3f;
  box-sizing: border-box;
  overflow-y: auto;
  .active {
    background-color: rgba(43, 55, 75, 1);
    p {
      color: #ffa143 !important;
    }
  }
  a {
    display: flex;
    height: 56px;
    align-items: center;
    text-decoration: none;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    :hover {
      background-color: rgba(43, 55, 75, 1);
      color: #ffa143;
    }
    .law_image {
      margin-right: 5px;
    }
  }
  @media screen and (min-width: 1600px) {
    width: 200px;
  }
  @media screen and (max-width: 1599px) {
    width: 160px;
  }

  @media screen and (max-width: 767px) {
    height: ${(props: any) => Number(props.height) - 54}px;

    display: ${(props: any) => (props.isOpenMenu ? "block" : "none")};
    position: ${(props: any) => (props.isOpenMenu ? "fixed" : "")};
    z-index: 1;
    background-color: #242938;
    /* padding: 10px 10px 0 10px; */

    /* width:4.5rem;
        .law_text{
            display:none;
        } */
  }
`);
