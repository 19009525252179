import React, { useEffect } from 'react'
import ArrayChartInfo from './ArrayChartInfo'

const ArrayDataYear = () => {
    // const [dataYear, setDataYear] = useState({
    //     name:'',
    //     borrow:0,
    //     Deposit:0,
    // })
    const getDataYear = async () => {
        try {

        } catch (error) {
            //TODO getDataYear
        }
    }
    useEffect(() => {
        getDataYear()
    }, [])
    const dataYear = [
        { "name": "2017", "Borrow": 2600, "Deposit": 2400, },
        { "name": "2018", "Borrow": 2400, "Deposit": 3400, },
        { "name": "2019", "Borrow": 6000, "Deposit": 2000, },
        { "name": "2020", "Borrow": 5000, "Deposit": 4400, },

    ]
    return (
        <ArrayChartInfo data={dataYear} />

    )
}

export default ArrayDataYear
