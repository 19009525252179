import React, { memo, useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import Information from './Information'

import VoteInformation from './VoteInformation'
import { TronContext } from '../../../contexts/tronWeb'
import Text from '../../../core/Text'
import ConfirmationStakingPool from '../../popup/ConfirmationStakingPool'
import LoginTron from '../../popup/LoginTron'
import { Link, useParams } from 'react-router-dom'

const HumaniliesInformation = () => {
    const { action, data, tronWeb } = useContext(TronContext);

    const [onCopy, setOnCopy] = useState({
        value: `${action.address}`,
        copied: false,
    });
    useEffect(() => {
        if (!!onCopy.copied) {
            const onTimer = setTimeout(() => {
                setOnCopy({ ...onCopy, copied: false });
            }, 5000);
            return () => {
                clearTimeout(onTimer);
            };
        }
    }, [onCopy]);

    const [banlanceCoin, setBalanceCoin] = useState({
        coinTRX: 0,
        coinUSDT: 0,
    });
    useEffect(() => {
        const getBalanceTRX = async () => {
            try {
                let trx = await tronWeb.trx.getBalance(action.address);
                let coinUSDT = await data.usdt.balanceOf(action.address).call();
                setBalanceCoin({
                    coinTRX: Number(trx) / 1e6,
                    coinUSDT: Number(coinUSDT) / 1e6,
                });
            } catch (error) {
                console.log("errorGetBalance", error);
            }
        };
        tronWeb &&
            action.address !== undefined &&
            action.address !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" &&
            getBalanceTRX();
    }, [tronWeb, action.address, data]);

    const BuyConfirm = async (usdtAmount: number) => {
        try {
            setOpenModalComfirm(true)

        } catch (error) {
            console.log("BuyConfirm", error)
            //TODO BuyConfirm

        }
    }
    const [openModalComfirm, setOpenModalComfirm] = useState(false)
    const [usdtAmount, setUsdtAmount] = useState('')
    const [errorInput, setErrorInput] = useState("");
    const [openModal, setopenModal] = useState(false)
    let { id } = useParams() as any;
    return (
        <HumaniliesInformationWrap className='container'>
            <div className="humaniw_header">
                <Link to="/humanilies">
                    <Text text="Humanities " color="coin" />

                </Link>
                <Text text="/ Super Representatives " />

            </div>
            <div className="humaniw">
                {openModalComfirm ? <ConfirmationStakingPool setOpenModalComfirm={setOpenModalComfirm} /> : null}
                {openModal ? <LoginTron setopenModal={setopenModal} /> : null}


                <div className="hiw_info">
                    <Information onCopy={onCopy} setOnCopy={setOnCopy} />
                </div>
                <div className="hiw_vote">
                    <VoteInformation

                        banlanceCoin={banlanceCoin}
                        setUsdtAmount={setUsdtAmount}
                        usdtAmount={usdtAmount}
                        errorInput={errorInput}
                        setErrorInput={setErrorInput}
                        BuyConfirm={BuyConfirm}
                        setopenModal={setopenModal}
                    />
                </div>
            </div>


        </HumaniliesInformationWrap>
    )
}

export default HumaniliesInformation
const HumaniliesInformationWrap = memo(styled.div`
   .humaniw_header{
        display:flex;
        justify-content:flex-start;
        margin-bottom:1.875rem;
        a{
            text-decoration:none;
        }
        p:first-child{
            margin-right:0.5rem !important;
        }
    }
    .humaniw{
        display:flex;
        justify-content:space-between;
        position: relative;
        
        .hiw_info{
            flex:0.64;
            width:100%;
            background-color:#262E3F;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
        }
        .hiw_vote{
            flex:0.34;
            width:100%;
            background-color:#262E3F;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            padding:2rem 4rem;
          
        }
       
    }
   
    @media screen and (max-width: 1600px){
        .humaniw{
            .hiw_info{
                flex:0.64;
                /* padding:2rem; */
            }
            .hiw_vote{
                flex:0.34;
                padding:2rem 3rem;
            }
        }
    }
    @media screen and (max-width: 1200px){
        .humaniw{
            .hiw_info{
                flex:0.55;
                /* padding:2rem; */
            }
            .hiw_vote{
                flex:0.43;
                padding:2rem;
            }
        }
    }
    @media screen and (max-width: 1024px){
        .humaniw{
            flex-direction:column;
            .hiw_info{
                /* padding:2rem 4rem; */
                margin-bottom : 2rem;
                border-radius: 5px;
            }
            .hiw_vote{
                padding:2rem 4rem;
                border-radius: 5px;
            }
        }
        
    }
    @media screen and (max-width: 767px){
        .humaniw_header{
            padding: 2rem 1rem 0;
        }
        .humaniw{
            margin: 0 1.5rem;
            flex-direction:column;
            .hiw_info{
                margin-bottom : 1.75rem;
            }
            .hiw_vote{
                padding:2rem 4rem;
            }
        }
    }
    @media screen and (max-width: 576px){
        
        .humaniw{
            margin: 0 1rem;
            flex-direction:column;
            
            .hiw_info{
                margin-bottom : 1.5rem;
            }
            .hiw_vote{
                padding:2rem 1rem;
               
            }
        }
    }
`)