import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
import InformationCopy from './InfomationCopy'
import InformationItem from './InformationItem'
interface InformationProps {
    onCopy: {
        value: string,
        copied: boolean
    }
    setOnCopy: any
}
const Information = ({ onCopy, setOnCopy }: InformationProps) => {
    const data = [
        { id: 1, text: "Proposer:", poloniex: 'Poloniex' },
        { id: 2, text: "Time of Creation (UTC):", value: '10/29/2020 7:59 AM' },
        { id: 3, text: "Time of Expire (UTC):", value: '10/29/2020 7:59 AM' },
        { id: 4, text: "Project start time:", value: '10/29/2020 7:59 AM' },
        { id: 5, text: "Proposal Status:", value: 'Voting' },
        { id: 6, text: "Current votes:", vote: 364444444, num: 55140 },
        { id: 7, text: "Percent was voted:", value: '30%' },
        { id: 8, text: "Website:", value: 'https://poloniex.com/' },
    ]
    return (
        <InformationWrap>
            <div className="iw_header">
                <Text text='Information' fontWeight="bold" size="xNormal" />
            </div>
            <InformationCopy onCopy={onCopy} setOnCopy={setOnCopy} />
            <div className="iw_cont">
                {data.map((t, index) => {
                    return <InformationItem key={index} text={t.text} num={t.num} value={t.value} poloniex={t.poloniex} vote={t.vote} />
                })}
            </div>
        </InformationWrap>
    )
}

export default Information
const InformationWrap = memo(styled.div`
   
    .iw_header{
       padding: 2rem 2rem 0;
       margin-bottom:1.5rem;
    }
    .iw_cont{
        padding:0 2rem;
        >div:last-child{
                border-bottom:0;
        }
        .iw_item{
            padding:1rem 0;
            display:flex;
            border-bottom: 1px solid rgba(58,78,110,0.5);
            .iwi_left{
                flex:0.5;
            }
            .iwi_right{
                flex:0.5;
                display:flex;
                justify-content:flex-start;
                align-items:center;
                p:first-child{
                    margin-right:1rem !important;
                }
            }
        }
    }
    @media screen and (max-width:1599px){
        .iw_header{
        margin-bottom:1.5rem;

        }
        .iw_cont{
            padding:0 2rem;
        }
    }
    @media screen and (max-width:1024px){
        .iw_header{
            margin-bottom:1rem;
        }
    }
    @media screen and (max-width:767px){
        .iw_header{
            padding:2rem 1rem 0 ;
            margin-bottom:1rem;
        }
        .iw_cont{
            padding:0 1rem;
         
            .iw_item{
                padding:0.875rem 0;
                .iwi_right{
                    p:first-child{
                        margin-right:0.5rem !important;
                    }
                }
            }
        }
    }
    @media screen and (max-width:576px){
        .iw_header{
            padding:2rem 1rem 0 ;
            margin-bottom:1rem;
        }
        .iw_cont{
            padding:0 1rem;
            .iw_item{
                padding:0.75rem 0;
            }
        }
    }
`)