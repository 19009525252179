import React, { memo } from 'react'
import styled from 'styled-components'
import close from 'assets/images/close.svg'
import CancelComfirmImg from 'assets/images/cancel-comfirm.svg'
import Text from './Text'
import { breakpointsMedia } from 'assets/containts/breakpoints'
interface CancelConfirmationProps {
    onClose: any;
    title: string,
    children: any,
}
const Modal = ({ onClose, title, children }: CancelConfirmationProps) => {
    return (
        <Wrap>
            <div className="ccw_inner">
                <div className="ccwi_close" onClick={onClose}>
                    <img src={close} alt="" />
                </div>
                <div className="ccwi_content">
                    <div className="ccwic_main">
                        <div className="ccwicm_header">
                            <Text text={title} size='subHeading' fontWeight="bold" />
                        </div>
                        <div className="ccwicm_cont">
                            <div className="ccwicmc_header">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default Modal
const Wrap = memo(styled.div`
    background-color:rgba(0,0,0,0.8);
    width:100vw;
    height:100vh;
    position:fixed;
    z-index:20;
    top:0;
    left:0;
    .ccw_inner{
        background-color:#262E3F;
        box-shadow: 0px 3px 6px #00000029;
        width:50%;
        position: relative;
        display:flex;
        justify-content:center;
        align-items:center;
        min-height:552px;
        height:90%;
        .ccwi_close{
            position:absolute;
            top:-20px;
            right:-20px;
            cursor: pointer;
        }
        .ccwi_content{
           width:100%;
            display:flex;
            justify-content:center;
            align-items:center;
            .ccwic_image{
                padding:0 1.5rem 0 1rem;
                text-align:center;
                flex:0.45;
                img{
                    width:100%;
                }
            }
            .ccwic_main{
                padding:10px 0;

                text-align:center;
                flex:1;
                ${breakpointsMedia.landscape}{
                padding:10px 15px;
                    
                }
                ${breakpointsMedia.largeDesktop}{
                    padding:50px 70px;
                }
                .ccwicm_header{
                    margin-bottom:10px;
                    .md-info{
                        &__item{
                            display:flex;
                            align-items:center;
                            justify-content:center;
                            p{
                                margin:0 2px !important;
                            }
                        }
                    }
                }
                .ccwicm_cont{
                    margin-bottom:3.375rem;
                    p{
                        margin:0;
                        color:#fff;
                        padding:0.25rem 0;
                        a{
                            color:#FFA143;
                            text-decoration:none;
                            margin: 0 3px;
                                padding:10px;
                            img{
                                width:4rem;
                                height:4rem;
                                border-radius:10px;
                            }
                        }
                    }
                }
                .ccwicm_footer{
                    button{
                        border-radius: 22px;
                        width: 70%;
                        border: none;
                        height: 43px;
                        background: transparent linear-gradient(270deg,#FF8467 0%,#FFAE5D 100%) 0% 0% no-repeat padding-box;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1600px) {
        display:flex;
        justify-content:center;
        align-items:center;
    }
    @media screen and (min-width: 1200px) and (max-width:1600px){
        display:flex;
        justify-content:center;
        align-items:center;
        .ccw_inner{
            .ccwi_content{
                .ccwic_image{
                    flex:0.45;
                    padding: 0 1rem;
                }
                .ccwic_main{
                    flex:0.52;
                    padding: 0 1rem;
                }
            }
        }
    }
    @media screen and (min-width:768px) and (max-width:1199px){
        overflow-y:auto;
        .ccw_inner{
            width:90%;
            min-height:610px;
            /* height:70%; */
            margin: 50px  auto 0;
            .ccwi_close{
                top:-10px;
                right:-10px;
            }
            .ccwi_content{
                /* width:75%;
                flex-direction:column; */
                .ccwic_main{
                    margin-top:3.375rem;
                    .ccwicm_header{
                        margin-bottom:10px;
                    }
                    .ccwicm_cont{
                        margin-bottom:2.275rem;
                        .ccwicmc_header{
                            padding:0 2.275rem;
                        }
                    }
                    .ccwicm_footer{
                        button{
                            height: 38px;  
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width:320px) and (max-width:767px){
        overflow-y:auto;
        .ccw_inner{
            width:100%;  
            height:100%;
            .ccwi_close{
                top:10px;
                right:10px;
                img{
                    width:35px;
                    height:35px;
                }
            }
            .ccwi_content{
                width:90%;
                flex-direction:column;
                .ccwic_main{
                    margin-top:3.5rem;
                    .ccwicm_header{
                        margin-bottom:10px;
                    }
                    .ccwicm_cont{
                        margin-bottom:2.275rem;
                        .ccwicmc_header{
                            padding:0;
                        }
                    }
                    .ccwicm_footer{
                        button{
                            width:100%;
                            height: 38px;  
                        }
                    }
                }
            }
        }
    }
`)