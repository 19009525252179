import configColor from '../assets/containts/configColor'
import i18next from 'i18next'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
    text: string,
    element?: 'p' | 'span' | 'raw',
    color?: 'white' | 'primary' | 'gray' | 'dark' | 'coin' | 'reduction' | 'increase',
    textTransform?: 'uppercase' | 'initial',
    size?: 'tiny' | 'small' | 'normal' | 'xNormal' | 'subHeading' | 'sale' | 'heading' | 'xHeading',
    fontWeight?: 'bold',
    // margin?: 0
}
const ColorText = {
    'white': configColor.colorWhite,
    'primary': configColor.colorPrimary,
    'gray': configColor.colorGray,
    'dark': configColor.colorDark,
    'coin': configColor.colorCoin,
    'reduction': configColor.colorReduction,
    'increase': configColor.colorIncrease
}
const SizeText = {
    'tiny': '12px',
    'small': '0.875rem',
    'normal': '1rem',
    'xNormal': '1.25rem',
    'subHeading': '1.875rem',
    'sale': '45px',
    'heading': '50px',
    'xHeading': '55px'
}
const SizeTextMobile = {
    'tiny': '12px',
    'small': '0.875rem',
    'normal': '14px',
    'xNormal': '1.25rem',
    'subHeading': '1.5rem',
    'sale': '30px',
    'heading': '45px',
    'xHeading': '50px',
}
const Text = ({ text, color = 'white', textTransform = 'initial', size = 'normal', fontWeight, ...props }: TextProps) => {
    const { t, i18n } = useTranslation();
    // let txt = i18n.exists(text)
    //     ? t(text)
    //     : text;

    const txtTranslate = text
    let lineHeight = '1.5'
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        const addWidth = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", addWidth)
        return () => {
            window.removeEventListener("resize", addWidth)
        }
    }, [])
    return (
        <p style={Object.assign({
            color: ColorText[color],
            textTransform: textTransform,
            fontSize: width > 1024 ? SizeText[size] : SizeTextMobile[size],
            lineHeight: lineHeight,
            fontWeight: fontWeight,
            // margin: margin
        })}
            {...props}
        >
            {txtTranslate}
        </p>
    )
}
export default memo(Text)