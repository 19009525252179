import { breakpointsMedia } from 'assets/containts/breakpoints'
import StakingLayout from 'components/StakingNew/StakingLayout'
import StakingRefCopy from 'components/StakingNew/stakingPackage/StakingRefCopy'
import ListLevelF1 from 'container/StakingNew/stakingJoin/ListLevelF1'
import StakingCounterTree from 'container/StakingNew/stakingJoin/StakingCounterTree'
import StakingInfo from 'container/StakingNew/stakingJoin/StakingInfo'
import StakingJoinTreeNode from 'container/StakingNew/stakingJoin/StakingJoinTreeNode'
import StakingNewPackage from 'container/StakingNew/StakingNewPackage'
import { TronContext } from 'contexts/tronWeb'
import Loading from 'core/Loading'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import StakingNewForm from './StakingNewForm'

const StakingNew = () => {
    //TODO check user join package
    const [loading, setLoading] = useState(true)
    const [joinPackage, setJoinPackage] = useState<undefined | boolean>(false)
    const { data, action } = useContext(TronContext)

    useEffect(() => {
        const getUser = async () => {
            const { packAmount } = await data.stake?.users(action.address).call() || ''
            if (packAmount !== '') {
                setLoading(false)
                if (Number(packAmount) !== 0) {
                    setJoinPackage(true)
                } else {
                    setJoinPackage(false)
                }
            } else {
                setJoinPackage(undefined)
            }

        }
        getUser()
    }, [action, data])
    if (loading) {
        return (
            <Wrap className="container">
                <Loading />
            </Wrap>
        )
    }
    return (
        <>
            {!joinPackage
                ?
                <Wrap className="container">
                    <div className="sn-left">
                        <div className="sn-info">
                            {/* <StakingLayout title="Staking Package Information">
                                <StakingNewPackage />
                            </StakingLayout> */}

                        </div>
                        <div className="sn-referral">
                            <StakingLayout title="Your Referral Link" >
                                <StakingRefCopy />
                            </StakingLayout>
                        </div>
                    </div>
                    <div className="sn-right">
                        <StakingNewForm />
                    </div>

                </Wrap>
                :
                <WrapLoged className="container">
                    <div className="lg-left">
                        <div className="mb-3">
                            <StakingInfo />
                        </div>
                        {/* <StakingLayout title="F1 Star Summary" >
                            <ListLevelF1 />
                        </StakingLayout> */}

                    </div>
                    <div className="lg-right">
                        <StakingLayout title="System Node Stats" >
                            <div className="bnt-total">
                                <StakingCounterTree />
                            </div>
                            <StakingJoinTreeNode />
                        </StakingLayout>
                        <div className="lg-referral">
                            <StakingLayout title="Your Referral Link" >
                                <StakingRefCopy />
                            </StakingLayout>
                        </div>
                    </div>
                </WrapLoged>
            }
        </>
    )
}

export default StakingNew

const Wrap = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:stretch;
    flex-direction:column;
    .sn-left{
        margin-right:0;
        flex: 1;
        .sn-info{
            margin-bottom:35px;
        }
        .sn-referral{
            margin-bottom:30px;
        }
    }   
    .sn-right{
        flex-basis:30%;
        background: #262E3F;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
    } 
    ${breakpointsMedia.landscape}{}
    ${breakpointsMedia.largeDesktop}{
        flex-direction:row;
        .sn-left{
            margin-right:30px;
            .sn-referral{
                margin-bottom:30px;
            }
        }
    }
`
const WrapLoged = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:stretch;
    flex-direction:column;
    .lg-left{
            margin-bottom:30px;
           
    }
    >div{
        flex-basis:50%;
        margin-right:0;
        :last-child{
            margin-right:0;
        }
        .bnt-total{
            margin-bottom:35px;
        }
        &.lg-right{
            /* background: #262E3F;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px; */
            .lg-referral{
                margin-top:30px;
            }
        }
    }
    ${breakpointsMedia.landscape}{}
    ${breakpointsMedia.largeDesktop}{
        flex-direction:row;
        .lg-left{
            margin-bottom:0;
        }
        >div{
            margin-right:30px;
        }
    }
`