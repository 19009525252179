import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js'
import { useHistory } from 'react-router-dom'
import Text from '../../../../core/Text'
import Image from '../../../../core/Image'
interface TableProps {

    id: number

    type: string
    address: string
    amount: number
    img: string
    status: string
}


const Table = ({ id, type, address, amount, img, status, }: TableProps) => {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const getWidth = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', getWidth)
        return () => window.removeEventListener('resize', getWidth)
    }, [])
    return (

        <div className="tr" >
            <div className="td-id">
                <Text text={`${id}`} color="coin" />

            </div>
            <div className="td-time">
                <Text text={type} color="coin" />
            </div>
            <div className="td">
                {
                    width > 1024 ? <Text text={`${address.slice(0, 10)}...${address.slice(-10)}`} color="coin" /> :
                        <Text text={`${address.slice(0, 5)}...${address.slice(-5)}`} color="coin" />
                }
            </div>
            <div className="td_amount">
                <Text text={`${amount}`} color="coin" />
                <Image src={img} />
            </div>
            <div className="td-rate ">
                {
                    status === 'Successful' ?
                        <div className='successful'>
                            <span>{status}</span>

                        </div>
                        :
                        status === 'Cancel' ?
                            <div className='cancel'>
                                <span>{status}</span>
                            </div>
                            :
                            <div className='watting'>
                                <span>{status}</span>
                            </div>
                }
            </div>

        </div>
    )
}

export default Table