/* eslint-disable import/no-anonymous-default-export */
import { keyframes } from "styled-components";

export default {
    toastifyFrom: keyframes`
    0%{
        top:-100%;
    }
    100%{
        top:0;
    }
`,
    toastifyTo: keyframes`
    0%{
        top:0;
    }
    100%{
        top:-100%;
    }
`,
    toastifyV2From: keyframes`
    0%{
        right:-100%;
    }
    100%{
        right:0;
    }
`,
    toastifyV2To: keyframes`
    0%{
        right:0;
    }
    100%{
        right:-100%;
        display:none;
    }
`,
    toastifyV2Devide: keyframes`
    0%{
        width:100%;
    }
    100%{
        width:0;
    }
`
    ,
    leftToRight: keyframes`
    0%{
        left:-100%;
    }
    100%{
        left:0;
    }
`,
    rightToLeft: keyframes`
    0%{
        right:-100%;
    }
    100%{
        right:0;
    }
`,
    topToBottom: keyframes`
    0%{
        top:-400px;
    }
    100%{
        top:0;
    }
`,
    bottomToTop: keyframes`
    0%{
        bottom:-100%;
    }
    100%{
        bottom:0;
    }
`,
    tabWidthScale: keyframes`
    0%{
        width:0;
    }
    100%{
        width:100%;
    }
`,
    countdown: keyframes`
    0% {
        transform: rotateX(0);
            
    }
    100% {
        transform: rotateX(-180deg);
    }
`,
    countdownSecondes: keyframes`
    0% {
        left:0;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left:100%;
    }
`,
    hoverButton: keyframes`
    0%{
        background: transparent linear-gradient(78deg, #FF8467 0%, #FFAE5D 100%) 0% 0% no-repeat padding-box;
    }
    100%{
        background: transparent linear-gradient(78deg, #FF7555 0%, #FFA143 100%) 0% 0% no-repeat padding-box;
    }
`,
    showText: keyframes`
        0%{
            transform:scaleY(0);
            height:0;
        }
        100%{
            transform:scaleY(1);
            height:100%;
        }
    `,
    hiddenText: keyframes`
        0%{
            transform:scaleY(1);
        }
        100%{
            transform:scaleY(0);
        }
    `,
    menuMobileAfter: keyframes`
        0%{
                bottom:-10px;
        }
        100%{
            bottom: 0;
            transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
            transform: rotate(-90deg);
        }
    `,
    menuMobileBefore: keyframes`
        0%{
            top:0;
        }
        100%{
            top: 0;
            transition: top 75ms ease,opacity 75ms ease .12s;
            opacity: 0;
        }
    `,
    menuMobileInner: keyframes`
        0%{
            top:0;
        }
        100%{
            transition-delay: .12s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
            transform: rotate(45deg);
        }
    `,
    loadingSuspene: keyframes`
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        
    `,
    notifyDice: keyframes`
        0%{
            transform:translate(-100px,-50%);
           
        }
        100%{
            transform:translate(0,-50%);

        }
    `,
    buttonRoll: keyframes`
        0%{
            transform:translateY(0);
        }
        50%{
            transform:translateY(-7px);
        }
        100%{
            transform:translateY(0);
        }
    `,
    rotateDice: keyframes`
        0%{
            transform:rotate(45deg);
        }
        15%{
            transform:rotate(120deg);
        }
        30%{
            transform:rotate(135deg);
        }
        45%{
            transform:rotate(280deg);
        }
        60%{
            transform:rotate(225deg);
        }
        75%{
            transform:rotate(160deg);
        }
        100%{
            transform:rotate(360deg);
        }
    `,
    copyRef: keyframes`
         0% {
            transform: scale(0);
            animation-timing-function: ease-in;
            opacity: 0;
        }
        38% {
            transform: scale(1);
            animation-timing-function: ease-out;
            opacity: 1;
        }
        55% {
            transform: scale(0.7);
            animation-timing-function: ease-in;
        }
        72% {
            transform: scale(1);
            animation-timing-function: ease-out;
        }
        81% {
            transform: scale(0.84);
            animation-timing-function: ease-in;
        }
        89% {
            transform: scale(1);
            animation-timing-function: ease-out;
        }
        95% {
            transform: scale(0.95);
            animation-timing-function: ease-in;
        }
        100% {
            transform: scale(1);
            animation-timing-function: ease-out;
        }
    `,
    slideTop: keyframes`
  
            0% {
                transform: translateY(-50px);
            }
            100% {
                transform: translateY(0);
            }
        
    `
}