import React, { memo, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { MdZoomOutMap } from 'react-icons/md'
import { IoIosNotificationsOutline } from 'react-icons/io'
import menu from '../../assets/images/menu.svg'
import Circle from '../../assets/images/circle-notifi.svg'
import { TronContext } from '../../contexts/tronWeb'
import Text from '../../core/Text'

const ActionUser = () => {
    const { action } = useContext(TronContext);
    return (
        <ActionUserWarp>
            {/* <div className="auw_zoom">
                <MdZoomOutMap />
            </div> */}
            {/* <div className="auw_notification">
                <IoIosNotificationsOutline />
                <img className="auwn_img" src={Circle} alt="" />
            </div> */}
            <div className="auw_profile">
                <div className="auwp_user">
                    <img src={menu} alt="" />
                </div>
                <div className="auwp_user_left">
                    {
                        action.address !== undefined && action.address !== 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb' ?
                            <Text text={`${action.address.slice(0, 5)}...${action.address.slice(-6)}`} /> :
                            <Text text="Guest" />
                    }
                </div>
            </div>
            {/* <div className="auw_menu">
                <img src={menu} alt="" />
            </div> */}
        </ActionUserWarp>
    )
}

export default ActionUser
const ActionUserWarp = memo(styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    flex:0.35;
    .auw_zoom{
        padding:10px;
        border-radius:3px;
        background-color:rgba(43, 55, 75,0.8);
        margin-right:10px;
        cursor: pointer;
        svg{
            fill:#fff;
            width:20px;
            height:20px;
            :hover{
                fill:#FFA143;

            }
        }
    }
    .auw_notification{
        padding:10px;
        border-radius:3px;
        background-color:rgba(43, 55, 75,0.8);
        margin-right:10px;
        position: relative;
        cursor: pointer;
        .auwn_img{
            position:absolute;
            width: 8px;
            height: 8px;
            right: 5px;
            top: 8px;
        }
        svg{
            fill:#fff;
            width:20px;
            height:20px;
            :hover{
                fill:#FFA143;

            }
        }
    }
    .auw_profile{
        padding:10px;
        border-radius:3px;
        margin-right:10px;
        display:flex;
        align-items:center;
        cursor: pointer;
        img{
            width:20px;
            height:20px;
            margin-right:10px;
        }
        .auwp_user_left{
            p{
                color:#fff;
            }
        }
    }
    .auw_menu{
        padding:10px;
        border-radius:3px;
        margin-right:10px;
        background-color:rgba(43, 55, 75,0.8);
        cursor: pointer;
        img{
            width:20px;
            height:20px;
        }
    }
    @media screen and (min-width:320px) and (max-width:767px){
        .auw_notification, .auw_zoom{
            display:none;
        }
        .auw_profile{
            .auwp_user_left{
                display:none;
            }
        }
    }
    
`)