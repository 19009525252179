import React, { useState, ReactNode } from 'react'

const MenuOpenContext = React.createContext({
    isOpenMenu: false,
    handleOpenMenu: (e: boolean) => { }
})
export default ({ children }: any) => {
    const [isOpenMenu, setisOpenMenu] = useState(false);
    const handleOpenMenu = () => {
        setisOpenMenu(!isOpenMenu)
    }
    return (
        <MenuOpenContext.Provider
            value={{
                isOpenMenu,
                handleOpenMenu
            }}
        >
            {children}
        </MenuOpenContext.Provider>
    );
}

export { MenuOpenContext };