import Button from 'core/Button'
import Text from 'core/Text'
import React from 'react'
import styled from 'styled-components'
interface StakingLayoutProps {
    title: string,
    children: any,
    buttonText?: string,
    onClick?: () => void
}
const StakingLayout = ({ title, children, buttonText, onClick }: StakingLayoutProps) => {
    return (
        <Wrap>
            <div className="stl-title">
                <Text text={title} size="xNormal" fontWeight='bold' />
                {
                    buttonText &&
                    <Button
                        onClick={onClick}
                        text={buttonText}
                        aria-activedescendant="approve"
                        styleBtn="btn-spin"
                    />

                }
            </div>
            <div className="stl-content">
                {children}
            </div>
        </Wrap>
    )
}

export default StakingLayout
const Wrap = styled.div`
    background: #262E3F;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:30px;
    .stl-title{
        margin-bottom:30px;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
`