import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
import I18n from 'i18n-js'
interface DeffectBoloHeaderProps {
    dataCoinBolo: {
        totalPersonal: number,
        dailyInterest: number,
        totalIncome: number,
        interestRate: number
    }
}
const DeffectBoloHeader = ({ dataCoinBolo }: DeffectBoloHeaderProps) => {
    const dataBoloHeader = [
        { id: 1, text: 'Total Income', coin: dataCoinBolo.totalIncome },
        { id: 2, text: 'Interest Rate', coin: dataCoinBolo.interestRate }
    ]
    return (
        <DeffectBoloHeaderWrap>
            <div className="dbhw_header">
                <div className="dbhwh_top">
                    <Text text='Total Personal Deposit' />
                </div>
                <div className="dbhwh_cent">
                    <Text text={`$ ${I18n.toNumber(dataCoinBolo.totalPersonal)}`} fontWeight="bold" color="gray" size="subHeading" />
                </div>
                <div>
                    <Text text={`Daily Interest: $ ${I18n.toNumber(dataCoinBolo.dailyInterest)}`} size="small" color="dark" />
                </div>
            </div>
            <div className="dbhw_cont">
                {
                    dataBoloHeader.map((t, index) => {
                        return <div className="dbhwc_item" key={index}>
                            <Text text={t.text} size="small" />
                            <Text text={`$ ${I18n.toNumber(t.coin)}`} size="xNormal" fontWeight="bold" />
                        </div>
                    }
                    )}
            </div>
        </DeffectBoloHeaderWrap>
    )
}

export default DeffectBoloHeader
const DeffectBoloHeaderWrap = memo(styled.div`
    background: #262E3F 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:2rem;
    width:40%;
    .dbhw_header{
        text-align:center;
        margin-bottom:2rem;
        .dbhwh_cent{
            margin-top:0.5rem;
        }
    }
    .dbhw_cont{
        display:flex;
        justify-content:space-between;
        .dbhwc_item{
            flex:1;
            border-right:1px solid #3A4E6E;
            text-align:center;
        }
        >div:last-child{
            border-right:0;
        }
    }
    @media screen and (max-width:1279px){
        width:80%;
        .dbhw_header{
            margin-bottom:1.5rem;
        }
    }
    @media screen and (max-width:1024px){
     
        width:100%; 
        border-radius: 5px;
        
    }
    @media screen and (max-width:767px){
        margin: 2rem 1rem 0;
       
        
    }
`)