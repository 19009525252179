import React, { memo, useState } from "react";
import styled from "styled-components";
import logintronimg from "../../assets/images/logintron.svg";
import close from "../../assets/images/close.svg";
import troncont from "../../assets/images/troncont.png";
import tronlink from "../../assets/images/tronlink.png"
import tronscan from "../../assets/images/tronscan.png";

import Text from "../../core/Text";
interface LoginTronProps {
    setopenModal: any;
}
const LoginTron = ({ setopenModal }: LoginTronProps) => {
    const [operatingSystem] = useState(() => {
        let userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    });
    console.log(operatingSystem, "operatingSystem")
    return (
        <LoginTronWrap>
            <div className="ltw_inner">
                <div className="ltwi_close" onClick={() => setopenModal(false)}>
                    <img src={close} alt="" />
                </div>
                <div className="ltwi_content">
                    <div className="ltwic_image">
                        <img src={troncont} alt="" />
                    </div>
                    <div className="ltwic_main">
                        <div className="ltwicm_header">
                            <Text text="Oh, no!" size="subHeading" fontWeight="bold" />
                        </div>
                        <div className="ltwicm_cont">
                            <div className="ltwicmc_header">
                                <Text text="It looks like you haven’t logged in or installed Tronlink." />
                            </div>
                            {operatingSystem === "iOS" ? (
                                <p>
                                    <a
                                        href="https://apps.apple.com/us/app/tronlink-trx-btt-wallet/id1453530188"
                                        target="_blank"
                                        rel="nofollow noreferrer noopener"
                                    >
                                        <img src={tronlink} alt="" />
                                    </a>
                                    <a
                                        href="https://apps.apple.com/us/app/tronwallet-blockchain-wallet/id1412536016"
                                        target="_blank"
                                        rel="nofollow noreferrer noopener"
                                    >
                                        <img src={tronscan} alt="" />
                                    </a>
                                </p>
                            ) : operatingSystem === "Android" ? (
                                <p>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=trx.org.freewallet.app&hl=en_US&gl=US"
                                        target="_blank"
                                        rel="nofollow noreferrer noopener"
                                    >
                                        <img src={tronlink} alt="" />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.tronlinkpro.wallet&hl=en_US&gl=US"
                                        target="_blank"
                                        rel="nofollow noreferrer noopener"
                                    >
                                        <img src={tronscan} alt="" />
                                    </a>
                                </p>
                            ) : (
                                        <p>
                                            Please visit their
                                            <a
                                                href="https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec?hl=vi"
                                                target="_blank"
                                                rel="nofollow noreferrer noopener"
                                            >
                                                TronLink
                  </a>
                  website and install it..
                                        </p>
                                    )}
                        </div>
                        <div className="ltwicm_footer">
                            <button onClick={() => setopenModal(false)}>
                                <Text text="Close" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </LoginTronWrap>
    );
};

export default LoginTron;
const LoginTronWrap = memo(styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  .ltw_inner {
    background-color: #262e3f;
    border: 2px solid #f7af2d;
    box-shadow: 0px 3px 6px #00000029;
    width: 50%;
    border-top: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 552px;
    height: 60%;
    ::before {
      content: "";
      background-image: url(${logintronimg});
      background-repeat: no-repeat;
      height: 13px;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      background-size: cover;
    }
    .ltwi_close {
      position: absolute;
      top: -20px;
      right: -20px;
      cursor: pointer;
    }
    .ltwi_content {
      width: 75%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ltwic_image {
        text-align: center;
        img {
          width: 70%;
        }
      }
      .ltwic_main {
        text-align: center;
        .ltwicm_header {
          margin-bottom: 2.875rem;
        }
        .ltwicm_cont {
          margin-bottom: 3.375rem;
          p {
            margin: 0;
            color: #fff;
            padding: 0.5rem 0;
            a {
              color: #ffa143;
              text-decoration: none;
              margin: 0 3px;
              padding: 10px;

              img {
                width: 4rem;
                height: 4rem;
                border-radius: 10px;
              }
            }
          }
        }
        .ltwicm_footer {
          button {
            border-radius: 22px;
            width: 70%;
            border: none;
            height: 43px;
            background: transparent
              linear-gradient(270deg, #ff8467 0%, #ffae5d 100%) 0% 0% no-repeat
              padding-box;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1600px) {
  }
  @media screen and (min-width: 992px) and (max-width: 1024px) {
    overflow-y:auto;
    .ltw_inner {
      width: 65%;
      min-height: 610px;
      height: 70%;
      .ltwi_close {
        top: -10px;
        right: -10px;
      }
      .ltwi_content {
        width: 75%;
        flex-direction: column;
        .ltwic_main {
          margin-top: 3.375rem;
          .ltwicm_header {
            margin-bottom: 1.875rem;
          }
          .ltwicm_cont {
            margin-bottom: 2.275rem;
            .ltwicmc_header {
              padding: 0 2.275rem;
            }
          }
          .ltwicm_footer {
            button {
              height: 38px;
            }
          }
        }
      }
    }
  }


  @media screen and (min-width:768px )and (max-width:991px){
      overflow-y:auto;
    .ltw_inner {
      width: 60%;
      transform: translate(0,20%);
        max-width: 500px;
        margin: 1.75rem auto;

      height: 100%;
      .ltwi_close {
        top: 25px;
        right: 10px;
        img {
          width: 35px;
          height: 35px;
        }
      }
      .ltwi_content {
        width: 90%;
        flex-direction: column;
        .ltwic_main {
          margin-top: 3.5rem;
          .ltwicm_header {
            margin-bottom: 1.5rem;
          }
          .ltwicm_cont {
            margin-bottom: 2.275rem;
            .ltwicmc_header {
              padding: 0 2.275rem;
            }
          }
          .ltwicm_footer {
            button {
              width: 100%;
              height: 38px;
            }
          }
        }
      }
    }
  } 
  @media screen and (min-width:601px )and (max-width:767px){
      overflow-y:auto;
    .ltw_inner {
        width: 60%;
      transform: translate(0,20%);
        max-width: 500px;
        margin: 1.75rem auto;
      height: 100%;

      .ltwi_close {
        top: 25px;
        right: 10px;
        img {
          width: 35px;
          height: 35px;
        }
      }
      .ltwi_content {
        width: 90%;
        flex-direction: column;
        .ltwic_main {
          margin-top: 3.5rem;
          .ltwicm_header {
            margin-bottom: 1.5rem;
          }
          .ltwicm_cont {
            margin-bottom: 2.275rem;
            .ltwicmc_header {
              padding: 0 2.275rem;
            }
          }
          .ltwicm_footer {
            button {
              width: 100%;
              height: 38px;
            }
          }
        }
      }
    }
  } 
  @media screen and (min-width:500px )and (max-width:600px){
      overflow-y:auto;
    .ltw_inner {
        width: 60%;
      transform: translate(0,25%);
        max-width: 500px;
        margin: 1.75rem auto;
      height: 100%;

      .ltwi_close {
        top: 25px;
        right: 10px;
        img {
          width: 35px;
          height: 35px;
        }
      }
      .ltwi_content {
        width: 90%;
        flex-direction: column;
        .ltwic_main {
          margin-top: 3.5rem;
          .ltwicm_header {
            margin-bottom: 1.5rem;
          }
          .ltwicm_cont {
            margin-bottom: 2.275rem;
            .ltwicmc_header {
              padding: 0 2.275rem;
            }
          }
          .ltwicm_footer {
            button {
              width: 100%;
              height: 38px;
            }
          }
        }
      }
    }
  } 
  @media screen and (min-width: 320px) and (max-width: 490px) {
    .ltw_inner {
      width: 100%;
      /* transform: translate(0,20%); */
        /* max-width: 500px; */
        /* margin: 1.75rem auto; */
      height: 100%;

      .ltwi_close {
        top: 25px;
        right: 10px;
        img {
          width: 35px;
          height: 35px;
        }
      }
      .ltwi_content {
        width: 90%;
        flex-direction: column;
        .ltwic_main {
          margin-top: 3.5rem;
          .ltwicm_header {
            margin-bottom: 1.5rem;
          }
          .ltwicm_cont {
            margin-bottom: 2.275rem;
            .ltwicmc_header {
              padding: 0 2.275rem;
            }
          }
          .ltwicm_footer {
            button {
              width: 100%;
              height: 38px;
            }
          }
        }
      }
    }
  }
`);
