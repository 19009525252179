import StakingInfoItem from "components/StakingNew/stakingJoin/StakingInfoItem";
import StakingLayout from "components/StakingNew/StakingLayout";
import tronWeb, { TronContext } from "contexts/tronWeb";
import Button from "core/Button";
import dayjs from "dayjs";
import { formatCoin } from "helps/formatCoin";
import React, { useContext, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";

interface StakingInfoItemProps {
  title: string;
  value?: number;
  type?: "string" | "coin" | "address" | "button" | any;
  text?: string;
  btnEvent?: () => void;
  textBtn?: string;
}
const StakingInfo = () => {
  //TODO get api info join
  const { data, action, tronWeb } = useContext(TronContext);
  const [info, setInfo] = useState({
    packageAmount: 0,
    dailyInterest: 0,
    directRef: 0,
    matchingBonus: 0,
    cycleBonus: 0,
    claimed: 0,
    lastUpdated: 0,
    totalF1: 0,
    claimable: 0,
    star: 0,
    sponsor: "",
  });
  const [upStar, setUpStar] = useState(false);
  useEffect(() => {
    const getUserInfo = async () => {
      const [
        users,
        dailyInterest,
        matchingBonus,
        cycleBonus,
      ] = await Promise.all([
        data.stake?.users(action.address).call() || "",
        data.stake?.getDailyInterest(action.address).call() || 0,
        data.stake?.getMatching(action.address).call() || 0,
        data.stake?.getCycle(action.address).call() || 0,
      ]);
      const [
        totalF1,
        claimable,
        sponsorParent,
        infoAddressStar,
      ] = await Promise.all([
        data.PrivateMember?.getRefLength(action.address).call() || "",
        data.stake?.getPayout(action.address).call() || 0,
        data.PrivateMember?.getParent(action.address).call() || "",
        data.stake?.canUpStar(action.address).call() || "",
      ]);
      if (infoAddressStar !== "") {
        setUpStar(infoAddressStar);
      }
      let packageAmount = 0;
      let directRef = 0;
      let claimed = 0;
      let lastUpdated = 0;
      let star = 0;

      if (users !== "") {
        packageAmount = formatCoin(+users.packAmount);
        directRef = formatCoin(+users.directRef);
        claimed = formatCoin(+users.claimed);
        lastUpdated = +users.lastUpdate;
        star = +users.star;
      }
      let lengthTotal = 0;
      if (totalF1 !== "") {
        lengthTotal = +totalF1._length;
      }
      let sponsor = "";
      if (sponsorParent !== "") {
        sponsor = tronWeb.address.fromHex(sponsorParent._parent);
      }
      setInfo({
        packageAmount,
        dailyInterest: formatCoin(+dailyInterest),
        directRef,
        matchingBonus: formatCoin(+matchingBonus),
        cycleBonus: formatCoin(+cycleBonus),
        claimed,
        lastUpdated,
        totalF1: lengthTotal,
        claimable: formatCoin(+claimable),
        star,
        sponsor,
      });
    };
    getUserInfo();
  }, [data, action]);
  const onUpStar = async () => {
    console.log("handle Up Star");
    try {
      await action.stake?.upgradeStar().send();
      toast.success("Up Star Success!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log("error upgrade start", error);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const listInfo = [
    {
      title: "Package Amount",
      value: info.packageAmount,
      type: "coin",
    },
    {
      title: "Daily Performance",
      value: info.dailyInterest,
      type: "coin",
    },
    {
      title: "Direct Reference",
      value: info.directRef,
      type: "coin",
    },
    {
      title: "Matching",
      value: info.matchingBonus,
      type: "coin",
    },
    {
      title: "Cycle ",
      value: info.cycleBonus,
      type: "coin",
    },
    {
      title: "Claimed",
      value: info.claimed,
      type: "coin",
    },
    {
      title: "Claimable",
      value: info.claimable,
      type: "coin",
    },
    {
      title: "Last update",
      type: "string",
      text: dayjs(info.lastUpdated * 1000).format("MM/DD/YYYY h:mm A"),
    },
    {
      title: "Star",
      type: upStar ? "button" : "string",
      text: `${info.star}`,
      textBtn: "Up star",
      btnEvent: onUpStar,
    },
    {
      title: "Total F1",
      type: "string",
      text: `${info.totalF1}`,
    },
    {
      title: "Sponsor",
      type: "address",
      text: `${info.sponsor}`,
    },
  ];
  const onClaimAll = () => {
    const claimAll = async () => {
      await action.stake?.withdraw().send({
        callValue: 0,
        feeLimit: 2e8,
      });
      toast.success("Claimed success!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    claimAll().then(
      () => { },
      (error) => {
        console.log("error claim all", error);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  };
  return (
    <StakingLayout
      title="Infomation"
      buttonText="Claim all"
      onClick={onClaimAll}
    >
      <ToastContainer />

      <Wrap>
        {listInfo.map(
          (
            {
              title,
              value,
              text,
              type,
              textBtn,
              btnEvent,
            }: StakingInfoItemProps,
            index: number
          ) => (
            <div key={index} className="si-item">
              <StakingInfoItem
                title={title}
                value={value}
                text={text}
                type={type}
                textBtn={textBtn}
                btnEvent={btnEvent}
              />
            </div>
          )
        )}
      </Wrap>
    </StakingLayout>
  );
};

export default StakingInfo;
const Wrap = styled.div`
  .si-item {
    padding: 8px 0;
    border-bottom: 1px solid #3a4e6e;
    :last-child {
      border-bottom: 0;
    }
  }
`;
