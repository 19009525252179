import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home/Home";
import ConnectErrorInternet from "../pages/popup/ConnectErrorInternet";
import SeedRound from "../pages/SeedRound/SeedRound";
import StakingPool from "../pages/Staking/StakingPool";
import Swap from "../pages/swap/Swap";
import DeffectBolo from "../pages/DeffectBolo/DeffectBolo";
import DeffectGame from "../pages/DeffectGame/DeffectGame";
import Humanilies from "../pages/Humanilies/Humanilies";
import HumaniliesInformation from "../pages/Humanilies/HumaniliesInfomation/HumaniliesInformation";
import Presale from "../pages/Presale/Presale";
import DeffectBoloDetails from "../pages/DeffectBolo/DeffectBoloDetails/DeffectBoloDetails";
import StakingNewConnectNode from "pages/StakingNew/StakingNewConnectNode";
import UsersContextWrap from "contexts/UsersContext";
import DirectRef from "pages/DirectRef/DirectRef";
import BinaryTree from "pages/BinaryTree/BinaryTree";
import Contact from "pages/Contact/Contact";
const Router = () => {
  return (
    <UsersContextWrap>
      <Switch>
        {/* <Route exact path='/' component={Presale} /> */}
        {/* <Route exact path='/seed' component={SeedRound} /> */}
        <Route exact path="/" component={Home} />
        {/* <Route exact path='/swap' component={Swap} /> */}
        <Route exact path="/staking">
          <StakingNewConnectNode />
        </Route>
        {/* <Route exact path="/binary-tree" component={BinaryTree} /> */}

        <Route exact path="/deffectbolo" component={DeffectBolo} />
        {/* <Route exact path="/direct-ref" component={DirectRef} /> */}
        {/* <Route exact path="/deffectgame" component={DeffectGame} /> */}
        <Route exact path="/humanilies" component={Humanilies} />
        <Route
          exact
          path="/humanilies/id=:id"
          component={HumaniliesInformation}
        />
        <Route
          exact
          path="/deffectbolo/id=:id"
          component={DeffectBoloDetails}
        />
        <Route exact path="/contact" component={Contact} />

        <Route component={ConnectErrorInternet} />
      </Switch>
    </UsersContextWrap>
  );
};

export default Router;
