const node = "https://api.tronstack.io";
// const node = "https://api.shasta.trongrid.io";
// const node = "https://testhttpapi.tronex.io"
// const node = "https://api.trongrid.io";
const contract = {
  token: "TY2uWJK5kprU6d5R7YXxWcXQVJtyYdMEC1",
  member: "TNxXf8pgs97rRYAHjUT86dMWpkEqW38Zkz",
  seedSale: "TG4s3cvb3LLqxRGxoctePocKPVrBH9nvwK",
  usdt: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  swap: "TG4s3cvb3LLqxRGxoctePocKPVrBH9nvwK",
  PrivateSale: "TCvTrsk1ZgesuQRiFjUtfGjkJKmSd4r4nj",
  PrivateMember: "TPFwyFAvkBnHmNHzeaQZ1gWWHYtsV1dTAy",
  price: "TUXP6yfa7LYYZAr3cig4413UDBZ2uWBBeK",
  stake: "TKDBaUPXv1n3E3hR4S8TRz2F56REHqaZ9X",
  fixClaim: "TWWaL5gcWuTn5h9cC7yE7M6jbe3vhfSqux",
  addressCreate: "TS3tYvXEUoeQ5zwTLQSq1gD9cSsiaY2Fws"
  //4-1
  // token: "TY2uWJK5kprU6d5R7YXxWcXQVJtyYdMEC1",
  // member: "TNxXf8pgs97rRYAHjUT86dMWpkEqW38Zkz",
  // seedSale: "TG4s3cvb3LLqxRGxoctePocKPVrBH9nvwK",
  // usdt: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  // swap: "TG4s3cvb3LLqxRGxoctePocKPVrBH9nvwK",
  // PrivateSale: "TCvTrsk1ZgesuQRiFjUtfGjkJKmSd4r4nj",
  // PrivateMember: "TPFwyFAvkBnHmNHzeaQZ1gWWHYtsV1dTAy",

  //Shasta
  // token: "TPXeb44915aHXs8B7cdw29RgkLS9srd9XB",
  // member: "TQMSZDAXYQxksDoCRUPBvdHyuWdCLYMmWV",
  // seedSale: "TQMSZDAXYQxksDoCRUPBvdHyuWdCLYMmWV",
  // usdt: "TPXeb44915aHXs8B7cdw29RgkLS9srd9XB",
  // swap: "TQMSZDAXYQxksDoCRUPBvdHyuWdCLYMmWV",
  // PrivateSale: "TFZPF9fM2huD4XzcmEW3aF786B5ZqMDqvR",
  // PrivateMember: "TQMSZDAXYQxksDoCRUPBvdHyuWdCLYMmWV",
};
export { node, contract };
