import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import './assets/Globalstyled.scss'
import TronContext from './contexts/tronWeb'
import LinkAction from './components/ContentComponent/LinkAction';
import Router from './routes/Router';
import MenuOpenContext from './contexts/MenuOpenContext';
import CoinContextWrap from './contexts/CoinContext';
import 'react-toastify/dist/ReactToastify.css';
import ApolloContext from 'contexts/ApolloContext';
const App = () => {
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const addHeight = () => {
            setHeight(window.innerHeight)
        }
        window.addEventListener("resize", addHeight)
        return () => {
            window.removeEventListener("resize", addHeight)
        }
    }, [])

    return (
        <ApolloContext>
            <MenuOpenContext >
                <TronContext>
                    <CoinContextWrap>
                        <Warp >
                            <div>
                                <HeaderComponent />
                                <div className="s">
                                    <LinkAction />
                                    <Router />
                                </div>
                            </div>
                        </Warp>
                    </CoinContextWrap>
                </TronContext>
            </MenuOpenContext>
        </ApolloContext>
    );
}

export default App;
const Warp = styled.div`
    background: #242938;
    .s{
        min-height:100vh;
        display:flex;
        background-color:#242938;
        padding-top:80px;
    }
    @media screen and (max-width: 767px){
        .s{
        padding-top:54px;
        }
    }
`
