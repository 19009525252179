import React from 'react'
import Text from '../../../../core/Text'
interface AssetInfoItemProps {
    text: string;
    amount: string
}
const AssetInfoItem = ({ text, amount }: AssetInfoItemProps) => {
    return (
        <div className="aiwc_item" >
            <Text text={text} />
            <Text text={amount} fontWeight="bold" size="xNormal" color='gray' />
        </div>
    )
}

export default AssetInfoItem
