import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
import { TronContext } from '../../../contexts/tronWeb'
import VoteInputItem from './VoteInputItem'
import I18n from 'i18n-js'
interface VoteInputProps {
    banlanceCoin: {
        coinTRX: number
        coinUSDT: number
    }
    usdtAmount: string
    setUsdtAmount: any
    errorInput: string
    setErrorInput: any
    BuyConfirm: (usdtAmount: number) => void
    setopenModal: (e: boolean) => void
}

const VoteInput = ({ banlanceCoin, usdtAmount, setUsdtAmount, errorInput, setErrorInput, BuyConfirm, setopenModal }: VoteInputProps) => {
    const { action } = useContext(TronContext);
    const handelClickBuy = (usdtAmount: number) => {
        if (action.address !== undefined && action.address !== 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
            BuyConfirm(usdtAmount)

        } else {
            setopenModal(true)
            //TODO ConfirmStaking
        }

    }
    return (
        <VoteInputWrap>
            <div className="sfiw_header">
                <Text text="Power vote" size="small" />
            </div>
            <VoteInputItem
                banlanceCoin={banlanceCoin}
                usdtAmount={usdtAmount}
                setUsdtAmount={setUsdtAmount}
                errorInput={errorInput}
                setErrorInput={setErrorInput}
            />
            {
                errorInput === "inputNotRight" ?
                    <div className="balance_error">
                        <Text text={`Min:100, Max:10,000`} size="small" color="reduction" />

                        <Text text={`Balance: ${I18n.toNumber(banlanceCoin.coinTRX, { precision: 4 })} TRX`} />
                    </div> :
                    errorInput === "Insufficientbalance" ?
                        <div className="balance_error">
                            <Text text={`Amount > Balance`} size="small" color="reduction" />

                            <Text text={`Balance: ${I18n.toNumber(banlanceCoin.coinTRX, { precision: 4 })} TRX`} />
                        </div> :

                        <div className="balance">
                            <Text text={`Balance: ${I18n.toNumber(banlanceCoin.coinTRX, { precision: 4 })} TRX`} />
                        </div>
            }

            <div className="sfiw_button">
                <button
                    onClick={() => handelClickBuy(Number(usdtAmount))}
                    disabled={
                        (action.address === undefined &&
                            action.address === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" &&
                            errorInput !== "") ||
                        Number(usdtAmount) < 100
                    }
                ><Text text="Deposit" /> </button>
            </div>
        </VoteInputWrap>
    )
}

export default VoteInput
const VoteInputWrap = memo(styled.div`
    .sfiw_header{
        margin-bottom:1rem;
    }
  
    .balance_error{
        display:flex;
        padding-top: 1.25rem;
        justify-content:space-between;
        margin-bottom: 5rem;
        align-items:center;
        font-style: italic;
    }
    .balance {
        padding-top: 1.25rem;
        text-align: end;
        margin-bottom: 5rem;
        font-style: italic;
    }
    .sfiw_button{
        text-align:center;
        button{
            width:70%;
            background: transparent linear-gradient(270deg, #FFA143 0%, #FF7555 100%) 0% 0% no-repeat padding-box;
            border-radius: 32px;
            padding:0.75rem 2.875rem;
            border:none;
            outline:none;
            cursor: pointer;
            :hover {
                background: transparent
                    linear-gradient(270deg, #ffa143 0%, #ff7555 100%) 0% 0% no-repeat
                    padding-box;
                }
            :disabled {
                background: transparent
                    linear-gradient(270deg, #ab733a 0%, #ab5a45 100%) 0% 0% no-repeat
                    padding-box;
                cursor: not-allowed;
                p{
                    color: #ababab !important;
                }
                :hover {
                    background: transparent
                    linear-gradient(270deg, #ab733a 0%, #ab5a45 100%) 0% 0% no-repeat
                    padding-box;
                }
            }
        
        }
    }
   
    @media screen and (max-width:1600px){
        .sfiw_header{
            margin-bottom:1rem;
        }
   
        /* .sfiw_balance{
            margin-bottom:3.75rem;
        } */
        .balance {
         margin-bottom:3.75rem;
            }
            .balance_error {
                margin-bottom:3.75rem;
            }
    }
    @media screen and (max-width:1200px){
        .sfiw_header{
            margin-bottom:0.5rem;
        }
        .balance {
            margin-bottom:2.75rem;
            }
            .balance_error {
            margin-bottom:2.75rem;

            }
        .sfiw_balance{
            margin-bottom:2.75rem;
        }
    }
    @media screen and (max-width:1024px){
        .sfiw_header{
            margin-bottom:1rem;
        }
   
        .sfiw_balance{
            margin-bottom:2.75rem;
        }
    }
    @media screen and ( max-width:992px ){
        .sfiw_header{
            margin-bottom:1rem;
        }
   
        .sfiw_balance{
            margin-bottom:2.75rem;
        }
    }
    @media screen and (max-width:767px){
        .sfiw_button{
            button{
                width:100%;      
            }
        }
    }
   
`)