import React, { memo } from 'react'
import styled from 'styled-components'
import Image from '../../../core/Image'
import Text from '../../../core/Text'

interface HomeBalanceTopItemProps {
    id: number
    num: number
    img: string
}
const HomeBalanceTopItem = ({ id, num, img }: HomeBalanceTopItemProps) => {
    return (
        <HomeBalanceTopItemWrap id={id}>
            <Text text={Intl.NumberFormat('en-US').format(num)} />
            <Image src={img} />
        </HomeBalanceTopItemWrap >
    )
}

export default memo(HomeBalanceTopItem)
const HomeBalanceTopItemWrap = memo(styled.div`
    display:flex;
    align-items:center;
    margin-bottom:1rem;
    p{
        position:relative;
        min-width:4rem;
        ::before{
            content:'';
            width:0.5rem;
            height:0.5rem;
            position:absolute;
            top:50%;
            left:-1rem;
            transform: translate(0,-50%);     
            background-color: ${(props: any) => props.id === 1 ? '#368FEA' : props.id === 2 ? '#FF7555' : '#FFA143'};           
        }
    }
    img{
        margin-left:1rem;
        width:1rem;
        height:1rem;
    }
         

`)