import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
import CoinDEFImg from '../../../assets/images/coin.svg'
import CoinTRXImg from '../../../assets/images/tron-coin-icon.svg'
import CoinUSDTImg from '../../../assets/images/usdt-coin-icon.svg'
import HomeBalanceTopItem from './HomeBalanceTopItem'
import { Doughnut } from 'react-chartjs-2'
import BigNumber from 'bignumber.js'
import I18n from 'i18n-js'
import { useTotalTransfer } from 'helps/useTotalTransfer'
import { useTotalWallet } from 'helps/useTotalWallet'
interface HomeBalanceTopProps {
    dataCoinUSDT: {
        usdtBalanceUSDT: number
        trxBalanceUSDT: number
        defBalanceUSDT: number
    }
    dataCoin: {
        usdtBalance: number
        trxBalance: number
        defBalance: number
    }
}
const HomeBalanceTop = ({ dataCoinUSDT, dataCoin }: HomeBalanceTopProps) => {
    const data = [
        { id: 1, num: dataCoin.defBalance, img: CoinDEFImg },
        { id: 2, num: dataCoin.trxBalance, img: CoinTRXImg },
        { id: 3, num: dataCoin.usdtBalance, img: CoinUSDTImg },
    ]
    const dataDoughnut = {
        labels: [
            'Coin DEF',
            'Coin TRX',
            'Coin USDT',
        ],
        datasets: [{
            data: [dataCoinUSDT.defBalanceUSDT, dataCoinUSDT.trxBalanceUSDT, dataCoinUSDT.usdtBalanceUSDT],
            backgroundColor: [
                '#368FEA',
                '#FF7555',
                '#FFA143'
            ],
            hoverBackgroundColor: [
                '#368FEA',
                '#FF7555',
                '#FFA143'
            ],
        }],
    }
    const dataEmpty = {
        datasets: [{
            data: [1],
            backgroundColor: [
                '#2B374B',
            ],
            hoverBackgroundColor: [
                '#2B374B',
            ],
        }],
    }
    const OptionData = {

        maintainAspectRatio: false,
        responsive: true,

        legend: {
            display: false,

        }
    }
    const OptionNoData = {

        maintainAspectRatio: false,
        responsive: true,

        legend: {
            display: false,

        },
        tooltips: {
            enabled: false,
        },

    }
    const total = Number(new BigNumber(dataCoinUSDT.usdtBalanceUSDT).plus(dataCoinUSDT.trxBalanceUSDT).plus(dataCoinUSDT.defBalanceUSDT))
    const totalTransfer = useTotalTransfer()
    const totalWallet = useTotalWallet()
    return (
        <HomeBalanceTopWrap data={data}>
            <div className="hbtw_header">
                <Text text='Statistics' fontWeight="bold" size="xNormal" />
            </div>
            <div className="balance">
                <div className="hbtw_balance">
                    <div className="hbtwb_header">
                        <Text text="Total transaction" />
                    </div>
                    <div className="hbtw_cont">
                        <Text text={`${I18n.toNumber(totalTransfer, { precision: 0 })}`} fontWeight="bold" color="gray" />
                    </div>
                    <div className="hbtwb_header">
                        <Text text="Total Wallet" />
                    </div>
                    <div className="hbtw_cont">
                        <Text text={`${I18n.toNumber(totalWallet, { precision: 0 })}`} fontWeight="bold" color="gray" />
                    </div>
                    <div className="hbtwb_header">
                        <Text text="Balance" />
                    </div>
                    <div className="hbtw_cont">
                        <Text text={`${I18n.toNumber(total, { precision: 2 })} $`} fontWeight="bold" size="subHeading" color="gray" />
                    </div>
                    <div className="hbtw_foot">
                        {
                            data.map((item, index) => {
                                return <HomeBalanceTopItem key={index} id={item.id} num={item.num} img={item.img} />
                            })
                        }
                    </div>
                </div>
                <div className="hbtw_chartjs">
                    <div className="hbtw_chartjs_container">
                        {
                            dataCoinUSDT.defBalanceUSDT === 0 && dataCoinUSDT.trxBalanceUSDT === 0 && dataCoinUSDT.usdtBalanceUSDT === 0 ?

                                <Doughnut data={dataEmpty} options={OptionNoData} /> :
                                <Doughnut data={dataDoughnut} options={OptionData} />



                        }

                    </div>
                </div>
            </div>

        </HomeBalanceTopWrap>
    )
}

export default HomeBalanceTop
const HomeBalanceTopWrap = memo(styled.div`
    .hbtw_header{
        margin-bottom:1.875rem;
    }
    .balance{
        display:flex;
        justify-content:space-between;
        .hbtw_balance{
            flex:0.6;
            .hbtw_cont{
                margin-bottom:1rem;
            }
            .hbtw_foot{
                padding-left:1rem;
            }
        }
        .hbtw_chartjs{
            flex:0.4;
            display:flex;
            justify-content:flex-end;
            .hbtw_chartjs_container{
                position: relative;
                width:12.5rem;
                height:12.5rem;
            }
        
        }
    }
   
    @media screen and (max-width:1600px){
        .balance{
            .hbtw_chartjs{
                .hbtw_chartjs_container{
                    width:10rem;
                    height:10rem;
                }
            
            }
        }
    }
    @media screen and (max-width:1279px){
        .hbtw_header{
            margin-bottom:1.5rem;
        }
        .balance{
            .hbtw_chartjs{
                flex:0.4;
                .hbtw_chartjs_container{
                    width:9rem;
                    height:9rem;
                }
            
            }
        }
    }
    @media screen and (max-width:1024px){
        .balance{
            .hbtw_chartjs{
                .hbtw_chartjs_container{
                    width:12.5rem;
                    height:12.5rem;
                    
                }
            }
        }

    }
    @media screen and (max-width:767px){
        
        .balance{
            .hbtw_balance{
                flex:0.6;
                .hbtw_cont{
                    margin-bottom:1rem;
                }
                .hbtw_foot{
                    padding-left:1rem;
                }
            }
            .hbtw_chartjs{
                flex:0.4;
                .hbtw_chartjs_container{
                    width:12.5rem;
                    height:12.5rem;
                }
            
            }
        }
    }
    @media screen and (max-width:575px){
        .balance{
            .hbtw_balance{
                .hbtw_cont{
                    margin-bottom:1rem;
                }
                .hbtw_foot{
                    padding-left:1rem;
                }
            }
            .hbtw_chartjs{
                flex:0.4;
                .hbtw_chartjs_container{
                    width:9rem;
                    height:9rem;
                }
            
            }
        }
    }
`)