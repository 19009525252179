import { TronContext } from "contexts/tronWeb";
import Button from "core/Button";
import Coin from "core/Coin";
import Text from "core/Text";
import { formatCoin, multiCoin } from "helps/formatCoin";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FormValue from "./FormValue";
import { contract } from "contexts/tronConfig";
import { toast, ToastContainer } from "react-toastify";

const StakingNewForm = () => {
  const { action, data, tronWeb } = useContext(TronContext);
  const [tabs, setTabs] = useState<"def" | "private">("def");
  const [value, setValue] = useState(0);
  const onChangeValue = (e: any) => {
    setValue(e.target.value);
  };
  const [balance, setBalance] = useState({
    def: 0,
    preSale: 0,
  });
  interface TabProps {
    text: string;
    tab: any;
  }
  const listTabs = [
    {
      text: "Def",
      tab: "def",
    },
    // {
    //   text: "Private Sale",
    //   tab: "private",
    // },
  ];
  //approve token
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [approve, setApprove] = useState(0);
  const approveToken = async () => {
    if (approve === 0) return;
    if (action.isConnect) {
      setLoadingApprove(true);
      try {
        await action.token
          .approve(contract.stake, tronWeb.fromDecimal(10 ** 64))
          .send({
            callValue: 0,
            feeLimit: 2e8,
          });
        setApprove(1);
        setLoadingApprove(false);
      } catch (error) {
        setLoadingApprove(false);
        console.log("error", error);
        // setErrorAllow(error || '')
      }
    } else {
      // setShowTron(true);
    }
  };
  useEffect(() => {
    try {
      if (data?.token) {
        if (data?.token !== undefined) {
          data.token
            ?.allowance(action.address, contract.stake)
            .call()
            .then((allow) => {
              let allowance = allow?.remaining || allow;

              if (Number(allowance) > 10 ** 10) {
                setApprove(1);
              } else {
                setApprove(2);
              }
            });
        } else {
          setApprove(1);
        }
      }
    } catch (error) {
      console.log("error approve", error);
    }
  }, [data, action.address]);
  //end approve
  useEffect(() => {
    const getBalance = async () => {
      const [result, userPre] = await Promise.all([
        data.token?.balanceOf(action.address).call() || "",
        data.PrivateSale?.users(action.address).call() || "",
      ]);
      if (result !== "" && userPre !== "") {
        const { defBought, defRef, defUnlock } = userPre;
        const balancePre =
          Number(defBought) + Number(defRef) - Number(defUnlock);
        setBalance({
          def: formatCoin(+result),
          preSale: formatCoin(balancePre),
        });
      }
    };
    getBalance();
  }, [data, action.address]);
  const onConfirm = async () => {
    if (value < 10) {
      toast.error("Amount must be than 10", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    if (tabs === "def") {
      if (value > balance.def) {
        toast.error("Balance not enought!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      const joinStakingDef = await action.stake
        ?.depositDEF(multiCoin(value))
        .send({
          callValue: 0,
          feeLimit: 2e8,
        });
      if (joinStakingDef) {
        toast.success("Join Staking Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (tabs === "private") {
      if (value > balance.preSale) {
        toast.error("Balance not enought!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      const joinPrivate = await action.stake
        ?.depositTransfer(multiCoin(value))
        .send({
          callValue: 0,
          feeLimit: 2e8,
        });
      if (joinPrivate) {
        toast.success("Join Staking Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const onSetMaxValue = () => {
    setValue(balance.def);
  };
  const btnConfirm =
    approve === 1 ? (
      <Button
        onClick={onConfirm}
        styleBtn="btn-spin"
        aria-activedescendant="approve"
        text="Confirm"
      />
    ) : (
      <Button
        onClick={() => {
          !loadingApprove && approveToken();
        }}
        styleBtn="btn-spin"
        aria-activedescendant="approve"
        text="Approve"
      />
    );
  // console.log('approve', approve)
  return (
    <Wrap>
      <ToastContainer />
      <div className="snf-tabs">
        {listTabs.map(({ text, tab }: TabProps, index: number) => (
          <div key={index} className="btn-tab" aria-current={tab === tabs}>
            <button onClick={() => setTabs(tab)}>{text}</button>
          </div>
        ))}
      </div>
      <div className="snf-content">
        {tabs === "def" && (
          <div className="snf-content__form">
            <div className="title">
              <Text text="STAKING FORM" fontWeight="bold" size="xNormal" />
            </div>
            <div className="sub-title">
              <Text text="Transfer DEF from Private Sale to Staking Packages and become our super profitable investors now!" />
            </div>
            <div className="form-input">
              <div className="input-number">
                <Text text="Input number" size="small" />
              </div>
              <FormValue
                value={value}
                onChange={onChangeValue}
                onSetMaxValue={onSetMaxValue}
              />
              <div className="balance">
                <Text text="Balance: " size="small" color="coin" />
                <Coin value={balance.def} size="tiny" format="0,0.00" />
              </div>
              {/* <div className="button-confirm">{btnConfirm}</div> */}
            </div>
          </div>
        )}
        {tabs === "private" && (
          <div className="snf-content__form">
            <div className="title">
              <Text text="STAKING FORM" fontWeight="bold" size="xNormal" />
            </div>
            <div className="sub-title">
              <Text text="Transfer DEF from Private Sale to Staking Packages and become our super profitable investors now!" />
            </div>
            <div className="form-input">
              <div className="input-number">
                <Text text="Input number" size="small" />
              </div>
              <FormValue
                value={value}
                onChange={onChangeValue}
                onSetMaxValue={onSetMaxValue}
              />
              <div className="balance">
                <Text text="Balance: " size="small" color="coin" />
                <Coin value={balance.preSale} size="tiny" format="0,0.00" />
              </div>
              <div className="button-confirm">{btnConfirm}</div>
            </div>
          </div>
        )}
      </div>
    </Wrap>
  );
};

export default StakingNewForm;
const Wrap = styled.div`
  .snf-tabs {
    display: flex;
    align-items: center;
    background-color: #242939;
    .btn-tab {
      width: 100%;
      max-width: 220px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      button {
        border-radius: 10px 10px 0 0;
        padding: 10px 15px;
        border: 0;
        margin-right: 20px;
        font-size: 1rem;
        font-weight: bold;
        color: #798fa6;
        background-color: transparent;
        background-color: #2b374b;
        width: 100%;
        cursor: pointer;
        :focus {
          outline: unset;
        }
        :hover {
          background-color: #262e3f;
          color: #fff;
        }
      }
      &[aria-current="true"] {
        button {
          background-color: #262e3f;
          color: #fff;
        }
      }
    }
  }
  .snf-content {
    padding: 45px;
    &__form {
      .title {
        text-align: center;
        margin-bottom: 30px;
      }
      .sub-title {
        text-align: center;
        margin-bottom: 60px;
      }
      .form-input {
        .input-number {
          margin-bottom: 10px;
        }
        .balance {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 10px;
        }
        .button-confirm {
          text-align: center;
          margin-top: 40px;
        }
      }
    }
  }
`;
