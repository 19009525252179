import React from 'react'
import styled, { css } from 'styled-components'
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string,
    alt?: string,
    type?: 'normal' | 'cover'
}
const Image = ({ src, alt = '', type = 'normal', ...props }: ImageProps) => {
    return (
        <Images src={src} alt={alt} type={type} {...props} />
    )
}
export default React.memo(Image)
interface ImageElementProps {
    type?: string;
}
const Images = styled.img<ImageElementProps>`
    ${props => props.type === 'cover' && css`
        object-fit:cover;
    `}
`