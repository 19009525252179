import React from 'react'
import styled from 'styled-components'
import imgAvatar from 'assets/images/avatar-user.svg'
import Image from 'core/Image'
import Text from 'core/Text'
import Coin from 'core/Coin'
import { breakpointsMedia } from 'assets/containts/breakpoints'
import Address from 'core/Address'

interface StakingTreeProps {
    username: string,
    userRef: string,
    nodeLeftValue?: number,
    nodeRightValue?: number,
    nodeLeftRef?: string,
    nodeRightRef: string
}
const StakingJoinTree = ({
    username,
    userRef,
    nodeLeftValue,
    nodeRightValue,
    nodeLeftRef = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
    nodeRightRef = 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb'

}: StakingTreeProps) => {
    const nodeLeftNull = nodeLeftRef !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"
    const nodeRightNull = nodeRightRef !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"
    // console.log('ref user', userRef)
    return (
        <Wrap>
            <div className='st-info'>
                <div className="st-info__img">
                    <Image src={imgAvatar} />
                </div>
                <div className="st-info__info">
                    <Text text={username} />
                    {/* <Text className="ref" size="small" text={`${userRef}`} /> */}
                    <Address address={userRef} />
                </div>
            </div>
            <div className='st-tree'>
                <div className="st-tree__dasher">
                    <div className="dasher-ngang">
                        <div className="dasher-ngang__left" aria-current={nodeLeftNull}>

                        </div>
                        <div className="dasher-ngang__right" aria-current={nodeRightNull} >

                        </div>
                        dsa</div>
                    <div className="dots dots__left" aria-current={nodeLeftNull}>.</div>
                    <div className="dots" aria-current={nodeRightNull}>.</div>
                </div>
                <div className="st-tree__node node">
                    <div className=" node-child node__left" >
                        <div className="node-item" aria-current={nodeLeftNull}>
                        </div>
                        {nodeLeftNull &&
                            <>
                                <div className="node-value">
                                    <Coin color="primary" value={nodeLeftValue || 0} />
                                </div>
                                {/* <Text title={nodeLeftRef} className="ref" text={`${nodeLeftRef}`} /> */}
                                <Address address={nodeLeftRef} />
                            </>
                        }
                    </div>
                    <div className=" node-child node__right" >
                        <div className="node-item node-item--red" aria-current={nodeRightNull}>
                        </div>
                        {nodeRightNull &&
                            <>
                                <div className="node-value">
                                    <Coin color="red" value={nodeRightValue || 0} />
                                </div>
                                {/* <Text title={nodeRightRef} className="ref" text={`${nodeRightRef}`} /> */}
                                <Address address={nodeRightRef} />

                            </>
                        }


                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default React.memo(StakingJoinTree)
const Wrap = styled.div`
    .ref{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        max-width:150px;
    }
    .st-info{
        display:flex;
        align-items:center;
        justify-content:center;
        background: #4D7FB2 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 0px #0000001A;
        border-radius: 5px;
        padding:10px 20px;
        margin-bottom:35px;
        width:100%;
        max-width:250px;
        overflow:hidden;
        margin-left:auto;
        margin-right:auto;
        &__img{
            width:35px;
            margin-right:10px;
        }
        &__info{
            text-align:left;
            >p{
                padding:0 !important;
                &.ref{
                    width:100%;
                    max-width:170px;
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow:ellipsis;
                }
            }
        }
    }
    .st-tree{
        &__dasher{
            position:relative;
            /* width:550px; */
            width:100%;
            max-width:275px;
            height:30px;
            margin-left:auto;
            margin-right:auto;
            .dasher-ngang{
                width:100%;
                height:1px;
                font-size:0;
                /* border-bottom:2px dashed #BCCEE2; */
                letter-spacing:4px;
                display:flex;
                >div{
                    flex-basis:50%;
                    width:100%;
                    height:1px;
                    border-bottom:2px dashed #BCCEE2;
                }
               
                :before{
                    content:'';
                    width:1px;
                    height:35px;
                    border-left:2px dashed #BCCEE2;
                    position:absolute;
                    left:50%;
                    transform:translateX(-50%);
                    top:-35px;
                    letter-spacing:4px;

                }
                &__right{
                    transform:translateX(10px);
                    &[aria-current="true"]{
                        border-color:#FF7555;
                        /* transform:translateX(10px); */
                    }
                }
                &__left{
                    &[aria-current="true"]{
                        border-color:#FFA143;
                    }
                }
            }
            .dots{
                position:absolute;
                width:100%;
                font-size:0;
                left:0;
                width:100%;
                height:30px;
                :before{
                    content:"";
                    width:1px;
                    height:30px;
                    position:absolute;
                    top:0;
                    right:-1px;
                    border-left:2px dashed #BCCEE2; 
                    letter-spacing:4px;
                }
                :after{
                    content:'';
                    width:5px;
                    height:5px;
                    background-color:#BCCEE2;
                    position: absolute;
                    right:-2px;
                    bottom:0;
                    border-radius:999px;
                   
                }
                &[aria-current="true"]{
                    ::before{
                        border-color:#FF7555;
                    }
                    ::after{
                        background-color:#FF7555;
                    }
                }
                &__left{
                    right:0;
                    :before{
                        left:0;
                    }
                    :after{
                        left:-2px;
                    }
                    &[aria-current="true"]{
                        ::before{
                            border-color:#FFA143;
                        }
                        ::after{
                            background-color:#FFA143;
                        }
                    }
                }
            }
        }
        &__node{
            display:flex;
            justify-content:space-between;
            position:relative;
            margin-left:auto;
            margin-right:auto;
            margin-top:4px;
            &.node{
                .node-child{
                    text-align:center;
                    /* width:200px; */
                    width:100%;
                    max-width:200px;
                    overflow:hidden;
                    .node-item{
                        width:33px;
                        height:33px;
                        background-color:#293246;
                        border:3px solid #BCCEE2;
                        border-radius:999px;
                        margin-left:auto;
                        margin-right:auto;
                        cursor: pointer;
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        margin-bottom:5px;
                        
                        &[aria-current="true"]{
                            border:3px solid #FFA143;
                        }
                        &--red{
                            &[aria-current="true"]{
                                border:3px solid #FF7555;
                            }

                        }
                    }
                    &.node__left{
                        .node-item{
                            margin-left:0;
                        }
                        .node-value{
                            >div{
                                justify-content:flex-start;
                            }
                        }
                        .src-copy__input{
                            justify-content:flex-start;
                        }
                    }
                    &.node__right{
                        .node-item{
                            margin-right:0;
                        }
                        .node-value{
                            >div{
                                justify-content:flex-end;
                            }
                        }
                        .src-copy__input{
                            justify-content:flex-end;
                        }
                    }
                    .node-value{
                        >div{
                            justify-content:center;
                        }
                    }
                   
                }
            }
        }
    }
    ${breakpointsMedia.landscape}{
        .ref{
            max-width:180px;
        }
        .st-tree{
            &__dasher{
                max-width:345px;
                .dasher-ngang{
                    :before{
                        height:35px;
                        top:-35px;
                    }
                }
                .dots{
                    height:30px;
                }
            }
            &__node{
                &.node{
                    .node-child{
                        max-width:150px;
                        .node-item{
                            width:50px;
                            height:50px;
                            margin-left:auto;
                            margin-right:auto;
                        }
                        .node-value{
                            >div{
                                justify-content:center;
                            }
                        }
                        &.node__left{
                            .node-item{
                                margin-left:auto;
                            }
                            .node-value{
                                >div{
                                    justify-content:center;
                                }
                            }
                            .src-copy__input{
                                justify-content:center;
                            }
                        }
                        &.node__right{
                            .node-item{
                                margin-right:auto;
                            }
                            .node-value{
                                >div{
                                    justify-content:center;
                                }
                            }
                            .src-copy__input{
                                justify-content:center;
                            }
                        }
                    }
                }
            }
        }
    }
    ${breakpointsMedia.desktop}{
        .st-tree{
            &__dasher{
                max-width:580px;
            }
        }
    }
    ${breakpointsMedia.largeDesktop}{
        .ref{
            max-width:200px;
        }
        .st-tree{
            &__dasher{
                max-width:250px;
                .dasher-ngang{
                    :before{
                        height:35px;
                        top:-35px;
                    }
                }
                .dots{
                    height:30px;
                }
            }
            &__node{
                &.node{
                    .node-child{
                        max-width:200px;
                        .node-item{
                            width:50px;
                            height:50px;

                        }
                        .node-value{
                            >div{
                                justify-content:center;
                            }
                        }
                        .node-value{
                            >div{
                                justify-content:center;
                            }
                        }
                    }
                }
            }
        }
    }
    ${breakpointsMedia.xLargeDesktop}{
        .ref{
            max-width:200px;
        }
        .st-tree{
            &__dasher{
                max-width:340px;
                .dasher-ngang{
                    :before{
                        height:35px;
                        top:-35px;
                    }
                }
                .dots{
                    height:30px;
                }
            }
            &__node{
                &.node{
                    .node-child{
                        max-width:200px;
                        .node-item{
                            width:50px;
                            height:50px;

                        }
                        .node-value{
                            >div{
                                justify-content:center;
                            }
                        }
                        .node-value{
                            >div{
                                justify-content:center;
                            }
                        }
                    }
                }
            }
        }
    }
    ${breakpointsMedia.xxLargeDesktop}{
        .ref{
            max-width:200px;
        }
        .st-tree{
            &__dasher{
                max-width:550px;
                .dasher-ngang{
                    :before{
                        height:35px;
                        top:-35px;
                    }
                }
                .dots{
                    height:30px;
                }
            }
            &__node{
                &.node{
                    .node-child{
                        max-width:200px;
                        .node-item{
                            width:50px;
                            height:50px;

                        }
                        .node-value{
                            >div{
                                justify-content:center;
                            }
                        }
                        .node-value{
                            >div{
                                justify-content:center;
                            }
                        }
                    }
                }
            }
        }
    }
    @media(max-width:420px){
        .st-tree{
            &__dasher{
                max-width:325px;
            }
        }
    }
    @media(max-width:384px){
        .st-tree{
            &__dasher{
                max-width:285px;
            }
        }
    }
    @media(max-width:375px){
        .st-tree{
            &__dasher{
                max-width:285px;
            }
        }
    }
    @media(max-width:320px){
        .st-tree{
            &__dasher{
                max-width:230px;
            }
        }
    }
`