import React, { memo } from 'react'
import { AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer, ComposedChart } from 'recharts'
import styled from 'styled-components';

interface CustomizedAxisTickProps {
    x: number
    y: number
    stroke: string
    payload: any
}
const CustomizedAxisTick = ({ x, y, stroke, payload }: CustomizedAxisTickProps) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={10} y={0} dy={16} textAnchor="end" fill={stroke} transform="rotate(0)">{payload.value}</text>
        </g>
    );
};
interface ArrayChartInfoProps {
    data?: Array<any>
}
const ArrayChartInfo = ({ data }: ArrayChartInfoProps) => {
    return (
        <ArrayChartInfoWrap>
            <ResponsiveContainer width='99%' height={200}>
                <ComposedChart data={data}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#FFA143" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#FFA143" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#368FEA" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#368FEA" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" tick={<CustomizedAxisTick x={0} y={0} stroke={'#A4BED9'} payload />} />
                    <YAxis width={0} />
                    <Tooltip />
                    <Area type="monotone" dataKey="Deposit" stroke="#368FEA" fillOpacity={1} fill="url(#colorPv)" />
                    <Area type="monotone" dataKey="Borrow" stroke="#FFA143" fillOpacity={1} fill="url(#colorUv)" />
                </ComposedChart>
            </ResponsiveContainer>
        </ArrayChartInfoWrap>
    )
}

export default ArrayChartInfo
const ArrayChartInfoWrap = memo(styled.div`
 
`)