import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import LogoApp from '../../assets/images/LogoApp.svg'
import Image from '../../core/Image'
import Text from '../../core/Text'
import { RiMenuLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { MenuOpenContext } from '../../contexts/MenuOpenContext'

const Logo = () => {
    const { handleOpenMenu, isOpenMenu } = useContext(MenuOpenContext)
    return (
        <Warp>
            <Link to='/' className="logo">
                <Image src={LogoApp} />
            </Link>
            <div className="header-warp__text">
                <Link to='/'><Text text="Deffect" fontWeight='bold' size='heading' element='span' /></Link>
            </div>
            <div className="menu" onClick={() => handleOpenMenu(isOpenMenu)}>
                <RiMenuLine />
            </div>
        </Warp>
    )

}

export default Logo
const Warp = memo(styled.div`
    box-sizing:border-box;
    flex:0.25;
    /* flex:0.25; */

    display:flex;
    a{
        text-decoration:none;
        p{
            color:#FF972F !important;
        }
    }
    .logo{
        padding-top: 18px;
        padding-left: 13px;
      
    }
    .header-warp__text{
        padding-left:10px;   
    } 
    @media screen and (min-width:1025px) {
        .menu{
            display:none;
        }
    }
    @media screen and (min-width:768px) and (max-width:1024px){
        .menu{
            display:none;
        }
        .header-warp__text{
        display:none;
        } 
    }
    @media screen and (min-width:320px) and (max-width:767px){
        
        .header-warp__text{
            display: none;
        }
        .header-warp__search{
            display: none;
        }
        .menu{
            display:flex;
            align-items:center;
            svg{
                background-color:#2B374B;
                padding:15px 10px;
                border-radius:3px;
                fill:#FFA143 ;
            }
        }
        .logo{
            padding:4px;
        }
    }
`)