import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import FundInformation from './FundInformation/FundInformation'
import HumaniliesTable from './HumaniliesTable/HumaniliesTable'

const Humanilies = () => {
    const [dataCoinFund, setDataCoinFund] = useState({
        total: 0,
        totalDisbursed: 0,
        projectsDisbursed: 0
    })
    useEffect(() => {
        const getDataCoinFun = async () => {
            try {

            } catch (error) {
                // console.log(object)
                //TODO getDataCoinFun
            }
        }
        getDataCoinFun()
    }, [])


    return (
        <HumaniliesWrap className="container">
            <div className="hw_header">
                <FundInformation dataCoinFund={dataCoinFund} />
            </div>
            <div className="hw_cont">
                <HumaniliesTable />
            </div>
        </HumaniliesWrap>
    )
}

export default Humanilies
const HumaniliesWrap = memo(styled.div`
    .hw_header{
        margin-bottom:2rem;
    }
`)

