import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../../core/Text'
import I18n from 'i18n-js'
import AssetInfoItem from './AssetInfoItem'
interface AssetInfoInfoProps {
    dataAssetInfo: {
        price: number,
        loan: number,
        rate: number,
        loansOut: number,
        total: number
    }
}
const AssetInfo = ({ dataAssetInfo }: AssetInfoInfoProps) => {
    const assetInfoData = [
        { id: 1, text: 'Price', amount: `$ ${I18n.toNumber(dataAssetInfo.price)}` },
        { id: 2, text: 'Loan-to-value (LTV) Ratio', amount: `${I18n.toNumber(dataAssetInfo.loan)} %` },
        { id: 3, text: 'Utilization Rate', amount: `${I18n.toNumber(dataAssetInfo.rate)} %` },
        { id: 4, text: 'Loans Outstanding', amount: `${I18n.toNumber(dataAssetInfo.loansOut)} USDT` },
        { id: 5, text: 'Total Market Deposit', amount: `${I18n.toNumber(dataAssetInfo.total)} USDT` },
    ]
    return (
        <AssetInfoWrap>
            <div className="aiw_header">
                <Text text="Asset Info" fontWeight="bold" size="xNormal" />
            </div>
            <div className="aiw_cont">
                {
                    assetInfoData.map((t, index) => {
                        return <AssetInfoItem key={index} text={t.text} amount={t.amount} />
                    })
                }
            </div>
        </AssetInfoWrap>
    )
}

export default AssetInfo
const AssetInfoWrap = memo(styled.div`
    background: #262E3F 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:2rem;
    .aiw_header{
        margin-bottom:1rem;
    }
    .aiw_cont{
        >div:last-child{
            border-bottom:0;
        }
        .aiwc_item{
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding: 1rem 0 ;
            border-bottom: 1px solid rgba(58, 78, 110,0.5);
        }
    }
    @media screen and (max-width:1024px){
        border-radius: 5px;
        padding:1.875rem;
    }
    @media screen and (max-width:767px){
        border-radius: 5px;
        padding:1.5rem;
    }
`)