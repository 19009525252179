import React, { ReactNode, useState, useEffect } from "react";
import TronWeb from "tronweb";
import { node, contract } from "./tronConfig";
import * as qs from "query-string";
interface IProps {
  children: ReactNode;
}

const tronWeb = new TronWeb({
  fullNode: node,
  solidityNode: node,
  eventServer: node,
  privateKey:
    "EC60C070F98F768400F45CF0674092998B716E7B0D5C26F31E30B2E1A3785B7D",
});

const TronContext = React.createContext({
  connectTronLink: () => { },
  data: {
    ready: false,
    token: undefined as any,
    member: undefined as any,
    seedSale: undefined as any,
    usdt: undefined as any,
    PrivateSale: undefined as any,
    PrivateMember: undefined as any,
    price: undefined as any,
    stake: undefined as any,
  },
  action: {
    isConnect: false,
    address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
    token: undefined as any,
    member: undefined as any,
    seedSale: undefined as any,
    swap: undefined as any,
    usdt: undefined as any,
    PrivateSale: undefined as any,
    PrivateMember: undefined as any,
    price: undefined as any,
    stake: undefined as any
  },
  ref: "TUAaHSB5fzD4zf9yXZ5jGi25evpmDWe1mv",

  tronWeb,
});

const waitTron = () => {
  try {
    return new Promise((res) => {
      let attempts = 0,
        maxAttempts = 20;
      const checkTron = () => {
        if (
          (window as any).tronWeb &&
          (window as any).tronWeb.defaultAddress.base58
        ) {
          res(true);
          return;
        }
        attempts++;
        if (attempts >= maxAttempts) {
          res(false);
          return;
        }
        setTimeout(checkTron, 100);
      };
      checkTron();
    });
  } catch (error) {
    console.log("error tron", error);
  }
};

const initStatic = async () => {
  const [
    token,
    member,
    usdt,
    seedSale,
    PrivateMember,
    PrivateSale,
    stake,
    price,
  ] = await Promise.all([
    tronWeb.contract().at(contract.token),
    tronWeb.contract().at(contract.member),
    tronWeb.contract().at(contract.usdt),
    tronWeb.contract().at(contract.seedSale),
    tronWeb.contract().at(contract.PrivateMember),
    tronWeb.contract().at(contract.PrivateSale),
    tronWeb.contract().at(contract.stake),
    tronWeb.contract().at(contract.price),
  ]);
  return {
    ready: true,
    token,
    member,
    usdt,
    seedSale,
    PrivateMember,
    PrivateSale,
    stake,
    price,
  };
};
const initAction = async () => {
  let tronExist = await waitTron();
  if (tronExist) {
    const token = await (window as any).tronWeb.contract().at(contract.token);
    const member = await (window as any).tronWeb.contract().at(contract.member);
    const seedSale = await (window as any).tronWeb.contract().at(contract.seedSale);
    const swap = await (window as any).tronWeb.contract().at(contract.swap);
    const usdt = await (window as any).tronWeb.contract().at(contract.usdt);
    const PrivateMember = await (window as any).tronWeb.contract().at(contract.PrivateMember);
    const PrivateSale = await (window as any).tronWeb.contract().at(contract.PrivateSale);
    const stake = await (window as any).tronWeb.contract().at(contract.stake);
    const price = await (window as any).tronWeb.contract().at(contract.price);
    // const [token, member, seedSale] = await Promise.all([
    //   (window as any).tronWeb.contract().at(contract.token),
    //   (window as any).tronWeb.contract().at(contract.member),
    //   (window as any).tronWeb.contract().at(contract.seedSale),
    // ]);
    // const [swap, usdt] = await Promise.all([
    //   (window as any).tronWeb.contract().at(contract.swap),
    //   (window as any).tronWeb.contract().at(contract.usdt),
    // ]);
    // const [PrivateMember, PrivateSale] = await Promise.all([
    //   (window as any).tronWeb.contract().at(contract.PrivateMember),
    //   (window as any).tronWeb.contract().at(contract.PrivateSale),
    // ]);
    // const [stake, price] = await Promise.all([
    //   (window as any).tronWeb.contract().at(contract.stake),
    //   (window as any).tronWeb.contract().at(contract.price)
    // ]);

    // setInterval(async () => {
    //   await stake.recovery().send({
    //     callValue: 0,
    //     feeLimit: 8e7,
    //   });
    // }, 1000);

    return {
      isConnect: true,
      address: (window as any).tronWeb.defaultAddress.base58,
      // address: "TUAaHSB5fzD4zf9yXZ5jGi25evpmDWe1mv",
      token,
      member,
      seedSale,
      swap,
      usdt,
      PrivateMember,
      PrivateSale,
      stake,
      price,
    };
  } else {
    return {
      isConnect: false,
      address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" as any,
      token: undefined as any,
      member: undefined as any,
      seedSale: undefined as any,
      swap: undefined as any,
      usdt: undefined as any,
      PrivateMember: undefined as any,
      PrivateSale: undefined as any,
      stake: undefined as any,
      price: undefined as any,
    };
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: IProps) => {
  const [ref, setRef] = useState(contract.member);
  const [tronStatic, setTronStatic] = useState({
    ready: false,
    token: undefined as any,
    member: undefined as any,
    usdt: undefined as any,
    seedSale: undefined as any,
    PrivateMember: undefined as any,
    PrivateSale: undefined as any,
    stake: undefined as any,
    price: undefined as any,
  });

  const getRef = async () => {
    const parsed = qs.parse(window.location.search);
    if (parsed.r) {
      if (tronWeb.isAddress(parsed.r)) {
        if (window.localStorage) {
          window.localStorage.setItem("ref", (parsed as any).r);
        }
        setRef((parsed as any).r);
      }
    } else {
      if (window.localStorage) {
        let refLocal = window.localStorage.getItem("ref");
        if (tronWeb.isAddress(refLocal)) {
          setRef(refLocal as string);
        }
      }
    }
  };

  useEffect(() => {
    initStatic().then((dataTron) => setTronStatic(dataTron));
    getRef();
  }, []);

  const [tronAction, setTronAction] = useState({
    isConnect: false,
    address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" as any,
    token: undefined as any,
    member: undefined as any,
    seedSale: undefined as any,
    swap: undefined as any,
    usdt: undefined as any,
    PrivateMember: undefined as any,
    PrivateSale: undefined as any,
    stake: undefined as any,
    price: undefined as any
  });
  useEffect(() => {
    initAction().then((actionTron) => setTronAction(actionTron));
  }, []);

  const connectTronLink = () => {
    initAction().then((actionTron) => setTronAction(actionTron));
  };

  useEffect(() => {
    if (tronAction.isConnect) {
      window.addEventListener("message", function (e) {
        if (e.data.message?.action === "setAccount") {
          if (e.data.message.data.address) {
            setTronAction({
              ...tronAction,
              address: e.data.message.data.address,
            });
          } else {
            setTronAction({
              isConnect: false,
              address: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb" as any,
              token: undefined as any,
              member: undefined as any,
              seedSale: undefined as any,
              swap: undefined as any,
              usdt: undefined as any,
              PrivateMember: undefined as any,
              PrivateSale: undefined as any,
              stake: undefined as any,
              price: undefined as any
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tronAction.isConnect]);
  return (
    <TronContext.Provider
      value={{
        connectTronLink,
        data: tronStatic,
        action: tronAction,
        ref,
        tronWeb,
      }}
    >
      {children}
    </TronContext.Provider>
  );
};

export { TronContext, tronWeb };
