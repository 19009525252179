import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
interface VoteInputItemProps {
    usdtAmount: string
    setUsdtAmount: any
    banlanceCoin: {
        coinTRX: number
        coinUSDT: number
    }
    errorInput: string
    setErrorInput: any
}
const VoteInputItem = ({ banlanceCoin, setUsdtAmount, errorInput, setErrorInput }: VoteInputItemProps) => {
    return (
        <VoteInputItemWrap errorInput={errorInput}>
            <input type="number"
                placeholder="0"
                onChange={e => {
                    setUsdtAmount(Number(e.target.value))
                    if (e.target.value === '') {
                        setUsdtAmount(e.target.value)
                    }
                    else {
                        if (+e.target.value > banlanceCoin.coinTRX) {
                            setErrorInput("Insufficientbalance");
                        }
                        else {

                            if (10000 >= +e.target.value && +e.target.value >= 100) {
                                setErrorInput("");
                            }
                            else {
                                setErrorInput('inputNotRight')
                                //TODO Input FormStaking
                            }
                        }
                    }


                }} />
        </VoteInputItemWrap>
    )
}

export default VoteInputItem

const VoteInputItemWrap = memo(styled.div`
    position: relative;
    .sfiwc_max{
        position:absolute;
        background: #4D7FB2 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        padding:1px 10px;            
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        outline:none;
        border:none;
        cursor: pointer;
    }
    input {
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        border-bottom: ${(props: any) => props.errorInput !== "" ? "1px solid red" : "1px solid #798FA6"};
        padding:0.5rem 4rem 0.5rem 0;
        box-sizing: border-box;
        color: #fff;
        font-size: 1rem;
        ::placeholder {
        color: #fff;
        }
        :disabled{
        color:#798FA6 ;
        }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`)