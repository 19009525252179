import React, { memo, } from 'react'
import styled from 'styled-components'
import imgCoinUSDT from '../../../assets/images/usdt-coin-icon.svg'
import imgCoinETH from '../../../assets/images/coinETH.svg'
import imgCoinBTC from '../../../assets/images/coinBTC.svg'
import imgCoinTRX from '../../../assets/images/tron-coin-icon.svg'
import imgCoinLink from '../../../assets/images/coinLink.png'
import imgCoinBAT from '../../../assets/images/coinBAT.png'
import DeffectBoloContItem from './DeffectBoloContItem'

interface DeffectBoloContentProps {
    dataCoinBoloCont: {
        deposit: number,
        depositMining: number,
        depositMarket: number,
        borrow: number,
        apr: number,
        size: number
    }
}
const DeffectBoloContent = ({ dataCoinBoloCont }: DeffectBoloContentProps) => {
    const dataDeffectBoloCont = [
        { id: 1, img: imgCoinUSDT, name: 'USDT', deposit: dataCoinBoloCont.deposit, depositMining: dataCoinBoloCont.depositMining, depositMarket: dataCoinBoloCont.depositMarket, borrow: dataCoinBoloCont.borrow, apr: dataCoinBoloCont.apr, size: dataCoinBoloCont.size },
        { id: 2, img: imgCoinETH, name: 'ETH', deposit: dataCoinBoloCont.deposit, depositMining: dataCoinBoloCont.depositMining, depositMarket: dataCoinBoloCont.depositMarket, borrow: dataCoinBoloCont.borrow, apr: dataCoinBoloCont.apr, size: dataCoinBoloCont.size },
        { id: 3, img: imgCoinBTC, name: 'BTC', deposit: dataCoinBoloCont.deposit, depositMining: dataCoinBoloCont.depositMining, depositMarket: dataCoinBoloCont.depositMarket, borrow: dataCoinBoloCont.borrow, apr: dataCoinBoloCont.apr, size: dataCoinBoloCont.size },
        { id: 4, img: imgCoinTRX, name: 'TRX', deposit: dataCoinBoloCont.deposit, depositMining: dataCoinBoloCont.depositMining, depositMarket: dataCoinBoloCont.depositMarket, borrow: dataCoinBoloCont.borrow, apr: dataCoinBoloCont.apr, size: dataCoinBoloCont.size },
        { id: 5, img: imgCoinLink, name: 'Link', deposit: dataCoinBoloCont.deposit, depositMining: dataCoinBoloCont.depositMining, depositMarket: dataCoinBoloCont.depositMarket, borrow: dataCoinBoloCont.borrow, apr: dataCoinBoloCont.apr, size: dataCoinBoloCont.size },
        { id: 6, img: imgCoinBAT, name: 'BAT', deposit: dataCoinBoloCont.deposit, depositMining: dataCoinBoloCont.depositMining, depositMarket: dataCoinBoloCont.depositMarket, borrow: dataCoinBoloCont.borrow, apr: dataCoinBoloCont.apr, size: dataCoinBoloCont.size },
    ]
    return (
        <DeffectBoloContentWrap>
            {
                dataDeffectBoloCont.map((t, index) => {
                    return <DeffectBoloContItem
                        key={index}
                        id={t.id}
                        img={t.img}
                        name={t.name}
                        deposit={t.deposit}
                        depositMining={t.depositMining}
                        depositMarket={t.depositMarket}
                        borrow={t.borrow}
                        apr={t.apr}
                        size={t.size}
                    />
                })
            }
        </DeffectBoloContentWrap>
    )
}

export default DeffectBoloContent
const DeffectBoloContentWrap = memo(styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content:space-between;
    >div:nth-last-child(1),  >div:nth-last-child(4){
        margin-right:0;
    }
    .dbcw_item{
        background: #262E3F 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        padding: 2.125rem 1.875rem;
        min-width:400px;
        margin-right:2.25rem;
        margin-bottom:2.25rem;
        flex:1;
        cursor: pointer;
        transition: all .3s ease-in-out;
        :hover{
            transform: scale(1.05);
        }
        .dbcwi_header{
            display:flex;
            justify-content:center;
            align-items:center;
            margin-bottom:1.875rem;

            .dbcwih_img{
                padding:0 0.5rem;

                img{
                    width:32px;
                }
            }
            .dbcwih_text{
                padding:0 0.5rem;
            }
        }
        .dbcwi_cont{
            >div:last-child{
                    border-bottom:0;
            }
            .dbcwic_child ,.dbcwic_child_bot{
                display:flex;
                border-bottom:1px solid rgba(58, 78, 110,0.5);
                .dbcwic_item{
                    flex:1;
                    padding:0rem 1rem 1rem;
                    text-align:center;
                    border-right:1px solid rgba(58, 78, 110,0.5);
                    
                    p:first-child{
                        margin-bottom:0.5rem !important;
                        min-height:42px;
                        align-items:center;
                        display:flex;
                        justify-content:center;

                    }
                }
                >div:last-child{
                    border-right:0;
                }
            }
            .dbcwic_child_bot{
                .dbcwic_item{
                    padding:1rem 1rem 0;
                }
            }
        }
    }
    @media screen and (max-width:1599px){
        .dbcw_item{
            padding: 2.25rem 1rem;
            min-width:335px;
            margin-right:1.25rem;
            margin-bottom:1.25rem;
            .dbcwi_header{
                margin-bottom:1.5rem;
                .dbcwih_img{
                    padding:0 0.25rem;
                    img{
                        width:32px;
                    }
                }
                .dbcwih_text{
                    padding:0 0.25rem;
                }
            }
            .dbcwi_cont{
                .dbcwic_child ,.dbcwic_child_bot{
                    .dbcwic_item{
                        padding:0 0.5rem 0.75rem;
                        p:first-child{
                            min-height:38px;
                        }
                        
                    }
                }
                .dbcwic_child_bot{
                    .dbcwic_item{
                        padding:0.75rem 0.5rem 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width:1279px){
        >div:nth-last-child(1),>div:nth-last-child(3),>div:nth-last-child(5){
        margin-right:0;
    }
    >div:nth-last-child(4){
        margin-right:1rem;
    }
        .dbcw_item{
            min-width:350px;
            .dbcwi_cont{
                .dbcwic_child,.dbcwic_child_bot{
                    flex-direction:column;
                    border-bottom:0;
                    .dbcwic_item{
                        display:flex;
                        justify-content:space-between;
                        border-bottom:1px solid rgba(58, 78, 110,0.5);
                        align-items:center;
                        padding: 1rem 0;
                        border-right:0;
                        p:first-child{
                            min-height:0;
                            margin-bottom:0rem !important;
                        }
                    }
                }
                .dbcwic_child_bot{
                   >div:last-child{
                    border-bottom:0;
                   }
                }
            }
        }
    }
    @media screen and (max-width:1024px){
        .dbcw_item{
            min-width:262px;
            border-radius: 5px;
            padding:2.25rem 1.25rem;
            .dbcwi_header{
                .dbcwih_img{
                    img{
                        width:25px;
                    }
                }
            }
            .dbcwi_cont{

                .dbcwic_child,.dbcwic_child_bot{
                    flex-direction:column;
                    border-bottom:0;
                    .dbcwic_item{
                        display:flex;
                        justify-content:space-between;
                        border-bottom:1px solid rgba(58, 78, 110,0.5);
                        align-items:center;
                        padding: 1rem 0;
                        border-right:0;
                    }
                }
                .dbcwic_child_bot{
                   >div:last-child{
                    border-bottom:0;
                   }
                }
            }
        }
    }
    @media screen and (max-width:767px){
        margin:0 1rem;
    }
    @media screen and (max-width:576px){
        >div:nth-last-child(4) {
        margin-right: 0;
    }
        .dbcw_item{
            min-width:280px;
            border-radius: 5px;
            padding:2.25rem 1.25rem;
            margin-right:0;
            .dbcwi_cont{
                .dbcwic_child,.dbcwic_child_bot{
                    
                    .dbcwic_item{
                        padding: 1rem 0;
                        border-right:0;
                    }
                }
                .dbcwic_child_bot{
                   >div:last-child{
                    border-bottom:0;
                   }
                }
            }
        }
    }
    
`)