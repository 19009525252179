import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import imgAvatar from 'assets/images/avatar-user.svg'
import Image from 'core/Image'
import Text from 'core/Text'
import { BsCheck } from 'react-icons/bs'
import { breakpointsMedia } from 'assets/containts/breakpoints'
import Address from 'core/Address'
interface StakingTreeProps {
    username: string,
    userRef: string,
    nodeLeft: string,
    nodeRight: string,
    onChangeActive: (e: any) => void,
    activeNode: any
}
const StakingTreeNode = ({ username, userRef, nodeLeft, nodeRight, activeNode, onChangeActive }: StakingTreeProps) => {
    const refLocation = userRef
    return (
        <Wrap>
            <div className='st-info'>
                <div className="st-info__img">
                    <Image src={imgAvatar} />
                </div>
                <div className="st-info__info">
                    <Text text={username} />
                    {/* <Text title={refLocation} className="ref" size="small" text={refLocation} /> */}
                    <Address address={refLocation} />
                </div>
            </div>
            <div className='st-tree'>
                <div className="st-tree__dasher">
                    <div className="dasher-ngang">dsa</div>
                    <div className="dots dots__left">.</div>
                    <div className="dots">.</div>
                </div>
                <div className="st-tree__node node">
                    <div className=" node-child node-child--left" >
                        <div className="node-item" aria-current={activeNode === 0} onClick={() => onChangeActive(0)}>
                            {activeNode === 0 && <BsCheck fontSize={25} color="#fff" />}
                        </div>
                        <Text text={nodeLeft} />
                    </div>
                    <div className=" node-child node-child--right" >
                        <div className="node-item" aria-current={activeNode === 1} onClick={() => onChangeActive(1)}>
                            {activeNode === 1 && <BsCheck fontSize={25} color="#fff" />}

                        </div>
                        <Text text={nodeRight} />

                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default React.memo(StakingTreeNode)
const Wrap = styled.div`
    .st-info{
        display:inline-flex;
        align-items:center;
        justify-content:center;
        background: #4D7FB2 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 0px #0000001A;
        border-radius: 5px;
        padding:10px 20px;
        margin-bottom:25px;
        &__img{
            width:35px;
            margin-right:10px;
        }
        &__info{
            text-align:left;
            >p{
                padding:0 !important;
                &.ref{
                    width:100%;
                    max-width:170px;
                    white-space:nowrap;
                    overflow:scroll;
                    ::-webkit-scrollbar {
                        display: none;
                        }
                }
            }
        }
    }
    .st-tree{
        &__dasher{
            position:relative;
            /* width:600px; */
            width:100%;
            max-width:250px;
            height:30px;
            margin-left:auto;
            margin-right:auto;
            .dasher-ngang{
                width:100%;
                height:1px;
                font-size:0;
                border-bottom:2px dashed #BCCEE2;
                letter-spacing:4px;

                :before{
                    content:'';
                    width:1px;
                    height:25px;
                    border-left:2px dashed #BCCEE2;
                    position:absolute;
                    left:50%;
                    transform:translateX(-50%);
                    top:-25px;
                    letter-spacing:4px;

                }
            }
            .dots{
                position:absolute;
                width:100%;
                font-size:0;
                left:0;
                width:100%;
                height:30px;
                :before{
                    content:"";
                    width:1px;
                    height:30px;
                    position:absolute;
                    top:0;
                    right:-1px;
                    border-left:2px dashed #BCCEE2; 
                    letter-spacing:4px;
                }
                :after{
                    content:'';
                    width:5px;
                    height:5px;
                    background-color:#BCCEE2;
                    position: absolute;
                    right:-2px;
                    bottom:0;
                    border-radius:999px;
                   
                }
                &__left{
                    right:0;
                    :before{
                        left:0;
                    }
                    :after{
                        left:-2px;
                    }
                }
            }
        }
        &__node{
            display:flex;
            align-items:center;
            justify-content:space-between;
            position:relative;
            /* width:670px; */
            width:100%;
            max-width:310px;
            margin-left:auto;
            margin-right:auto;
            margin-top:4px;
            &.node{
                .node-child{
                    .node-item{
                        width:35px;
                        height:35px;
                        background-color:#293246;
                        border:3px solid #BCCEE2;
                        border-radius:999px;
                        margin-left:auto;
                        margin-right:auto;
                        cursor: pointer;
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        margin-bottom:5px;

                        &[aria-current="true"]{
                            background-color:#FFA143;
                            border:3px solid #FFA143;

                        }
                    }
                   &--right{
                        transform:translateX(5px);
                   }
                }
              
            }
        }
    }
    ${breakpointsMedia.landscape}{
        .st-tree{
            &__dasher{
                max-width:435px;
                height:30px;
                .dasher-ngang{

                    :before{
                        content:'';
                        width:1px;
                        height:25px;
                        border-left:2px dashed #BCCEE2;
                        position:absolute;
                        left:50%;
                        transform:translateX(-50%);
                        top:-25px;
                        letter-spacing:4px;
                    }
                }
            }
            &__node{
                width:100%;
                max-width:495px;
                &.node{
                    .node-child{
                        .node-item{
                            width:50px;
                            height:50px;
                        }
                    &--right{
                            transform:translateX(5px);
                    }
                    }
                }
            }
        }   
    }
    ${breakpointsMedia.desktop}{}
    ${breakpointsMedia.largeDesktop}{
        .st-tree{
            &__dasher{
                max-width:600px;
                height:30px;
                .dasher-ngang{

                    :before{
                        content:'';
                        width:1px;
                        height:25px;
                        border-left:2px dashed #BCCEE2;
                        position:absolute;
                        left:50%;
                        transform:translateX(-50%);
                        top:-25px;
                        letter-spacing:4px;
                    }
                }
            }
            &__node{
                max-width:670px;
                &.node{
                    .node-child{
                        .node-item{
                            width:50px;
                            height:50px;
                        }
                    &--right{
                            transform:translateX(5px);
                    }
                    }
                }
            }
        }     
    }
`