import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { config } from 'config/apolloConfig';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
const ApolloContext = ({ children }: any) => {
    // const client = new ApolloClient({
    //     uri: config.graphql,
    //     cache: new InMemoryCache()
    // });
    const httpLink = new HttpLink({
        uri: config.graphql
    });

    const wsLink = new WebSocketLink({
        uri: config.wsGraphql,
        options: {
            reconnect: true
        }
    });
    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link,
    });
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export default ApolloContext
