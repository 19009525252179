import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
ReactDOM.render(
    <Router>
        <Suspense fallback="loading">
            <App />
        </Suspense>
    </Router>,
    document.getElementById('root')
);

reportWebVitals();
