import React, { createContext, useContext, useEffect, useState } from "react";
import { TronContext } from "./tronWeb";
interface DataCoinProps {
  bought: number;
  defBought: number;
  defRef: number;
  defUnlock: number;
  amountClaim: number;
}

export const CoinContext = createContext({
  dataCoin: {
    bought: 0,
    defBought: 0,
    defRef: 0,
    defUnlock: 0,
    amountClaim: 0,
  },
});
const CoinContextWrap = ({ children }: any) => {
  const { action, data } = useContext(TronContext);
  const [dataCoin, setDataCoin] = useState<DataCoinProps>({
    bought: 0,
    defBought: 0,
    defRef: 0,
    defUnlock: 0,
    amountClaim: 0,
  });
  const getDataCoin = async () => {
    try {
      let result = await data.PrivateSale.users(action.address).call();
      // let amountClaim = await action.fixClaim.claimed(action.address).call();
      setDataCoin({
        bought: Number(result.bought) / 10 ** 6,
        defBought: Number(result.defBought) / 10 ** 6,
        defRef: Number(result.defRef) / 10 ** 6,
        defUnlock: (Number(result.defBought) + Number(result.defUnlock)) / 10 ** 6,
        amountClaim: 0,
      });
      // setDataCoin({
      //   bought: Number(result.bought) / 10 ** 6,
      //   defBought: Number(result.defBought) / 10 ** 6,
      //   defRef: Number(result.defRef) / 10 ** 6,
      //   defUnlock: !amountClaim
      //     ? Number(result.defUnlock) / 10 ** 6
      //     : (Number(result.defBought) + Number(result.defUnlock)) / 10 ** 6,
      //   amountClaim: !amountClaim
      //     ? (Number(result.defBought) +
      //         Number(result.defRef) -
      //         Number(result.defUnlock)) /
      //       10 ** 6
      //     : 0,
      // });
    } catch (error) {
      console.log("getDataCoin PreSale", error);
    }
  };
  useEffect(() => {
    data.PrivateSale && action.address && getDataCoin();
  }, [data.PrivateSale, action]);
  return (
    <CoinContext.Provider value={{ dataCoin }}>{children}</CoinContext.Provider>
  );
};

export default CoinContextWrap;
