import React from 'react'
import { useHistory } from 'react-router-dom'
import Image from '../../../core/Image'
import Text from '../../../core/Text'
interface DeffectBoloContItemProps {
    id: number
    img: string
    name: string
    deposit: number
    depositMining: number
    depositMarket: number
    borrow: number
    apr: number
    size: number
}
const DeffectBoloContItem = ({
    id,
    img,
    name,
    deposit,
    depositMining,
    depositMarket,
    borrow,
    apr,
    size,
}: DeffectBoloContItemProps) => {
    let history = useHistory()
    const handelClickLink = (id: number) => {
        history.push(`deffectbolo/id=${id}`)
    }
    return (
        <div className="dbcw_item" onClick={() => handelClickLink(id)}>
            <div className="dbcwi_header">
                <div className="dbcwih_img">
                    <Image src={img} />
                </div>
                <div className="dbcwih_text">
                    <Text text={name} fontWeight="bold" size="xNormal" />
                </div>
            </div>
            <div className="dbcwi_cont">
                <div className="dbcwic_child">
                    <div className="dbcwic_item">
                        <Text text='Deposit APR' size="small" />
                        <Text text={`${deposit}%`} fontWeight="bold" size="xNormal" color="gray" />
                    </div>
                    <div className="dbcwic_item">
                        <Text text='Deposit Mining APR' size="small" />
                        <Text text={`${depositMining}%`} fontWeight="bold" size="xNormal" color="gray" />
                    </div>
                    <div className="dbcwic_item">
                        <Text text='Deposit Market Size' size="small" />
                        <Text text={`${depositMarket}M`} fontWeight="bold" size="xNormal" color="gray" />
                    </div>

                </div>
                <div className="dbcwic_child_bot">
                    <div className="dbcwic_item">
                        <Text text='Borrow APR' size="small" />
                        <Text text={`${borrow}%`} fontWeight="bold" size="xNormal" color="increase" />
                    </div>
                    <div className="dbcwic_item">
                        <Text text='Deposit Mining APR' size="small" />
                        <Text text={`${apr}%`} fontWeight="bold" size="xNormal" color="increase" />
                    </div>
                    <div className="dbcwic_item">
                        <Text text='Deposit Market Size' size="small" />
                        <Text text={`${size}K`} fontWeight="bold" size="xNormal" color="increase" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DeffectBoloContItem
