
import configColor from 'assets/containts/configColor'
import React from 'react'
import styled from 'styled-components'
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    content?: "text" | "icon"
    text?: string,
    styleBtn?: "normal" | "btn-roll" | "btn-spin" | "refferal" | "close",
    color?: "liner" | "gray",
    icon?: React.ReactElement,
    disabled?: boolean
}
const Button = ({ text = "", styleBtn = "normal", color = "gray", content = "text", icon, disabled = false, ...props }: ButtonProps) => {
    return (
        <ElButton
            className={styleBtn}
            aria-activedescendant={color}
            disabled={disabled}

            {...props}
        >
            {text}

        </ElButton>
    )
}

export default React.memo(Button)
const ElButton = styled.button`

    cursor: pointer;    
    font-size:1rem;
    :focus{
        outline:unset;
    }
    &.normal{
        padding:11px 10px;
        background:${configColor.colorGrayOpa};
        color:${configColor.colorWhite};
        border-radius:5px;
        border:0;
        :hover{
            background: transparent linear-gradient(231deg, ${configColor.colorGradient1} 0%, ${configColor.colorGradient2} 100%) 0% 0% no-repeat padding-box;
        }
        :disabled{
            cursor:no-drop;
            background:${configColor.colorGrayOpa};
            opacity:0.4;
        }

    }
    &.refferal{
        background: transparent linear-gradient(222deg,  ${configColor.colorGradient1} 0%, ${configColor.colorGradient2} 100%) 0% 0% no-repeat padding-box;;
        display:flex;
        align-items:center;
        justify-content:center;
        width:38px;
        height:38px;
        border-radius:5px;
        svg{
            color:${configColor.colorWhite};
            font-size:20px;
        }

    }
    &.btn-spin{
        background:transparent;
        padding:10px 10px;
        color:${configColor.colorWhite};
        border:1px solid ${configColor.colorGradient2};
        border-radius:5px;
        white-space:nowrap;
        
        :hover{
            background: transparent linear-gradient(78deg, #FF7555 0%, #FFA143 100%) 0% 0% no-repeat padding-box;
        }
      
        &[aria-activedescendant="spin"]{
            position: relative;
            background: transparent linear-gradient(78deg, ${configColor.colorGradient2} 0%, ${configColor.colorGradient1} 100%) 0% 0% no-repeat padding-box;
            border:1px;
            :hover{
                background: transparent linear-gradient(78deg, #FF7555 0%, #FFA143 100%) 0% 0% no-repeat padding-box;
            }
        }
        &[aria-activedescendant="approve"]{
            border-radius:999px;
           padding:10px 60px;
            border:0;
           position: relative;
            background: transparent linear-gradient(78deg, ${configColor.colorGradient2} 0%, ${configColor.colorGradient1} 100%) 0% 0% no-repeat padding-box;
            :hover{
                background: transparent linear-gradient(78deg, #FF7555 0%, #FFA143 100%) 0% 0% no-repeat padding-box;
            }
            :disabled{
                opacity:.6;
                cursor:no-drop;
            }
        }
        &[aria-activedescendant="auto-spin"]{
          display:flex;
          align-items:center;
          justify-content:center;
          .tiny{
              margin-left:5px;
          }
        }
        &[aria-activedescendant="auto-spin-stop"]{
          display:flex;
          align-items:center;
          justify-content:center;
          background: transparent linear-gradient(78deg, ${configColor.colorGradient2} 0%, ${configColor.colorGradient1} 100%) 0% 0% no-repeat padding-box;
          :hover{
                background: transparent linear-gradient(78deg, #FF7555 0%, #FFA143 100%) 0% 0% no-repeat padding-box;
            }
          .tiny{
              margin-left:5px;
          }
        }
        :disabled,[aria-disabled="true"]{
            /* background:transparent !important;
            cursor:no-drop;
            border:1px solid ${configColor.colorGradient2}; */
        }

    }
    &.close{
        background: transparent linear-gradient(78deg, ${configColor.colorGradient2} 0%, ${configColor.colorGradient1} 100%) 0% 0% no-repeat padding-box;
        padding:15px 50px;
        color:#fff;
        border-radius:999px;
    }

`