import { TronContext } from 'contexts/tronWeb';
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';

const Contact = () => {
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (window.location.search.includes('success=true')) {
            setSuccess(true);
        }
    }, []);
    const { action: { address } } = useContext(TronContext)
    return (
        <Wrap >
            <h2>CONTACT</h2>
            {success && (
                <p style={{ color: "green" }}>Thanks for your message! </p>
            )}

            <form
                name="contact"
                method="POST"
                action="?success=true"
                data-netlify="true"
            >
                <input type="hidden" name="form-name" value="contact" />
                <input id="standard-basic" name="name" value={address} /> <br />

                <br />
                <div>
                    <button type="submit">Send</button>
                </div>
            </form>

        </Wrap>
    );
}

export default Contact
const Wrap = styled.div``
