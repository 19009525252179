const configColor = {
    colorWhite: "#fff",
    colorPrimary: "#0275d8",
    colorGray: "#FFA143",
    colorDark: '#798FA6',
    colorCoin: '#BCCEE2',
    colorReduction: '#FF4355',
    colorIncrease: '#28DE44',
    colorGradient1: "#FFAE5D",
    colorGradient2: "#FF8467",
    colorGrayOpa: "#38445D",
    colorGrayRoll: "#293245",
}
export default configColor