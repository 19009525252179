import StakingJoinTree from 'components/StakingNew/stakingJoin/StakingJoinTree'
import { TronContext } from 'contexts/tronWeb'
import { UsersContext } from 'contexts/UsersContext'
import { useTreeChild } from 'helps/useTreeChild'
import React, { useContext } from 'react'

const StakingJoinTreeNode = () => {
    //TODO data tree node
    const { users } = useContext(UsersContext)
    const { action } = useContext(TronContext)
    const nodeLeftValue = useTreeChild(users.left)
    const nodeRightValue = useTreeChild(users.right)
    const dataNode = {
        userRef: users.treeParent,
        nodeLeftRef: users.left,
        nodeRightRef: users.right
    }
    const { userRef, nodeLeftRef, nodeRightRef } = dataNode
    return (
        <div>
            <StakingJoinTree
                username={''}
                userRef={action.address}
                nodeLeftValue={nodeLeftValue}
                nodeRightValue={nodeRightValue}
                nodeLeftRef={nodeLeftRef}
                nodeRightRef={nodeRightRef}
            />
        </div>
    )
}

export default StakingJoinTreeNode
