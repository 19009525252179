import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
interface FundInfomationContentProps {
    id: number
    text: string
    total: string
}
const FundInfomationContent = ({ id, text, total }: FundInfomationContentProps) => {
    return (
        <FundInfomationContentWrap>
            <div className="fiwi_header">
                <Text text={text} />
            </div>
            <div className="fiwi_cont">
                <Text text={total} fontWeight="bold" size="subHeading" color="gray" />
            </div>
        </FundInfomationContentWrap>
    )
}

export default FundInfomationContent
const FundInfomationContentWrap = memo(styled.div`
    text-align:center;
    border-right:1px solid #3A4E6E;
    flex:0.333;
    :last-child{
        border-right:none;
    }
    @media screen and  (max-width:767px){
        display:flex;
        justify-content:space-between;
        align-items:center;
        border-right:none;
        border-bottom:1px solid #3A4E6E;
        padding: 0.5rem 0;
      
        :last-child{
            border-bottom:none;
        }
       
    }
`)