
import animation from 'assets/containts/animation'
import configColor from 'assets/containts/configColor'
import React from 'react'
import styled from 'styled-components'
interface LoadingProps {
    size?: "normal" | "tiny",
    color?: "white" | "normal",
    mode?: "center" | "left"
}
const Loading = ({ size = "normal", color = "normal", mode = "center" }: LoadingProps) => {
    return (
        <Wrap className={size} aria-activedescendant={color} aria-details={mode} >
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </Wrap>
    )
}

export default Loading

const Wrap = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    &[aria-details="center"]{
        width:100%;
    }
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring >div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid ${configColor.colorGray};
        border-radius: 50%;
        animation: ${animation.loadingSuspene} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${configColor.colorGray} transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    &.tiny{
        .lds-ring {
            display: inline-block;
            position: relative;
            width: 18px;
            height: 18px;
        }
        .lds-ring >div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            margin: 0;
            border: 2px solid ${configColor.colorGray};
            border-radius: 50%;
            animation: ${animation.loadingSuspene} .8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: ${configColor.colorGray} transparent transparent transparent;
            .lds-ring div:nth-child(3) {

            }
        }
    }
    &[aria-activedescendant='white']{
        .lds-ring {
            display: inline-block;
            position: relative;
            width: 18px;
            height: 18px;
        }
        .lds-ring >div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            margin: 0;
            border: 2px solid ${configColor.colorWhite};
            border-radius: 50%;
            animation: ${animation.loadingSuspene} .8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: ${configColor.colorWhite} transparent transparent transparent;
            .lds-ring div:nth-child(3) {

            }
        }
    }
`