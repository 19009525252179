
import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
import VoteInput from './VoteInput'


interface VoteInformationProps {
    banlanceCoin: {
        coinTRX: number
        coinUSDT: number
    }
    usdtAmount: string
    setUsdtAmount: any
    errorInput: string
    setErrorInput: any
    BuyConfirm: (usdtAmount: number) => void
    setopenModal: (e: boolean) => void
}
const VoteInformation = ({ banlanceCoin, usdtAmount, setUsdtAmount, errorInput, setErrorInput, BuyConfirm, setopenModal }: VoteInformationProps) => {

    return (
        <VoteInformationWrap>
            <div className="viw_header">
                <Text text="Vote" fontWeight="bold" size="xNormal" />
            </div>
            <div className="viw_cont">
                <Text text="Tron is a profitable project for crypto users. Build even bigger TRON community by voting now!" />
            </div>
            <div className="viw_input">
                <VoteInput

                    banlanceCoin={banlanceCoin}
                    setUsdtAmount={setUsdtAmount}
                    usdtAmount={usdtAmount}
                    errorInput={errorInput}
                    setErrorInput={setErrorInput}
                    BuyConfirm={BuyConfirm}
                    setopenModal={setopenModal}
                />
            </div>
        </VoteInformationWrap>
    )
}

export default VoteInformation

const VoteInformationWrap = memo(styled.div`
   .viw_header{
       text-align:center;
   }
   .viw_cont{
        text-align:center;
       margin-top:3.5rem;
       margin-bottom:3rem;
   }
   @media screen and (max-width:1024px){
        .viw_cont{
            margin-top:2.125rem;
            margin-bottom:2.25rem;
        }
   }
   @media screen and (max-width:767px){
        .viw_cont{
            margin:2rem 0;
        }
    }
`)