import React, { memo, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import HomeBalance from "./HomeBalance/HomeBalance";
import HomeTable from "./HomeTable/HomeTable";
import { TronContext } from "../../contexts/tronWeb";
import axios from "axios";
import BigNumber from "bignumber.js";
import HomeQuantity from "./HomeQuantityRef/HomeQuantity";
import HomeRef from "./HomeQuantityRef/HomeRef";
import DetailPreSaleClaim from "./HomePreSaleClaim/DetailPreSaleClaim";
import StakingLayout from "components/StakingNew/StakingLayout";
import { breakpointsMedia } from "assets/containts/breakpoints";
import { formatCoin } from "helps/formatCoin";
import FormClaimBonus from "container/FormClaim/FormClaimBonus";
import { contract } from "contexts/tronConfig";
const Home = () => {
  const { action, data, tronWeb } = useContext(TronContext);
  const [dataCoin, setDataCoin] = useState({
    usdtBalance: 0,
    trxBalance: 0,
    defBalance: 0,
  });
  useEffect(() => {
    const getDataCoin = async () => {
      try {
        if (
          !!!data.usdt ||
          !!!tronWeb ||
          !!!data.token ||
          action.address === undefined ||
          action.address === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"
        )
          return;
        let dataUsdt = await data.usdt.balanceOf(action.address).call();
        let dataTRX = await tronWeb.trx.getBalance(action.address);
        let dataDEF = await data.token.balanceOf(action.address).call();
        setDataCoin({
          usdtBalance: Number(dataUsdt) / 10 ** 6,
          trxBalance: Number(dataTRX) / 10 ** 6,
          defBalance: formatCoin(Number(dataDEF)),
        });
      } catch (error) {
        console.log("getDataCoin", error);
      }
    };
    getDataCoin();
  }, [data, action.address, tronWeb]);
  const [convertUSDT, setConvertUSDT] = useState({
    trx: 0,
    def: 0,
  });
  // data price DEF/TRX
  const [priceDef, setPriceDef] = useState(0)
  useEffect(() => {
    const getPriceDef = async () => {
      try {
        const [totalBalane, totalTrx] = await Promise.all([
          data.token?.balanceOf(contract.addressCreate).call() || '',
          tronWeb.trx.getBalance(contract.addressCreate)
        ])

        const dataPriceDef = new BigNumber(Number(totalTrx)).dividedBy(Number(totalBalane)).toNumber()
        // console.log("result balanceDef ", dataPriceDef)
        setPriceDef(dataPriceDef)
      } catch (error) {
        console.log('get price def', error)
      }
    }
    if (!!action.isConnect && action.address !== "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb") {
      getPriceDef()
    }
  }, [tronWeb, data, action])
  useEffect(() => {
    const getAPIUsdt = async () => {
      try {
        if (!!!data.price) return;
        let api = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=tether%2Ctron&vs_currencies=usd"
        );
        let dataDEF = (await data.price?.currentPrice().call()) || "";
        // console.log("dataDEF", Number(api.data.tron.usd));
        setConvertUSDT({
          def: Number(dataDEF) / 1e6,
          trx: Number(api.data.tron.usd),
        });
      } catch (error) {
        console.log("getAPIUsdt vs TRX", error);
      }
    };
    getAPIUsdt();
  }, [data.price]);
  const [dataCoinUSDT, setDataCoinUSDT] = useState({
    usdtBalanceUSDT: 0,
    trxBalanceUSDT: 0,
    defBalanceUSDT: 0,
  });

  useEffect(() => {
    setDataCoinUSDT({
      usdtBalanceUSDT: dataCoin.usdtBalance,
      trxBalanceUSDT: Number(
        new BigNumber(dataCoin.trxBalance).multipliedBy(convertUSDT.trx)
      ),
      defBalanceUSDT: Number(
        new BigNumber(dataCoin.defBalance).multipliedBy(priceDef * convertUSDT.trx)
      ),
    });
  }, [dataCoin, convertUSDT]);

  const Claim = async (type) => {
    try {
      if (type === 1) {
        await action.seedSale.claim().send({
          callValue: 0,
          feeLimit: 2e8,
        });
      } else {
        await action.seedSale.claimRef().send({
          callValue: 0,
          feeLimit: 2e8,
        });
      }
    } catch (error) {
      console.log("errorClaim", error);
    }
  };
  const [dataRef, setDataRef] = useState({
    bought: 0,
    price: 0,
    totalMember: 0,
    totalSold: 0,
    claimed: 0,
    amountClaim: 0,
    refBalance: 0,
    refUnlock: 0,
    refAmount: 0,
  });
  useEffect(() => {
    const getData = async () => {
      try {
        // console.log("getnew");
        let result = await data.seedSale.getInformation(action.address).call();
        let amountClaim = await data.seedSale
          .getClaimAmount(action.address)
          .call();
        let refClaimAmount = await data.seedSale
          .getRefAmount(action.address)
          .call();
        let userBalance = await data.seedSale.users(action.address).call();
        // console.log("reffff", refClaimAmount);
        result &&
          amountClaim &&
          amountClaim.unlockAmount &&
          setDataRef({
            bought: Number(result.bought) / 10 ** 6,
            price: Number(result.price) / 10 ** 6,
            totalMember: Number(result.totalMember) / 10 ** 6,
            totalSold: Number(result.totalSold) / 10 ** 6,
            claimed: Number(userBalance.unlock) / 10 ** 6,
            amountClaim: Number(amountClaim.unlockAmount) / 10 ** 6,
            refBalance: Number(userBalance.refBalance) / 10 ** 6,
            refUnlock: Number(userBalance.refUnlock) / 10 ** 6,
            refAmount: Number(refClaimAmount.unlockAmount) / 10 ** 6,
          });
      } catch (error) {
        console.log("error", error);
      }
    };
    if (data.seedSale) {
      getData();
    }
  }, [data.seedSale, action.address]);
  //form netlify
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (window.location.search.includes('success=true')) {
      setSuccess(true);
    }
  }, []);
  return (
    <HomeWrap className="container">
      <div className="hw_balance">
        <HomeBalance dataCoinUSDT={dataCoinUSDT} dataCoin={dataCoin} />
      </div>
      {/* <div className="hw">
                <div className="hw_homeBalance">
                    <HomeQuantity dataRef={dataRef} Claim={Claim} quantityPurchased={quantityPurchased} />
                    <HomeRef dataRef={dataRef} Claim={Claim} />
                </div>
                <div className="hw_homeTable">
                    <DetailPreSaleClaim />
                </div>
            </div> */}
      <div className="home-sale">
        {/* <FormClaimBonus /> */}
        {/* <div className="home-sale__seed">
          <StakingLayout title="Seed sale">
            <HomeQuantity dataRef={dataRef} Claim={Claim} />
            <HomeRef dataRef={dataRef} Claim={Claim} />
          </StakingLayout>
        </div>
        <div className="home-sale__pre">
          <StakingLayout title="Pre sale">
            <DetailPreSaleClaim />
          </StakingLayout>
        </div> */}
      </div>
      <Wrap>
        {success && (
          <p style={{ color: "green" }}>Thanks for your message! </p>
        )}

        <form
          name="contact"
          method="POST"
          action="?success=true"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input id="standard-basic" name="name" value={action.address} /> <br />

          <br />
          <div>
            <button type="submit">Claim Promotion</button>
          </div>
        </form>

      </Wrap>
    </HomeWrap>
  );
};

export default Home;
const Wrap = styled.div`
padding:0 15px;
margin-bottom:30px;
  input{
    border: none;
      outline: none;
      background-color: transparent;
      width: 100%;
      font-size: 1rem;
      color: #fff;
      box-sizing: border-box;
      border: 1px solid #3A4E6E;
      padding:15px 25px;
      border-radius:5px;
  }
   button{
        padding: 15px 30px;
        background: transparent linear-gradient(270deg,#FFA143 0%,#FF7555 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color:#fff;
        border:0;
        text-align:center;
      display:inline-block;

    }
    ${breakpointsMedia.landscape}{

    }
    ${breakpointsMedia.largeDesktop}{
      padding:0;
      margin-bottom:0;
    }
`
const HomeWrap = memo(styled.div`
  .hw_balance {
    width: 100%;
    margin-bottom: 2.25rem;
  }
  .hw {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .hw_homeBalance {
      flex: 0.3215;
    }
    .hw_homeTable {
      flex: 0.66;
    }
  }
  .home-sale {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
      margin-right: 0;
      :last-child {
        margin-right: 0;
      }
    }
    &__seed {
      margin-bottom: 30px;
    }
  }
  ${breakpointsMedia.largeDesktop} {
    .home-sale {
      flex-direction: row;
      > div {
        width: 100%;
        margin-right: 30px;
        :last-child {
          margin-right: 0;
        }
      }
      &__seed {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 1279px) {
    .hw_balance {
      width: 100%;
      margin-bottom: 0;
    }
    .hw {
      flex-direction: column;
      .hw_homeBalance {
        margin-bottom: 1rem;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .hw {
      flex-direction: column;
      .hw_homeBalance {
        margin-bottom: 1rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .hw {
      background-color: #242938;
      flex-direction: column;
      .hw_homeBalance {
        margin: 0 2rem 1rem;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .hw {
      background-color: #242938;
      flex-direction: column;
      .hw_homeBalance {
        margin: 0 1rem 1rem;
      }
    }
  }
`);
