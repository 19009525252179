import React, { memo } from 'react'
import styled from 'styled-components'
import Image from '../../../../core/Image'
import coinUSDT from '../../../../assets/images/usdt-coin-icon.svg'
import Text from '../../../../core/Text'
import ArrayDataWeek from './ArrayDataWeek'
import ArrayDataMonth from './ArrayDataMonth'
import ArrayDataYear from './ArrayDataYear'



interface InfoDetailsProps {
    handleClickOpenModal: (type: number, active: boolean) => void
    handelClickTimeLine: (e: number) => void
    timeLine: {
        active: number
    },
}
const InfoDetails = ({ handleClickOpenModal, handelClickTimeLine, timeLine }: InfoDetailsProps) => {

    return (
        <InfoDetailsWrap>
            <div className="idw_header">
                <div className="idwh_child">
                    <Image src={coinUSDT} />

                </div>
                <div className="idwh_child">
                    <Text text='USDT' fontWeight='bold' size="xNormal" />
                </div>
            </div>
            <div className="idw_status">
                <div className="idws_child">
                    <Text text="Borrow APR: 7.54%" />
                    <Text text="Deposit: 5.06%" />
                </div>
            </div>
            <div className="idw_history">
                <Text text="Interest Rate History" />
            </div>
            <div className="idw_day">
                <div className={`idwd_item && ${timeLine.active === 0 ? 'active' : ''}`} onClick={() => handelClickTimeLine(0)}>
                    <Text text="1 week" size="small" color="dark" />
                </div>
                <div className={`idwd_item && ${timeLine.active === 1 ? 'active' : ''}`} onClick={() => handelClickTimeLine(1)}>
                    <Text text="1 month" color="dark" size="small" />
                </div>
                <div className={`idwd_item && ${timeLine.active === 2 ? 'active' : ''}`} onClick={() => handelClickTimeLine(2)}>
                    <Text text="1 year" color="dark" size="small" />
                </div>
            </div>
            <div className="idw_chart">
                {timeLine.active === 0
                    &&
                    <ArrayDataWeek />

                }
                {timeLine.active === 1
                    &&
                    <ArrayDataMonth />

                }
                {timeLine.active === 2
                    &&
                    <ArrayDataYear />

                }
            </div>
            <div className="idw_footer">
                <div className="idwf_btn " onClick={() => handleClickOpenModal(2, true)}>
                    <Text text="Deposit" />
                </div>
                <div className="idwf_btn brrow" onClick={() => handleClickOpenModal(1, true)}>
                    <Text text="Borrow" />
                </div>
            </div>
        </InfoDetailsWrap>
    )
}

export default InfoDetails
const InfoDetailsWrap = memo(styled.div`
    background: #262E3F 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:2rem;
    margin-bottom: 2.5rem;
    .idw_header{
        display:flex;
        align-items:center;
        justify-content:center;
        .idwh_child{
            padding: 0 0.5rem;
            img{
                width:32px;
            }
        }
    }
    .idw_status{
        .idws_child{
            justify-content:center;
            display:flex;
            align-items:center;
            p:last-child{
                ::before{
                    background-color: #FFA143;         
                }
            }
            p{
                padding:1rem;
                position: relative;
                ::before{
                    content:'';
                    width:0.5rem;
                    height:0.5rem;
                    position:absolute;
                    top:50%;
                    left:0rem;
                    transform: translate(0,-50%);     
                    background-color: #368FEA;         
                }
            }
        }
    }
    .idw_history{
        text-align:center;
        margin-bottom:1rem;
    }
    .idw_day{
        display:flex;
        align-items:center;
        justify-content:space-around;
        .idwd_item{
            cursor: pointer;
            p{
                border-bottom:1px solid transparent;
            }
        }
        .active{
            p{
                color:#FFA143 !important;
                border-bottom:1px solid #FFA143;

            }
        }
    }
    .idw_footer{
        display:flex;
        justify-content:space-around;
        margin-top:1rem;
        .idwf_btn{
            min-width:40%;
            background: #FFA143 0% 0% no-repeat padding-box;
            border-radius: 32px;
            height:40px;
            display:flex;
            align-items:center;
            justify-content:center;
            cursor: pointer;
            :hover, :active{
                background: #FF9327 0% 0% no-repeat padding-box;

            }
        }
        .brrow{
            background: #368FEA 0% 0% no-repeat padding-box;
            :hover, :active{
                background: #0380FF 0% 0% no-repeat padding-box;

            }

        }
    }
    @media screen and (max-width:1024px){
        border-radius: 5px;
        padding:1.875rem;
        margin-bottom: 1.5rem;
    }
    @media screen and (max-width:767px){
        padding:1.5rem;
    }
`)