import { breakpointsMedia } from 'assets/containts/breakpoints'
import CopyRef from 'core/CopyRef'
import Text from 'core/Text'
import React, { memo } from 'react'
import styled from 'styled-components'
interface StakingSuccessProps {
    node: number;
    sponsor: string;
}
const StakingSussess = ({ node, sponsor }: StakingSuccessProps) => {
    let textNode = ''
    switch (node) {
        case 0: {
            textNode = "Node Left"
            break;
        }
        case 1: {
            textNode = "Node Right"
            break;
        }
        default:
            break;
    }
    return (
        <Wrap>
            <div className="ss-item">
                <div className="it">
                    <Text text="Node position:" />

                </div>
                <div className="ct">
                    <Text text={textNode} />

                </div>
            </div>
            <div className="ss-item">
                <div className="it">
                    <Text text="Sponsor:" />
                </div>
                <div className="ct">
                    <CopyRef address={sponsor} title="" />
                </div>
            </div>
        </Wrap>
    )
}

export default memo(StakingSussess)
const Wrap = styled.div`
    width:90%;
    margin-left:auto;
    margin-right:auto;
    .ss-item{
        display:flex;
        align-items:center;
        text-align:left;
        border-bottom:1px solid #3A4E6E;
        padding:7px 0;
        :last-child{
            border-bottom:0;
        }
        .it{
            width:100px;
            
        }
        .ct{
            flex:1;
            text-align:left;
            .src-title{
                margin-bottom:0 !important;
            }
            .src-copy__input{
                padding:0 !important;
            }
        }
    }
    ${breakpointsMedia.landscape}{}
    ${breakpointsMedia.desktop}{
        width:60%;
        .ss-item{
            .it{
                width:200px; 
            }
        }
    }
`