import React, { memo } from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import styled from 'styled-components'
interface PaginationProps {
    pagination: {
        _page: number
        _limit: number
        _totalRows: number
    }
    onPageChange: (e: number) => void
}
const Pagination = ({ pagination, onPageChange }: PaginationProps) => {

    const totalPages = Math.ceil(pagination._totalRows / pagination._limit)
    const handlePageChange = (newPage: number) => {
        if (onPageChange) {
            onPageChange(newPage);
        }
    }
    const a = Math.ceil(pagination._totalRows / pagination._limit)
    let arrayPage = [] as Array<any>

    for (let i = 0; i < a; i++) {
        arrayPage.push(i)
    }

    return (
        <PaginationWarp className="pagination">
            <button
                disabled={pagination._page <= 1}
                onClick={() => {
                    console.log("object")
                    handlePageChange(pagination._page - 1)
                }}
            >
                <BsArrowLeft />
            </button>
            {
                arrayPage.map((t, index) =>
                    <div className={`number__pagination ${t === pagination._page - 1 ? "active" : null} `} key={index} onClick={() => handlePageChange(t + 1)}>{t + 1}</div>
                )

            }
            <button
                disabled={pagination._page >= totalPages}
                onClick={() => {
                    handlePageChange(pagination._page + 1)
                }}
            >
                <BsArrowRight />
            </button>
        </PaginationWarp>
    )
}

export default Pagination

const PaginationWarp = memo(styled.div`

        display:flex;
        justify-content:center;
        align-items:center;
        margin-top:1rem;
        margin-bottom:1.25rem;
        .active{
            background-color:#FFA143;
        }
        .number__pagination{
            color:#fff;
            box-sizing:border-box;
            cursor: pointer;
            :hover{
            background-color:#FFA143;

            }
            
        }
        button{
            outline:none;
            border:none;
            background-color:transparent;
            svg{
                fill:#fff;
                cursor: pointer;
                font-size:1.5rem;
            }
            :disabled{
                svg{
                    fill:#798FA6;

                }
            }
        }
    @media screen and (min-width:1600px){
            .number__pagination{
                margin: 0 10px;
                width: 29px;
                height: 32px;
                padding:5px 10px;
                border-radius:3px;
            }
    }
    @media screen and (min-width:1025px) and (max-width:1599px){
            .number__pagination{
                margin: 0 10px;
                width: 29px;
                height: 32px;
                padding:5px 10px;
                border-radius:3px;
            }
    }
    @media screen and (min-width:768px) and (max-width:1024px){
            .number__pagination{
                margin: 0 7.5px;
                width: 28px;
                height: 32px;
                padding:4px 8px;
                border-radius:2px;
            }
    }
    @media screen and (min-width:320px) and (max-width:767px){
            .number__pagination{
                margin: 0 7.5px;
                padding: 0.5rem 0.75rem;
                border-radius:2px;
            }
    }
        
`)