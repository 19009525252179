import React, { useEffect } from 'react'
import ArrayChartInfo from './ArrayChartInfo'

const ArrayDataWeek = () => {
    // const [dataWeek, setDataWeek] = useState({
    //     name:'',
    //     borrow:0,
    //     Deposit:0,
    // })
    const getDataWeek = async () => {
        try {

        } catch (error) {
            //TODO getDataWeek
        }
    }
    useEffect(() => {
        getDataWeek()
    }, [])
    const dataWeek = [
        { "name": "20-11-12", "Borrow": 4000, "Deposit": 2400, },
        { "name": "22-11-12", "Borrow": 2000, "Deposit": 9800, },
        { "name": "24-11-12", "Borrow": 1890, "Deposit": 4800, },
        { "name": "26-11-12", "Borrow": 3490, "Deposit": 4300, },
    ]
    return (
        <ArrayChartInfo data={dataWeek} />
    )
}

export default ArrayDataWeek
