import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { AiOutlineSearch } from 'react-icons/ai'

const Search = () => {
    const [Search, setSearch] = useState('')
    return (
        <Warp>
            {/* <div className="search__icon">
                <AiOutlineSearch />
            // </div>
            <div className="header-warp__search">
                <input type="text" placeholder="Search" value={Search} onChange={e => setSearch(e.target.value)} />
            </div> */}
        </Warp>
    )
}

export default memo(Search)
const Warp = memo(styled.div`
    flex:0.4;
    .header-warp__search{
        margin:15px 15px;
        input{
            background-color:rgba(43, 55, 75,0.8);
            color: #fff;
            box-sizing:border-box;
            border-radius:10px;
            padding:10px 20px;
            outline:none;
            border:none;
            height:40px;
        }
    }
    @media screen and (min-width:768px){
        .search__icon{
            display:none;
        }
    }
    @media screen and (min-width:320px) and (max-width:767px){
        .header-warp__search{
            display:none;
        }
        .search__icon{
            display:flex;
            align-items:center;
            padding:4px 10px;
            svg{
                background-color:#2B374B;
                padding:15px 10px;
                border-radius:3px;
                fill:#FFA143 ;
                :hover{
                    fill:red;
                }
            }
        }
    }
    
`)