import Address from 'core/Address'
import Button from 'core/Button'
import Coin from 'core/Coin'
import Text from 'core/Text'
import React from 'react'
import styled from 'styled-components'
interface StakingInfoItemProps {
    title: string,
    value?: number,
    type?: "string" | "coin" | "address" | "button" | any,
    text?: string | number,
    btnEvent?: () => void,
    textBtn?: string
}
const StakingInfoItem = ({ title, type = "coin", value, text, btnEvent, textBtn }: StakingInfoItemProps) => {
    return (
        <Wrap>
            <div className="sii-title">
                <Text text={title} />
            </div>
            <div className="sii-content">
                {
                    type === "coin" && <Coin format="0,0.000000" value={value || 0} />

                }
                {
                    type === "string" && <Text text={`${text}`} />

                }
                {
                    type === "address" && <Address address={`${text}`} />

                }
                {
                    type === "button" &&
                    <div className="sii-btn">
                        <Text text={`${text}`} />
                        <Button text={textBtn} styleBtn="normal" aria-activedescendant='approve' onClick={btnEvent} />
                    </div>

                }
            </div>
        </Wrap>
    )
}

export default StakingInfoItem
const Wrap = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    .sii-btn{
        display:flex;
        align-items:center;
        button{
            margin-left:10px;
        }
    }
`