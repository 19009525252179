import React, { memo } from 'react'
import styled from 'styled-components'
import close from '../../../../assets/images/close.svg'

import Text from '../../../../core/Text'
import ModalDetailsFormInput from './ModalDetailsFormInput'
interface ModalDetailsProps {
    handleClickOpenModal: (type: number, active: boolean) => void
    openModal: {
        open: boolean,
        status: number,
    }
    dataBorrow: {
        loanBalance: number,
        newLoanBalance: number,
        borrowingPower: number,
        newBorrowingPower: number,
        value: number
    }
    dataDesposit: {
        availableBalance: number,
        newVailableBalance: number,
        additional: number,
        borrowing: number,
        newBorrowing: number,
        value: number
    }

}
const ModalDetails = ({ handleClickOpenModal, openModal, dataBorrow, dataDesposit }: ModalDetailsProps) => {
    const dataBrrow = [
        { id: 1, text: 'Loan Balance', amount: ` ${dataBorrow.loanBalance} USDT` },
        { id: 2, text: 'New Loan Balance', amount: ` ${dataBorrow.newLoanBalance} USDT` },
        { id: 3, text: 'Borrowing Power', amount: `$ ${dataBorrow.borrowingPower}` },
        { id: 4, text: 'New Borrowing Power', amount: `$ ${dataBorrow.newBorrowingPower}` },
        { id: 5, text: 'Value', amount: `$ ${dataBorrow.value}` },

    ]
    const dataDeposlit = [
        { id: 1, text: 'Available Balance', amount: ` ${dataDesposit.availableBalance} USDT` },
        { id: 2, text: 'New vailable Balance', amount: ` ${dataDesposit.newVailableBalance} USDT` },
        { id: 3, text: 'Additional Year Interest to Earn', amount: `$ ${dataDesposit.additional}` },
        { id: 4, text: 'Borrowing Power', amount: `$ ${dataDesposit.borrowing}` },
        { id: 5, text: ' New Borrowing Power', amount: `$ ${dataDesposit.newBorrowing}` },
        { id: 6, text: 'Value', amount: `$ ${dataDesposit.value}` },
    ]
    return (
        <ModalDetailsWrap>
            <div className="ccw_inner">
                <div className="ccwi_close" onClick={() => handleClickOpenModal(openModal.status, false)}>
                    <img src={close} alt="" />
                </div>
                <div className="ccwi_content">
                    <div className="ccwic_image">
                        <div className="ccwici_header">
                            {
                                openModal.status === 1 ?
                                    <Text text='Borrow' fontWeight="bold" size="subHeading" />
                                    :
                                    <Text text='Deposit' fontWeight="bold" size="subHeading" />
                            }
                        </div>
                        <div className="ccwici_cont">
                            {
                                openModal.status === 1 ?
                                    <Text text='No matter the loan and interest rate, now you can borrow USDT quickly and easily with a small fee!' />
                                    :
                                    <Text text=' Invest in USDT to earn passive income every day on your debt reserves. Let us help you get more interest by depositing now!' />
                            }
                        </div>
                        <div className="ccwici_input">
                            {
                                openModal.status === 1 ?
                                    <ModalDetailsFormInput
                                        // banlanceCoin={banlanceCoin}
                                        // setUsdtAmount={setUsdtAmount}
                                        // usdtAmount={usdtAmount}
                                        // errorInput={errorInput}
                                        // setErrorInput={setErrorInput}
                                        // BuyConfirm={BuyConfirm}
                                        // setopenModal={setopenModal}
                                        openModal={openModal}
                                    />
                                    :
                                    <ModalDetailsFormInput
                                        // banlanceCoin={banlanceCoin}
                                        // setUsdtAmount={setUsdtAmount}
                                        // usdtAmount={usdtAmount}
                                        // errorInput={errorInput}
                                        // setErrorInput={setErrorInput}
                                        // BuyConfirm={BuyConfirm}
                                        // setopenModal={setopenModal}
                                        openModal={openModal}
                                    />
                            }

                        </div>
                    </div>
                    <div className="ccwic_main">
                        {
                            openModal.status === 1 ?
                                dataBrrow.map((t, index) => {
                                    return <div className="aiwc_item" key={index}>
                                        <Text text={t.text} />
                                        {
                                            t.id === 5 ?
                                                <Text text={t.amount} color="gray" fontWeight="bold" size="xNormal" />
                                                :
                                                <Text text={t.amount} />
                                        }
                                    </div>
                                })
                                :
                                dataDeposlit.map((t, index) => {
                                    return <div className="aiwc_item" key={index}>
                                        <Text text={t.text} />
                                        {
                                            t.id === 6 ?
                                                <Text text={t.amount} color="gray" fontWeight="bold" size="xNormal" />
                                                :
                                                <Text text={t.amount} />
                                        }
                                    </div>
                                })
                        }

                    </div>
                </div>
            </div>
        </ModalDetailsWrap>
    )
}

export default ModalDetails
const ModalDetailsWrap = memo(styled.div`
    background-color:rgba(0,0,0,0.8);
    width:100vw;
    height:100vh;
    position:fixed;
    z-index:20;
    top:0;
    left:0;
    .ccw_inner{
        background-color:#262E3F;
        box-shadow: 0px 3px 6px #00000029;
        width:60%;
        position: relative;
        display:flex;
        justify-content:center;
        align-items:center;
        min-height:552px;
        .ccwi_close{
            position:absolute;
            top:-20px;
            right:-20px;
            cursor: pointer;
        }
        .ccwi_content{
           width:100%;
            display:flex;
            justify-content:space-between;
            padding:0 5rem;
            .ccwic_image{
                flex:0.45;
                .ccwici_header{
                    text-align:center;
                    margin-bottom:1rem;
                }
                .ccwici_cont{
                    margin-bottom:2rem;

                    text-align:center;
                }
                
            }
            .ccwic_main{
                flex:0.52;
                /* padding:0 1rem 0 1.5rem; */
                background: #293246 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #171C2A1A;
                border-radius: 15px;
                padding:3rem;
                >div:last-child{
                    border-bottom:0;
                }
                .aiwc_item{
                    display:flex;
                    align-items:center;
                    justify-content:space-between;
                    padding: 1rem 0 ;
                    border-bottom: 1px solid rgba(58, 78, 110,0.5);
                    
                }

            }
        }
    }
    @media screen and (min-width: 1600px) {
        display:flex;
        justify-content:center;
        align-items:center;
        .ccw_inner{
            border-radius:15px;
        }
    }
    @media screen and (min-width: 1280px) and (max-width:1599px){
        display:flex;
        justify-content:center;
        align-items:center;
        .ccw_inner{
            border-radius:15px;

            .ccwi_content{
                .ccwic_image{
                    flex:0.45;
                    padding: 0 1rem;
                }
                .ccwic_main{
                    flex:0.52;
                    padding: 2rem 1.5rem;
                }
            }
        }
    }
    @media screen and (min-width: 1025px) and (max-width:1279px){
        display:flex;
        justify-content:center;
        align-items:center;
        .ccw_inner{
            width:55%;
            border-radius:10px;

            .ccwi_content{
                flex-direction:column;
                padding: 3rem;
                .ccwic_main{
                    margin-top:3.375rem;

                    padding: 0 1rem;
                }
            }
        }
    }
    @media screen and (min-width:768px) and (max-width:1024px){
        overflow-y:auto;
        .ccw_inner{
            border-radius:5px;

            width:65%;
            min-height:610px;
            margin: 50px  auto 0;
            .ccwi_close{
                top:-10px;
                right:-10px;
            }
            .ccwi_content{
                width:75%;
                flex-direction:column;
                padding: 3rem 2rem;
                .ccwic_main{
                    border-radius:10px;

                    margin-top:3.375rem;
                }
            }
        }
    }
    @media screen and (min-width:320px) and (max-width:767px){
        overflow-y:auto;
        .ccw_inner{
            margin:1rem auto;
            border-radius:5px;

            width:90%;  
            /* height:100%; */
            .ccwi_close{
                top:-5px;
                right:-6px;
                img{
                    width:30px;
                }
            }
            .ccwi_content{
                flex-direction:column;
                padding:2rem 1.5rem;
                .ccwic_image{
                        .ccwici_header{
                            text-align:center;
                            margin-bottom:0.75rem;
                        }
                        .ccwici_cont{
                            text-align:center;
                        }
                        
                    }
                .ccwic_main{
                    margin-top:3.5rem;
                    padding:0 1rem ;
                    border-radius:5px;
                    .aiwc_item{
                        flex-direction:column;
                        justify-content:center;
                    }
                }
            }
        }
    }
`)