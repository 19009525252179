import React, { memo } from 'react'
import styled from 'styled-components'
import Text from '../../../core/Text'
import FundInfomationContent from './FundInfomationContent'
import I18n from 'i18n-js'
interface FundInformationProps {
    dataCoinFund: {
        total: number,
        totalDisbursed: number,
        projectsDisbursed: number
    }
}
const FundInformation = ({ dataCoinFund }: FundInformationProps) => {
    const data = [
        { id: 1, text: 'Total amount in Fund', total: `$ ${I18n.toNumber(dataCoinFund.total)}` },
        { id: 2, text: 'Total disbursed amount', total: `$ ${I18n.toNumber(dataCoinFund.totalDisbursed)}` },
        { id: 3, text: 'Number of projects disbursed', total: `$ ${I18n.toNumber(dataCoinFund.projectsDisbursed)}` },
    ]
    return (
        <FundInformationWrap >
            <div className="fiw_header">
                <Text text='Fund information' fontWeight="bold" size="xNormal" />
            </div>
            <div className="fiw_cont">
                {
                    data.map((t, index) => {
                        return <FundInfomationContent id={t.id} text={t.text} total={t.total} key={index} />
                    })
                }
            </div>
        </FundInformationWrap>
    )
}

export default FundInformation
const FundInformationWrap = memo(styled.div`
    background-color:#262E3F;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:2rem;
    .fiw_header{
        margin-bottom:2rem;
    }
    .fiw_cont{
        display:flex;
        justify-content:space-between;
    }
    @media screen and (max-width:1024px){
        padding:1.875rem;
        .fiw_header{
        margin-bottom:1rem;
            
        }
       
    }
    @media screen and  (max-width:767px){
        padding:1.875rem 1.5rem;
        border-radius:5px;
        margin: 1.5rem;
        .fiw_header{
            margin-bottom:0.5rem; 
        }
        .fiw_cont{
           flex-direction:column;
        }
    }
    @media screen and  (max-width:576px){
        margin: 1rem;

    }
`)